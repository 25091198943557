import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Footer from "../ect/Footer";

import BlogReviewList from "../ect/detail-page-template/BlogReviewList";
import RelatedVideoList from "../editor-video-template/RelatedVideoList";
import PlaceDetailFormTemplate from "../place/place-template/PlaceDetailFormTemplate";
import DetailTapMenu from "../ect/detail-page-template/DetailTapMenu";
import Overview from "../ect/detail-page-template/OverView";
import SingleMarkerMap from "components/map/SingleMarkerMap";
import UserReviewComponent from "components/ect/detail-page-template/UserReviewComponent";
import UserReservComponent from "components/ect/detail-page-template/UserReservComponent";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const WaitingPlaceDetailView = ({ match }) => {
  const { state } = useContext(MobileContext);

  const history = useHistory();

  const [detailInfo, setDetailInfo] = useState(null);

  const [tapMenu, setTapMenu] = useState(0);
  const [reviewBtns, setReviewBtns] = useState({
    user_review: true,
    blog_review: false,
    make_reserv: false,
  });
  const getTapMenu = (menu) => {
    setTapMenu(menu);
  };

  const getVideoAry = (resources) => {
    let ary = [];
    if (Object.keys(resources).includes("videos")) {
      let id = 1;
      for (let i = 0; i < resources.videos.length; i++) {
        ary.push({
          id: id++,
          vId: resources.videos[i].url,
        });
      }
    }

    return ary;
  };

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const { postId } = match.params;

    const getPlaceDetail = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/detail/${postId}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setDetailInfo({
            id: res.data.id,
            like: false,
            imgSrc: res.data.resources,
            category: res.data.space_type,
            title: res.data.name,
            address: res.data.address1 + " " + res.data.address2,
            time: {
              open: res.data.open_time,
              close: res.data.close_time,
            },
            homePage: res.data.homepage,
            tel: res.data.phone,
            dayOff: res.data.holiday,
            content: res.data.more_information,
            center: {
              lat: parseFloat(res.data.latitude),
              lng: parseFloat(res.data.longitude),
            },
            urls: getVideoAry(res.data.resources),
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getPlaceDetail();
  }, [history, match.params]);

  if (detailInfo === null) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <h1 className="page__title page__title-black">
                <span>문화공간 등록하기</span>
              </h1>
              <p className="listing-detail__subtitle">
                <Link to="/main">홈</Link> /{" "}
                <Link to="/my-page/user-info">프로필</Link> /{" "}
                <Link to="/my-page/user-cont-refused">
                  내가 등록신청한 게시물
                </Link>{" "}
                / <span className="active">대기 중 게시물</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="page-top" style={{ textAlign: "center" }}>
        <div
          className="user-stay"
          style={{
            backgroundColor: "grey",
            textAlign: "center",
            padding: "5px",
          }}
        >
          대기 중
        </div>
        심사가 완료될 때까지 최대 2일에서 7일정도의 기간이 소요될 수 있습니다.
        필요한 모든 심사가 완료될 때까지 기다려 주세요.
      </div>

      <section className="listing-detail add-listing">
        <div className="listing-detail__content">
          <div className="container">
            <div className="row">
              <PlaceDetailFormTemplate initForm={detailInfo} />
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <div className="listing-detail__content-box listing-detail__content-box-nopadding">
                  <div
                    className="listing-detail__overview element-waypoint"
                    id="overview-box"
                  >
                    <DetailTapMenu getTapMenu={getTapMenu} />
                    {
                      {
                        0: <Overview initDetail={detailInfo.content} />,
                        1: (
                          <>
                            <SingleMarkerMap
                              center={detailInfo.center}
                              category={detailInfo.category}
                            />
                            <br />
                            <br />
                          </>
                        ),
                        2: (
                          <div id="sc-tab-menu">
                            <div id="tab-tit" className="center-button">
                              <div id="tab-btn">
                                <ul>
                                  <li
                                    className={
                                      reviewBtns.user_review ? "active" : ""
                                    }
                                  >
                                    <a
                                      onClick={() => {
                                        setReviewBtns({
                                          user_review: true,
                                          blog_review: false,
                                          make_reserv: false,
                                        });
                                      }}
                                    >
                                      리뷰
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      reviewBtns.blog_review ? "active" : ""
                                    }
                                  >
                                    <a
                                      onClick={() => {
                                        setReviewBtns({
                                          user_review: false,
                                          blog_review: true,
                                          make_reserv: false,
                                        });
                                      }}
                                    >
                                      블로그
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      reviewBtns.make_reserv ? "active" : ""
                                    }
                                  >
                                    <a
                                      onClick={() => {
                                        setReviewBtns({
                                          user_review: false,
                                          blog_review: false,
                                          make_reserv: true,
                                        });
                                      }}
                                    >
                                      예약 신청
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div id="tab-cont">
                              {reviewBtns.user_review ? (
                                <UserReviewComponent
                                  postId={detailInfo.id}
                                  type="space"
                                />
                              ) : null}
                              {reviewBtns.blog_review ? (
                                <BlogReviewList keyword={detailInfo.title} />
                              ) : null}
                              {reviewBtns.make_reserv ? (
                                <UserReservComponent
                                  postId={detailInfo}
                                  type="space"
                                />
                              ) : null}
                            </div>
                          </div>
                        ),
                        3: <RelatedVideoList videoAry={detailInfo.urls} />,
                      }[tapMenu]
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default WaitingPlaceDetailView;
