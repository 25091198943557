import React, { useState, useRef } from "react";
import Select2 from "react-select2-wrapper";

import PostCode from "components/ect/PostCode";

const weekdaysOpenOptions = [
  { id: "default", text: "오픈 시간" },
  { id: "오전 7:00", text: "오전 7:00" },
  { id: "오전 8:00", text: "오전 8:00" },
  { id: "오전 9:00", text: "오전 9:00" },
  { id: "오전 10:00", text: "오전 10:00" },
  { id: "오전 11:00", text: "오전 11:00" },
  { id: "오후 12:00", text: "오후 12:00" },
  { id: "오후 1:00", text: "오후 1:00" },
  { id: "오후 2:00", text: "오후 2:00" },
  { id: "오후 3:00", text: "오후 3:00" },
  { id: "오후 4:00", text: "오후 4:00" },
  { id: "오후 5:00", text: "오후 5:00" },
  { id: "오후 6:00", text: "오후 6:00" },
  { id: "오후 7:00", text: "오후 7:00" },
  { id: "오후 8:00", text: "오후 8:00" },
  { id: "오후 9:00", text: "오후 9:00" },
  { id: "오후 10:00", text: "오후 10:00" },
  { id: "오후 11:00", text: "오후 11:00" },
  { id: "오전 0:00", text: "오전 0:00" },
];

const weekdaysCloseOptions = [
  { id: "default", text: "마감 시간" },
  { id: "오전 7:00", text: "오전 7:00" },
  { id: "오전 8:00", text: "오전 8:00" },
  { id: "오전 9:00", text: "오전 9:00" },
  { id: "오전 10:00", text: "오전 10:00" },
  { id: "오전 11:00", text: "오전 11:00" },
  { id: "오후 12:00", text: "오후 12:00" },
  { id: "오후 1:00", text: "오후 1:00" },
  { id: "오후 2:00", text: "오후 2:00" },
  { id: "오후 3:00", text: "오후 3:00" },
  { id: "오후 4:00", text: "오후 4:00" },
  { id: "오후 5:00", text: "오후 5:00" },
  { id: "오후 6:00", text: "오후 6:00" },
  { id: "오후 7:00", text: "오후 7:00" },
  { id: "오후 8:00", text: "오후 8:00" },
  { id: "오후 9:00", text: "오후 9:00" },
  { id: "오후 10:00", text: "오후 10:00" },
  { id: "오후 11:00", text: "오후 11:00" },
  { id: "오전 0:00", text: "오전 0:00" },
];

const categoryOptions = [
  { id: "default", text: "종류 선택" },
  { id: "SHOW", text: "공연" },
  { id: "EXHIBITION", text: "전시" },
  { id: "OTHER", text: "기타" },
];

const EventFormTemplate = ({ getFormInfo, formInfo, placeOptions }) => {
  const [imgBase64, setImgBase64] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [regList, setRegList] = useState({
    category: true,
    time: {
      open: true,
      close: true,
    },
    homepage: true,
    reservPage: true,
    tel: true,
    fee: true,
  });

  const onChangeImgFile = (e) => {
    const imgFileAry = e.target.files;

    getFormInfo("imgFile", imgFileAry);

    setImgBase64([]);

    for (let i = 0; i < imgFileAry.length; i++) {
      if (imgFileAry[i]) {
        let reader = new FileReader();

        // 1. 파일 읽어서 버퍼에 저장
        reader.readAsDataURL(imgFileAry[i]);

        // 파일 상태 업데이트
        reader.onloadend = () => {
          // 읽기 완료시, 아래 코드 실행
          const base64 = reader.result;
          console.log(base64);

          if (base64) {
            var base64Sub = base64.toString();

            // 파일 base64 상태 업데이트
            setImgBase64((imgBase64) => [...imgBase64, base64Sub]);
          }
        };
      }
    }
  };

  const regCategoryTest = (category) => {
    if (category === categoryOptions[0].id) {
      if (regList.category)
        setRegList({
          ...regList,
          category: false,
        });
    } else {
      if (!regList.category)
        setRegList({
          ...regList,
          category: true,
        });
    }
  };

  const onChangeCategory = (e) => {
    regCategoryTest(e.target.value);
    getFormInfo("category", e.target.value);
  };

  const onChangeAddress = (result) => {
    getFormInfo("address", result);
    togglePopup();
  };

  const onChangePeriod = (e, period) => {
    if (period === "start") {
      getFormInfo("start", e.target.value);
    } else {
      getFormInfo("end", e.target.value);
    }
  };

  const regTimeTest = (time, when) => {
    switch (when) {
      case "open":
        if (time === "default") {
          if (regList.time.open)
            setRegList({
              ...regList,
              time: {
                open: false,
                close: regList.time.close,
              },
            });

          return false;
        } else {
          if (!regList.time.open)
            setRegList({
              ...regList,
              time: {
                open: true,
                close: regList.time.close,
              },
            });

          return true;
        }

      case "close":
        if (time === "default") {
          if (regList.time.close)
            setRegList({
              ...regList,
              time: {
                open: regList.time.open,
                close: false,
              },
            });

          return false;
        } else {
          if (!regList.time.close)
            setRegList({
              ...regList,
              time: {
                open: regList.time.open,
                close: true,
              },
            });

          return true;
        }
      default:
        return false;
    }
  };

  const onChangeTime = (e, when) => {
    regTimeTest(e.target.value, when);

    if (when === "open") {
      getFormInfo("open", e.target.value);
    } else if (when === "close") {
      getFormInfo("close", e.target.value);
    }
  };

  const homePageInput = useRef();

  const regHomePageTest = (homePage) => {
    const regHomePage =
      /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?$/;

    if (regHomePage.test(homePage)) {
      if (!regList.homepage)
        setRegList({
          ...regList,
          homepage: true,
        });

      homePageInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
    } else {
      if (regList.homepage)
        setRegList({
          ...regList,
          homepage: false,
        });

      homePageInput.current.focus();
      homePageInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
    }
  };

  const onChangeHomePage = (e) => {
    regHomePageTest(e.target.value);

    getFormInfo("homePage", e.target.value);
  };

  const reservPageInput = useRef();
  const regReservPageTest = (reservPage) => {
    const regReservPage =
      /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?$/;

    if (regReservPage.test(reservPage)) {
      if (!regList.reservPage)
        setRegList({
          ...regList,
          reservPage: true,
        });

      reservPageInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
    } else {
      if (regList.reservPage)
        setRegList({
          ...regList,
          reservPage: false,
        });

      reservPageInput.current.focus();
      reservPageInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
    }
  };

  const onChangeReservPage = (e) => {
    regReservPageTest(e.target.value);
    getFormInfo("reservPage", e.target.value);
  };

  const telInput = useRef();
  const regTelTest = (tel) => {
    const regTel = /^[0-9]{2,3}([0-9]{3,4})([0-9]{4})$/;

    if (regTel.test(tel)) {
      if (!regList.tel)
        setRegList({
          ...regList,
          tel: true,
        });

      telInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
    } else {
      if (regList.tel)
        setRegList({
          ...regList,
          tel: false,
        });

      telInput.current.focus();
      telInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
    }
  };
  const onChangeTel = (e) => {
    regTelTest(e.target.value);
    getFormInfo("tel", e.target.value);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [ratingG, setRatingG] = useState(formInfo.age === 0);
  const ratingInput = useRef();
  const onChangeRating = () => {
    setRatingG(!ratingG);

    if (ratingG) {
      ratingInput.current.disabled = false;
      ratingInput.current.placeholder = "권장연령";

      getFormInfo("age", "");
    } else {
      ratingInput.current.disabled = true;
      ratingInput.current.placeholder = "전체관람가";

      getFormInfo("age", 0);
    }
  };

  const feeInput = useRef();
  const [fee, setFee] = useState({
    free: {
      id: 0,
      isActive: formInfo.price === 0,
    },
    pay: {
      id: 1,
      isActive: formInfo.price > 0,
    },
  });
  const onChangeFee = (e, id) => {
    switch (id) {
      case 0:
        setFee({
          free: {
            id: 0,
            isActive: e.target.value,
          },
          pay: {
            id: 1,
            isActive: !e.target.value,
          },
        });
        feeInput.current.disabled = true;
        feeInput.current.placeholder = "0";

        getFormInfo("price", 0);
        break;
      case 1:
        setFee({
          free: {
            id: 0,
            isActive: !e.target.value,
          },
          pay: {
            id: 1,
            isActive: e.target.value,
          },
        });
        feeInput.current.disabled = false;
        feeInput.current.placeholder = "비용";
        getFormInfo("price", "");

        break;
      default:
        break;
    }
  };
  const onChangePay = (e) => {
    if (!fee.pay.isActive)
      setFee({
        free: {
          id: 0,
          isActive: false,
        },
        pay: {
          id: 1,
          isActive: true,
        },
      });

    if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
      getFormInfo("price", e.target.value.replace(/(^0+)/, ""));
    }
  };

  const [showInputBox, setShowInputBox] = useState(true);
  const toggleInputBox = () => {
    setShowInputBox(!showInputBox);
  };

  const getImgSrc = () => {
    if (imgBase64.length === 1) {
      return imgBase64[0];
    } else if (formInfo.imgSrc) {
      return `${process.env.REACT_APP_ADMIN_SERVICE}${formInfo.imgSrc}`;
    } else {
      return "../upload/event-poster-upload.jpg";
    }
  };

  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="sidebar">
          <div className="event-post__gal-box event-gal">
            <img
              className="event-gal-image"
              src={getImgSrc()}
              alt="문화행사 포스터"
            />
          </div>
          <div className="contact-form__upload-btn">
            <input
              className="contact-form__input-file"
              type="file"
              accept="image/*"
              name="photo-upload"
              id="photo-upload"
              onChange={(e) => {
                onChangeImgFile(e);
              }}
            />
            <span>
              <i className="la la-cloud-upload"></i>
              포스터 업로드 (580x816){" "}
            </span>
          </div>
        </div>
      </div>

      <div className="col-lg-8">
        <div className="listing-detail__content-box listing-detail__content-box-nopadding">
          <form className="add-listing__form">
            <div className="container">
              <div
                className="add-listing__form-box element-waypoint"
                id="general-info"
              >
                <div className="add-listing__form-content">
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        제목 :{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="text"
                        name="list-title"
                        id="list-title"
                        placeholder="제목"
                        value={formInfo.title}
                        onChange={(e) => getFormInfo("title", e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="time">
                        카테고리 :{" "}
                      </label>
                    </div>
                    <div className="col-md-7">
                      <Select2
                        className="add-listing__input"
                        name="category"
                        id="category"
                        options={{
                          minimumResultsForSearch: Infinity,
                        }}
                        data={categoryOptions}
                        value={formInfo.category}
                        onChange={(e) => {
                          onChangeCategory(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        장소 :{" "}
                      </label>
                    </div>
                    {showInputBox ? (
                      <div className="flex">
                        <input
                          className="add-listing__input"
                          type="text"
                          name="list-title"
                          id="list-title"
                          placeholder="장소 이름"
                          value={
                            formInfo.placeName === "default"
                              ? ""
                              : formInfo.placeName
                          }
                          onChange={(e) =>
                            getFormInfo("placeName", e.target.value)
                          }
                        />
                      </div>
                    ) : (
                      <div className="flex" style={{ width: "300px" }}>
                        <Select2
                          className="add-listing__input"
                          name="list-title"
                          id="list-title"
                          options={{
                            minimumResultsForSearch: Infinity,
                          }}
                          data={placeOptions}
                          value={formInfo.placeName}
                          onChange={(e) => {
                            getFormInfo("placeName", e.target.value);
                          }}
                        />
                      </div>
                    )}
                    <button
                      className="button-sc__submit btn_float-right"
                      type="button"
                      onClick={toggleInputBox}
                    >
                      {showInputBox ? "장소 선택" : "직접 입력"}
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        주소 :{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        className="add-listing__input"
                        type="adress"
                        name="list-title"
                        id="list-title"
                        placeholder="검색버튼 눌러서 주소 찾기"
                        value={formInfo.address}
                        disabled={true}
                      />
                    </div>
                    <button
                      className="button-sc__submit btn_float-right"
                      type="button"
                      onClick={togglePopup}
                    >
                      검색{" "}
                    </button>
                    {showPopup ? (
                      <PostCode
                        getAddress={onChangeAddress}
                        closePopup={togglePopup}
                      />
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        {" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="text"
                        name="list-title"
                        id="list-title"
                        placeholder="상세주소"
                        value={formInfo.detailAddress}
                        onChange={(e) =>
                          getFormInfo("detailAddress", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        기간 :{" "}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        className="add-listing__input"
                        type="date"
                        name="date"
                        id="list-title"
                        value={
                          Object.keys(formInfo).includes("period")
                            ? formInfo.period.start
                            : ""
                        }
                        onChange={(e) => {
                          onChangePeriod(e, "start");
                        }}
                      />
                    </div>
                    <label className="add-listing__label" htmlFor="list-title">
                      -{" "}
                    </label>
                    <div className="col-md-4">
                      <input
                        className="add-listing__input"
                        type="date"
                        name="date"
                        id="list-title"
                        value={
                          Object.keys(formInfo).includes("period")
                            ? formInfo.period.end
                            : ""
                        }
                        min={
                          Object.keys(formInfo).includes("period")
                            ? formInfo.period.start
                            : ""
                        }
                        onChange={(e) => {
                          onChangePeriod(e, "end");
                        }}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="time">
                        시간 :{" "}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <Select2
                        className="add-listing__input"
                        name="openTime"
                        id="openTime"
                        options={{
                          minimumResultsForSearch: Infinity,
                        }}
                        data={weekdaysOpenOptions}
                        value={
                          Object.keys(formInfo).includes("time")
                            ? formInfo.time.open
                            : "default"
                        }
                        onChange={(e) => {
                          onChangeTime(e, "open");
                        }}
                      />
                    </div>
                    <label className="add-listing__label" htmlFor="list-title">
                      -{" "}
                    </label>
                    <div className="col-md-4">
                      <Select2
                        className="add-listing__input"
                        name="closeTime"
                        id="closeTime"
                        options={{
                          minimumResultsForSearch: Infinity,
                        }}
                        data={weekdaysCloseOptions}
                        value={
                          Object.keys(formInfo).includes("time")
                            ? formInfo.time.close
                            : "default"
                        }
                        onChange={(e) => {
                          onChangeTime(e, "close");
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        권장연령 :{" "}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        className="add-listing__input"
                        type="number"
                        name=" list-title"
                        id="list-title"
                        value={formInfo.age}
                        placeholder="권장연령"
                        ref={ratingInput}
                        onChange={(e) => getFormInfo("age", e.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="add-listing__input"
                        type="checkbox"
                        checked={ratingG}
                        onChange={onChangeRating}
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        전체관람가{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        홈페이지 :{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="url"
                        name="list-title"
                        id="list-title"
                        value={formInfo.homePage}
                        placeholder="http://"
                        onChange={(e) => onChangeHomePage(e)}
                        ref={homePageInput}
                      />
                      {regList.homepage === false ? (
                        <p
                          style={{
                            marginTop: "-16px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 홈페이지가 올바르지 않습니다
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        예매 사이트 :{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="url"
                        name="list-title"
                        id="list-title"
                        value={formInfo.reservPage}
                        placeholder="http://"
                        onChange={(e) => onChangeReservPage(e)}
                        ref={reservPageInput}
                      />
                      {regList.reservPage === false ? (
                        <p
                          style={{
                            marginTop: "-16px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 예매 사이트가 올바르지 않습니다
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        전화번호 :{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="tel"
                        name="list-title"
                        id="list-title"
                        value={formInfo.tel}
                        placeholder="전화번호"
                        onChange={(e) => onChangeTel(e)}
                        ref={telInput}
                      />
                      {regList.tel === false ? (
                        <p
                          style={{
                            marginTop: "-16px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 전화번호가 올바르지 않습니다
                          <br />
                          -을 제외한 숫자만 입력해주세요
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        가격 :{" "}
                      </label>
                    </div>
                    <div className="col-md-1">
                      <input
                        className="add-listing__input"
                        type="radio"
                        checked={fee.free.isActive}
                        onChange={(e) => onChangeFee(e, 0)}
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        무료{" "}
                      </label>
                    </div>
                    <div className="col-md-1">
                      <input
                        className="add-listing__input"
                        type="radio"
                        checked={fee.pay.isActive}
                        onChange={(e) => onChangeFee(e, 1)}
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        유료{" "}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        className="add-listing__input add-listing__input-half"
                        type="text"
                        name=" list-title"
                        id="list-title"
                        placeholder="비용"
                        value={formInfo.price === "init" ? "" : formInfo.price}
                        ref={feeInput}
                        onChange={(e) => onChangePay(e)}
                      />
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        원{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventFormTemplate;
