import React, { useState, useEffect } from "react";
import axios from "axios";

import PageShareModal from "components/ect/shareBtn/PageShareModal";

const addDot = (string) => {
  let result = "";
  return result.concat(
    string.slice(0, 4),
    ".",
    string.slice(4, 6),
    ".",
    string.slice(6)
  );
};

const EventDetailForm = ({ initForm }) => {
  const [shareBtn, setShareBtn] = useState(false);
  const [isFavorite, setIsFavorite] = useState();

  const onToggleShareBtn = () => {
    setShareBtn(!shareBtn);
  };

  const closeShareModal = () => {
    onToggleShareBtn();
  };

  const onClickUnLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}/${initForm.id}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}`;

    let data = new Object();
    data.eventIds = [initForm.id];

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  useEffect(() => {
    setIsFavorite(initForm.like);
  }, [initForm.like]);

  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="sidebar">
          <div className="event-post__gal-box event-gal">
            <img
              className="event-gal-image"
              src={getImgSrc(initForm.imgSrc)}
              alt="문화행사 포스터"
            />
          </div>
          <div className="listing-detail__buttons listing-detail__buttons-icons">
            <a
              className={isFavorite ? "btn-default-active" : "btn-default"}
              onClick={
                window.location.href.includes("my-page")
                  ? null
                  : isFavorite
                  ? onClickUnLikedBtn
                  : onClickLikedBtn
              }
            >
              <i
                className={isFavorite ? "la la-heart" : "la la-heart-o"}
                aria-hidden="true"
              ></i>
            </a>
            <a
              className="btn-default"
              onClick={
                window.location.href.includes("my-page")
                  ? null
                  : onToggleShareBtn
              }
            >
              <i className="la la-share-alt" aria-hidden="true"></i>
            </a>
            {shareBtn ? (
              <PageShareModal
                closeShareModal={closeShareModal}
                url={window.location.href}
                title={initForm.title}
                address={initForm.address}
                category={
                  {
                    SHOW: "공연",
                    EXHIBITION: "전시",
                    OTHER: "기타 행사",
                  }[initForm.category]
                }
                imgUrl={getImgSrc(initForm.imgSrc)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="listing-detail__content-box listing-detail__content-box-nopadding">
          <form className="add-listing__form">
            <div className="container">
              <dl>
                <h2 className="">{initForm.title}</h2>
                <dt>장소</dt>
                <dd>
                  {initForm.placeName === "" ? <br /> : initForm.placeName}
                </dd>
                <dt>주소</dt>
                <dd>{initForm.address === " " ? <br /> : initForm.address}</dd>
                <dt>기간</dt>
                <dd>
                  {addDot(initForm.period.start)} -{" "}
                  {addDot(initForm.period.end)}
                </dd>
                <dt>시간</dt>
                <dd>
                  {initForm.time.open === "default" &&
                  initForm.time.close === "default" ? (
                    <br />
                  ) : (
                    `${
                      initForm.time.open === "default" ? "" : initForm.time.open
                    } ~${
                      initForm.time.close === "default"
                        ? ""
                        : initForm.time.close
                    }`
                  )}
                </dd>
                <dt>관람연령</dt>
                <dd>
                  {initForm.age === 0
                    ? "전체 관람가"
                    : `만 ${initForm.age}세 이상`}
                </dd>
                <dt>홈페이지</dt>
                <dd>
                  {initForm.homePage === "" ? (
                    <br />
                  ) : (
                    <a
                      href={initForm.homePage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {initForm.homePage}
                    </a>
                  )}
                </dd>
                <dt>예매사이트</dt>
                <dd>
                  {initForm.reservPage === "" ? (
                    <br />
                  ) : (
                    <a
                      href={initForm.reservPage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {initForm.reservPage}
                    </a>
                  )}
                </dd>
                <dt>전화번호</dt>
                <dd>{initForm.tel === "" ? <br /> : initForm.tel}</dd>
                <dt>가격</dt>
                <dd>{initForm.price === 0 ? "무료" : `${initForm.price}원`}</dd>
              </dl>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventDetailForm;
