import React, { useState, useEffect } from "react";
import PageShareModal from "components/ect/shareBtn/PageShareModal";
import axios from "axios";

const DetailPageTitle = ({ detailInfo }) => {
  // const getPriceLabel = (price) => {
  //   let priceLabelList = [];

  //   if (0 < price && price <= 20000) {
  //     priceLabelList.push(
  //       <i className="fa fa-usd red-col" aria-hidden="true"></i>
  //     );
  //     for (let i = 0; i < 3; i++) {
  //       priceLabelList.push(<i className="fa fa-usd" aria-hidden="true"></i>);
  //     }
  //   } else if (20000 < price && price < 35000) {
  //     for (let i = 0; i < 2; i++) {
  //       priceLabelList.push(
  //         <i className="fa fa-usd red-col" aria-hidden="true"></i>
  //       );
  //     }
  //     for (let i = 0; i < 2; i++) {
  //       priceLabelList.push(<i className="fa fa-usd" aria-hidden="true"></i>);
  //     }
  //   } else if (35000 <= price && price < 50000) {
  //     for (let i = 0; i < 3; i++) {
  //       priceLabelList.push(
  //         <i className="fa fa-usd red-col" aria-hidden="true"></i>
  //       );
  //     }
  //     priceLabelList.push(<i className="fa fa-usd" aria-hidden="true"></i>);
  //   } else if (50000 <= price) {
  //     for (let i = 0; i < 4; i++) {
  //       priceLabelList.push(
  //         <i className="fa fa-usd red-col" aria-hidden="true"></i>
  //       );
  //     }
  //   } else {
  //     for (let i = 0; i < 4; i++) {
  //       priceLabelList.push(<i className="fa fa-usd" aria-hidden="true"></i>);
  //     }
  //   }

  //   return priceLabelList;
  // };

  const [shareBtn, setShareBtn] = useState(false);
  const [isFavorite, setIsFavorite] = useState();

  const onToggleShareBtn = () => {
    setShareBtn(!shareBtn);
  };

  const closeShareModal = () => {
    onToggleShareBtn();
  };

  const onClickUnLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}/${
      detailInfo.id
    }`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}`;

    let data = new Object();
    data.eventIds = [detailInfo.id];

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return `${process.env.REACT_APP_ADMIN_SERVICE}/${resources.images[0].url}`;
    } else {
      return process.env.PUBLIC_URL + "/upload/place-poster1.jpg";
    }
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  useEffect(() => {
    setIsFavorite(detailInfo.like);
  }, [detailInfo.like]);

  return (
    <section className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-sm-9">
            <h1 className="page__title page__title-black">
              <span>{detailInfo.title}</span>
              <span className="listing-detail__rate">
                {detailInfo.averageScore ? detailInfo.averageScore : "0.0"}
                <span>/ 5</span>
              </span>
            </h1>
            <p className="listing-detail__address">
              <i className="fa fa-map-marker"></i>
              {detailInfo.address}{" "}
            </p>
          </div>
          <div className="col-sm-3">
            <div className="listing-detail__buttons listing-detail__buttons-icons">
              <a
                className={isFavorite ? "btn-default-active" : "btn-default"}
                onClick={
                  window.location.href.includes("my-page") ||
                  window.location.href.includes("place")
                    ? null
                    : isFavorite
                    ? onClickUnLikedBtn
                    : onClickLikedBtn
                }
              >
                <i
                  className={isFavorite ? "la la-heart" : "la la-heart-o"}
                  aria-hidden="true"
                ></i>
              </a>
              <a
                className="btn-default"
                onClick={
                  window.location.href.includes("my-page")
                    ? null
                    : onToggleShareBtn
                }
              >
                <i className="la la-share-alt" aria-hidden="true"></i>
              </a>
              {shareBtn ? (
                <PageShareModal
                  closeShareModal={closeShareModal}
                  url={window.location.href}
                  title={detailInfo.title}
                  address={detailInfo.address}
                  category={
                    {
                      SHOW: "공연",
                      EXHIBITION: "전시",
                      OTHER: "기타 행사",
                      HALL: "공연장",
                      PRACTICE: "연습실",
                      INSTRUMENT: "악기상점",
                      GALLERY: "미술관/갤러리",
                    }[detailInfo.category]
                  }
                  imgUrl={
                    window.location.href.includes("event")
                      ? getImgSrc(detailInfo.imgSrc)
                      : getImage(detailInfo.imgSrc)
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailPageTitle;
