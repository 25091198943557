import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MobileContext from "context/mobile";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import ScEventList from "./sc-event-template/ScEventList";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const count = 5;

const ScEventView = ({ pageTitle }) => {
  const { state } = useContext(MobileContext);

  const [curPage, setCurPage] = useState(1);

  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const [eventList, setEventList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const getScEventList = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/seochogu-festival/list`;

      try {
        const res = await axios.get(url, {
          params: {
            page: curPage,
            count: count,
            search_type: "STATE",
            search_word: "POST",
          },
        });

        if (res.status === 200) {
          console.log(res.data.list);
          setEventList(res.data.list);
          setTotalCount(res.data.total_count);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getScEventList();
  }, [curPage]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle={pageTitle} />

      <div class="container">
        <div class="explore-title__box">
          <h2 class="explore__filter-title">
            <span>총</span> {totalCount}건
          </h2>
        </div>
        {eventList.map((scEvent) => (
          <ScEventList scEvent={scEvent} key={scEvent.id} />
        ))}
        <Paging
          pageNum={curPage}
          getCurPage={getCurPage}
          onePage={5}
          all={totalCount}
        />{" "}
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default ScEventView;
