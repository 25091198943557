import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import MenuContext from "context/menu";

import Header from "../ect/Header";
import PostCode from "components/ect/PostCode";
import GoogleLoginBtn from "./login/GoogleLoginBtn";
import NaverLoginBtn from "./login/NaverLoginBtn";
import FacebookLoginBtn from "./login/FacebookLoginBtn";
import { useHistory } from "react-router";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";
import AppleLoginBtn from "./login/AppleLoginBtn";

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);

  let str = "" + date.getFullYear();

  if (date.getMonth() < 9) {
    str += "0" + (date.getMonth() + 1);
  } else {
    str += date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    str += "0" + date.getDate();
  } else {
    str += date.getDate();
  }
  return str;
};

const Login = () => {
  const { state, actions } = useContext(MenuContext);
  const isMobile = useContext(MobileContext).state.isMobile;

  const history = useHistory();
  const today = new Date();
  const maxDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [loginInfo, setLoginInfo] = useState({
    id: "",
    pwd: "",
  });

  const onChangeLoginId = (e) => {
    setLoginInfo({
      ...loginInfo,
      id: e.target.value,
    });
  };

  const onChangeLoginPwd = (e) => {
    setLoginInfo({
      ...loginInfo,
      pwd: e.target.value,
    });
  };

  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies("saveId");

  // const checkBox = useRef();

  const onChangeCheckBox = () => {
    setIsRemember(!isRemember);
  };

  const login = (e) => {
    e.preventDefault();

    if (isRemember) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 30);

      setCookie("saveId", loginInfo.id, { path: "/", expires: expires });
    } else {
      removeCookie("saveId");
    }

    let data = new Object();

    data.id = loginInfo.id;
    data.password = loginInfo.pwd;

    requestLogin(data);
  };

  const requestLogin = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/login`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 200) {
        console.log("===== 로그인 성공 ====");

        window.sessionStorage.setItem("token", res.headers.token);
        window.sessionStorage.setItem("userid", res.headers.userid);

        // 로그인 인포
        window.sessionStorage.setItem("isFirstVisit", "2");

        history.push("/");
      }
    } catch (e) {
      console.log("===== 로그인 요청 실패 =====");
      console.log(e);
      alert("아이디와 비밀번호를 확인해주세요.");
    }
  };

  const [joinFormInfo, setJoinFormInfo] = useState({
    id: "",
    name: "",
    pwd: "",
    tel: "",
    email: "",
    birth: "",
    address: "",
    detailAddress: "",
  });
  const [secondPwd, setSecondPwd] = useState("");

  const nameInput = useRef();
  const idInput = useRef();
  const pwdInput = useRef();
  const secondPwdInput = useRef();
  const telInput = useRef();
  const emailInput = useRef();

  const [regList, setRegList] = useState({
    id: true,
    idOverlap: "none",
    name: true,
    nameVerification: "none",
    pwd: true,
    secondPwd: true,
    tel: true,
    email: true,
  });

  const regIdTest = (id) => {
    const regId = /^[a-zA-Z]{1}[a-z\d#@$!%*?&^-]{4,19}$/;

    if (regId.test(id) === false) {
      if (regList.id === true) {
        setRegList({
          ...regList,
          id: false,
        });
      }

      idInput.current.focus();
      idInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    } else {
      if (regList.id === false) {
        setRegList({
          ...regList,
          id: true,
        });
      }

      idInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    }
  };

  const onChangeJoinId = (e) => {
    regIdTest(e.target.value);
    setJoinFormInfo({
      ...joinFormInfo,
      id: e.target.value,
    });
  };

  const regNameTest = (name) => {
    const regNameKorean = /^[가-힣]{2,15}$/; // 한글 2 ~ 15자
    const regNameEnglish = /^[a-zA-Z\s]{2,30}$/; // 영어 2자 ~ 30자 (공백 가능)

    if (regNameKorean.test(name) || regNameEnglish.test(name)) {
      if (regList.name === false) {
        setRegList({
          ...regList,
          name: true,
        });
      }

      nameInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.name === true) {
        setRegList({
          ...regList,
          name: false,
        });
      }

      nameInput.current.focus();
      nameInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };

  const onChangeJoinName = (e) => {
    regNameTest(e.target.value);

    setJoinFormInfo({
      ...joinFormInfo,
      name: e.target.value,
    });
  };

  const regPwdTest = (pwd) => {
    const regPwdNumCha =
      /^(?=.*[a-zA-Z])(?=.*[#@$!%*?&^-])(?=.*\d)[a-zA-Z\d#@$!%*?&^-]{10,}$/;
    const regPwdNum = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;
    const regPwdCha =
      /^(?=.*[a-zA-Z])(?=.*[#@$!%*?&^-])[a-zA-Z#@$!%*?&^-]{10,}$/;

    if (regPwdNumCha.test(pwd) || regPwdNum.test(pwd) || regPwdCha.test(pwd)) {
      if (!regList.pwd) {
        setRegList({
          ...regList,
          pwd: true,
        });
      }
      pwdInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.pwd)
        setRegList({
          ...regList,
          pwd: false,
        });

      pwdInput.current.focus();
      pwdInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };

  const onChangeJoinPwd = (e) => {
    regPwdTest(e.target.value);

    setJoinFormInfo({
      ...joinFormInfo,
      pwd: e.target.value,
    });
  };

  const regSecondPwdTest = (pwd) => {
    if (pwd !== joinFormInfo.pwd) {
      if (regList.secondPwd) {
        setRegList({
          ...regList,
          secondPwd: false,
        });
      }
      secondPwdInput.current.focus();
      secondPwdInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    } else {
      if (!regList.secondPwd) {
        setRegList({
          ...regList,
          secondPwd: true,
        });
      }
      secondPwdInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    }
  };

  const onChangeJoinSecondPwd = (e) => {
    regSecondPwdTest(e.target.value);
    setSecondPwd(e.target.value);
  };

  const regTelTest = (tel) => {
    const regPhone = /^[0-9]{2,3}([0-9]{3,4})([0-9]{4})$/;

    if (regPhone.test(tel)) {
      if (!regList.tel)
        setRegList({
          ...regList,
          tel: true,
        });

      telInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.tel)
        setRegList({
          ...regList,
          tel: false,
        });

      telInput.current.focus();
      telInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };

  const onChangeJoinTel = (e) => {
    regTelTest(e.target.value);

    setJoinFormInfo({
      ...joinFormInfo,
      tel: e.target.value,
    });
  };

  const regEmailTest = (email) => {
    const regEmail = /^[a-zA-Z0-9\.\-_]+@([a-zA-Z0-9\-]+\.)+[a-z]{2,6}$/;

    if (!regEmail.test(email)) {
      if (regList.email)
        setRegList({
          ...regList,
          email: false,
        });

      emailInput.current.focus();
      emailInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    } else {
      if (!regList.email)
        setRegList({
          ...regList,
          email: true,
        });

      emailInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    }
  };

  const onChangeJoinEmail = (e) => {
    regEmailTest(e.target.value);
    setJoinFormInfo({
      ...joinFormInfo,
      email: e.target.value,
    });
  };

  const onChangeJoinBirth = (e) => {
    setJoinFormInfo({
      ...joinFormInfo,
      birth: e.target.value,
    });
  };

  const onChangeAddress = (result) => {
    setJoinFormInfo({
      ...joinFormInfo,
      address: result,
    });

    togglePopup();
  };

  const onChangeDetailAddress = (e) => {
    setJoinFormInfo({
      ...joinFormInfo,
      detailAddress: e.target.value,
    });
  };

  const join = () => {
    // id
    if (!regIdTest(!joinFormInfo.id)) {
      alert("입력하신 id를 확인해주세요");
      return;
    }
    // id 중복 확인
    else if (regList.idOverlap === "none") {
      alert("아이디 중복확인을 해주세요");
      return;
    }
    // 이름
    else if (!regNameTest(joinFormInfo.name)) {
      alert("입력하신 이름을 확인해주세요");
      return;
    }
    // 이름 실명인증
    else if (
      regList.nameVerification === "none" ||
      regList.nameVerification === "false"
    ) {
      alert("실명 인증을 완료해주세요");
      return;
    }
    // 비밀번호
    else if (!regPwdTest(joinFormInfo.pwd)) {
      alert("입력하신 비밀번호를 확인해주세요");
      return;
    } else if (!regSecondPwdTest(secondPwd)) {
      alert("입력하신 비밀번호가 일치하지 않습니다");
      return;
    }
    // 전화번호
    else if (!regTelTest(joinFormInfo.tel)) {
      alert("입력하신 전화번호를 확인해주세요");
      return;
    }
    // 이메일
    else if (!regEmailTest(joinFormInfo.email)) {
      alert("입력하신 이메일을 확인해주세요");
      return;
    }

    let data = new Object();

    data.id = joinFormInfo.id;
    data.name = joinFormInfo.name;
    data.password = joinFormInfo.pwd;
    data.phone = joinFormInfo.tel;
    data.email = joinFormInfo.email;
    data.birthday =
      joinFormInfo.birth !== "" ? convertDateFormat(joinFormInfo.birth) : "";
    data.address1 = joinFormInfo.address;
    data.address2 = joinFormInfo.detailAddress;

    console.log(data);

    requestRigstUser(data);
  };

  const requestRigstUser = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/user/regist`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 200) {
        console.log(" ====== 회원가입 성공 ====== ");
        alert("회원가입이 완료되었습니다. ");
        actions.setLoginMenu(0);
      }
    } catch (e) {
      console.log("====== 회원가입 요청 실패 =======");
      console.log(e);
    }
  };

  const loginByNaver = () => {
    if (
      document &&
      document?.querySelector("#naverIdLogin")?.firstChild &&
      window !== undefined
    ) {
      const loginBtn = document.getElementById("naverIdLogin")?.firstChild;
      loginBtn.click();
    }
  };

  // const loginByApplId = () => {
  //   if (
  //     document &&
  //     document?.querySelector("#appleid-signin")?.firstChild &&
  //     window !== undefined
  //   ) {
  //     const loginBtn = document.getElementById("appleid-signin")?.firstChild;

  //     loginBtn.click();
  //   }
  // };

  const onClickVerificationId = () => {
    if (regList.id === false) {
      alert(
        "중복 확인은 올바른 형식의 아이디만 가능합니다.\n아이디 형식을 확인해주세요."
      );
      return;
    }

    const checkIdValidation = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE}/api/user/exists/${joinFormInfo.id}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          if (res.data === 1) {
            setRegList({
              ...regList,
              idOverlap: true,
            });

            idInput.current.focus();
            idInput.current.style =
              "background:#FBFBEF; border: 1px solid #ef5797;";
          } else {
            setRegList({
              ...regList,
              idOverlap: false,
            });
            idInput.current.style =
              "background:#FFFFFF; border: 1px solid #dddddd;";
            idInput.current.disabled = true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    checkIdValidation();
  };

  const onClickVerificationName = () => {
    if (joinFormInfo.name === "") {
      alert("이름을 입력하신 뒤, 실명 인증을 진행해주세요.");
      return;
    }

    let popup = window.open(
      "https://culture.seocho.go.kr:8443/mobileAuth/request",
      "verificationWindow",
      "width=455px , height=100%"
    );

    const successToVerification = (e) => {
      popup.close();
      if (e.data.message === "OK") {
        setRegList({
          ...regList,
          nameVerification: true,
        });

        setJoinFormInfo({
          ...joinFormInfo,
          tel: e.data.cellNo,
        });

        nameInput.current.disabled = true;
        nameInput.current.style =
          "background:#FFFFFF; border: 1px solid #dddddd;";
      } else {
        setRegList({
          ...regList,
          nameVerification: false,
        });

        nameInput.current.disabled = false;
        nameInput.current.focus();
        nameInput.current.style =
          "background:#FBFBEF; border: 1px solid #ef5797;";
      }
    };

    window.addEventListener("message", successToVerification, false);
  };

  useEffect(() => {
    if (cookies.saveId !== undefined) {
      setLoginInfo({
        id: cookies.saveId,
        pwd: "",
      });
      setIsRemember(true);
    }
  }, []);

  return (
    <div id="container">
      <Header />

      <section
        className="sign"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="sign__area">
          <nav>
            <div className="nav nav-tabs" id="nav-tab">
              <a
                className={
                  state.loginMenu === 0
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                onClick={() => actions.setLoginMenu(0)}
                style={{
                  cursor: "pointer",
                }}
              >
                로그인
              </a>
              <a
                className={
                  state.loginMenu === 1
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                onClick={() => actions.setLoginMenu(1)}
                style={{
                  cursor: "pointer",
                }}
              >
                회원가입
              </a>
            </div>
          </nav>
          <div style={{ display: "block", backgroundColor: "#ffff" }}>
            {
              {
                0: (
                  <div>
                    <form className="tab-form" onSubmit={login}>
                      <label className="tab-form__label" htmlFor="username">
                        아이디{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="text"
                        name="username"
                        id="username"
                        placeholder="아이디를 입력하세요"
                        value={loginInfo.id}
                        onChange={(e) => {
                          onChangeLoginId(e);
                        }}
                      />
                      <label className="tab-form__label" htmlFor="password">
                        비밀번호{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="비밀번호를 입력하세요"
                        value={loginInfo.pwd}
                        onChange={(e) => {
                          onChangeLoginPwd(e);
                        }}
                        autoComplete="on"
                      />
                      <div className="tab-form__checkbox">
                        <input
                          className="tab-form__input-checkbox"
                          type="checkbox"
                          name="rememb-check"
                          id="rememb-check"
                          // ref={checkBox}
                          checked={isRemember}
                          onClick={() => onChangeCheckBox()}
                        />
                        <span className="tab-form__checkbox-style"></span>
                        <span className="tab-form__checkbox-text">
                          기억하기
                        </span>
                      </div>

                      <span
                        className="tab-form__forget-link"
                        onClick={() => history.push("/common/find-id-pwd")}
                      >
                        아이디/비밀번호 찾기
                      </span>
                      <button
                        className="tab-form__submit"
                        id="submit-loggin"
                        type="submit"
                      >
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                        로그인{" "}
                      </button>
                      <p className="tab-form__text">SNS 계정으로 로그인하기</p>
                      <ul className="tab-form__social">
                        <li>
                          <FacebookLoginBtn />
                        </li>
                        <li>
                          <GoogleLoginBtn />
                        </li>
                        <li>
                          <NaverLoginBtn />
                          <a
                            className="naver"
                            style={{
                              color: "#2DB400",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={loginByNaver}
                          >
                            N
                          </a>
                        </li>
                        <li>
                          <AppleLoginBtn />
                        </li>
                      </ul>
                    </form>
                  </div>
                ),
                1: (
                  <div>
                    <div className="tab-form">
                      <label className="tab-form__label" htmlFor="id">
                        아이디{" "}
                      </label>
                      <div className="row">
                        <div className="flex">
                          <input
                            className="tab-form__input-text"
                            type="text"
                            name="id"
                            id="id"
                            placeholder="영문 대소문자, 숫자, 특수문자 조합, 5~20자 이상"
                            value={joinFormInfo.id}
                            ref={idInput}
                            onChange={(e) => {
                              onChangeJoinId(e);
                            }}
                          />
                        </div>
                        <input
                          type="submit"
                          name="btn_js_alert"
                          id="btn_js_confirm"
                          className="button-sc__submit btn_float-left"
                          onClick={onClickVerificationId}
                          value="중복확인"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      {regList.idOverlap === true ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          이미 존재하는 아이디입니다. 다른 아이디를
                          사용해주세요.
                        </p>
                      ) : regList.idOverlap === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          사용가능한 아이디 입니다.
                        </p>
                      ) : (
                        ""
                      )}
                      {regList.id === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          아이디 형식을 확인해주세요
                          <br />
                          5~20개, 영문 대소문자 포함, 숫자 또는
                          특수문자(#@$!%*?&amp;-^) 조합
                        </p>
                      ) : (
                        ""
                      )}

                      <label className="tab-form__label" htmlFor="username2">
                        이름{" "}
                      </label>
                      <div className="row">
                        <div className="flex">
                          <input
                            className="tab-form__input-text"
                            type="text"
                            name="username"
                            id="username"
                            placeholder="이름"
                            value={joinFormInfo.name}
                            ref={nameInput}
                            onChange={(e) => {
                              onChangeJoinName(e);
                            }}
                          />
                        </div>
                        <input
                          type="submit"
                          name="btn_js_alert"
                          id="btn_js_confirm"
                          className="button-sc__submit btn_float-left"
                          onClick={onClickVerificationName}
                          value="실명인증"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      {regList.nameVerification === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증에 실패하였습니다.
                        </p>
                      ) : regList.nameVerification === true ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증에 성공하였습니다.
                        </p>
                      ) : (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          이름을 입력하신 후에 실명인증을 진행해주세요.
                        </p>
                      )}
                      {regList.name === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          이름을 확인해주세요
                        </p>
                      ) : (
                        ""
                      )}
                      <label className="tab-form__label" htmlFor="password1">
                        비밀번호{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="password"
                        name="password1"
                        id="password1"
                        placeholder="영문 대소문자 포함, 숫자 또는 특수문자 중 2가지 이상 조합, 10자 이상"
                        value={joinFormInfo.pwd}
                        ref={pwdInput}
                        onChange={(e) => {
                          onChangeJoinPwd(e);
                        }}
                      />
                      {regList.pwd === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          비밀번호 형식을 확인해주세요
                          <br />
                          10자 이상, 영문 대소문자 포함, 숫자 또는
                          특수문자(#@$!%*?&amp;-^) 조합
                        </p>
                      ) : (
                        ""
                      )}
                      <label
                        className="tab-form__label"
                        htmlFor="con-password2"
                      >
                        비밀번호 확인{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="password"
                        name="con-password2"
                        id="con-password2"
                        placeholder="비밀번호를 확인을 위해 다시 한번 입력해주세요"
                        value={secondPwd}
                        ref={secondPwdInput}
                        onChange={(e) => {
                          onChangeJoinSecondPwd(e);
                        }}
                      />
                      {regList.secondPwd === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 비밀번호가 일치하지 않습니다
                        </p>
                      ) : (
                        ""
                      )}
                      <label className="tab-form__label" htmlFor="tel">
                        전화번호{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="tel"
                        name="tel"
                        id="tel"
                        placeholder="전화번호"
                        value={joinFormInfo.tel}
                        // onChange={(e) => {
                        //   onChangeJoinTel(e);
                        // }}
                        ref={telInput}
                        disabled={true}
                      />
                      {regList.tel === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 전화번호가 올바르지 않습니다
                          <br />
                          -을 제외한 숫자만 입력해주세요
                        </p>
                      ) : (
                        ""
                      )}
                      <label className="tab-form__label" htmlFor="email">
                        이메일{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="email"
                        name="email"
                        id="emil"
                        placeholder="사용하실 이메일을 입력해주세요"
                        value={joinFormInfo.email}
                        onChange={(e) => {
                          onChangeJoinEmail(e);
                        }}
                        ref={emailInput}
                      />
                      {regList.email === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 이메일이 올바르지 않습니다
                        </p>
                      ) : (
                        ""
                      )}
                      <hr />
                      <label className="tab-form__label" htmlFor="birth">
                        생년월일 (선택){" "}
                      </label>
                      <input
                        className="add-listing__input"
                        type="date"
                        name="birth"
                        id="birth"
                        max={maxDate}
                        value={joinFormInfo.birth}
                        onChange={(e) => {
                          onChangeJoinBirth(e);
                        }}
                      />
                      <label
                        className="tab-form__label"
                        style={{ width: "100%" }}
                        htmlFor="adress"
                      >
                        주소 (선택){" "}
                      </label>
                      <div className="row">
                        <div className="flex">
                          <input
                            className="tab-form__input-text"
                            type="text"
                            name="adress"
                            id="adress"
                            placeholder="검색버튼 눌러서 주소찾기"
                            value={joinFormInfo.address}
                            disabled={true}
                          />
                        </div>
                        <button
                          className="button-sc__submit btn_float-left"
                          type="button"
                          onClick={togglePopup}
                        >
                          주소검색{" "}
                        </button>
                        {showPopup ? (
                          <PostCode
                            getAddress={onChangeAddress}
                            closePopup={togglePopup}
                          />
                        ) : null}
                      </div>
                      <input
                        className="tab-form__input-text"
                        type="text"
                        name="detail-address"
                        id="detail-address"
                        placeholder="상세주소를 입력해주세요"
                        value={joinFormInfo.detailAddress}
                        onChange={(e) => {
                          onChangeDetailAddress(e);
                        }}
                      />
                      <input
                        type="button"
                        name="btn_js_alert"
                        id="btn_js_confirm"
                        className="tab-form__submit"
                        value="회원가입"
                        onClick={join}
                      />
                      <p className="tab-form__text">
                        SNS 계정으로 회원가입하기
                      </p>
                      <ul className="tab-form__social">
                        <li>
                          <FacebookLoginBtn />
                        </li>
                        <li>
                          <GoogleLoginBtn />
                        </li>

                        <li>
                          <NaverLoginBtn />
                          <a
                            className="naver"
                            style={{
                              color: "#2DB400",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={loginByNaver}
                          >
                            N
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ),
              }[state.loginMenu]
            }
          </div>
        </div>
      </section>
      {isMobile ? <BottomNavigation /> : ""}
    </div>
  );
};

export default Login;
