import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import UserReviewForm from "./UserReviewForm";
import UserReviewList from "./UserReviewList";
import Paging from "../Paging";

const UserReviewComponent = ({ postId, type }) => {
  const history = useHistory();

  const [curPage, setCurPage] = useState(1);
  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };

  const onConfirmMoveLogin = () => {
    history.push("/common/log-in");
  };

  const comfirmLogin = useConfirm(
    "로그인 후에 이용하실 수 있습니다.\n로그인 화면으로 이동하시겠습니까?",
    onConfirmMoveLogin
  );

  const addReview = (content, stars) => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      comfirmLogin();
      return;
    }

    if (window.location.href.includes("-detail-waiting")) {
      alert("대기중인 게시물은 리뷰를 등록하실 수 없습니다.");
      return;
    }

    let data = new Object();

    data.reviewContentId = postId;
    data.contentType = type;
    data.content = content;
    data.score = stars;

    registRview(data);
  };

  const registRview = async (data) => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/admin/review/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        getReviewList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteReview = async (rId) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/admin/review/${rId}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        getReviewList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [totalRows, setTotalRows] = useState(0);
  const [reviewList, setReviewList] = useState([]);

  const getReviewList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/admin/review/${type}/${postId}`;

    try {
      const res = await axios.get(url, {
        params: {
          page: curPage,
          rows: 5,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;
        setTotalRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].id,
            user: data[i].createUid,
            date: data[i].reviewDate,
            content: data[i].content,
            stars: data[i].score,
          });
        }

        setReviewList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPage, postId, type]);

  useEffect(() => {
    getReviewList();
  }, [getReviewList]);

  return (
    <div>
      <h2 className="listing-detail__content-title">리뷰({totalRows}건)</h2>
      <UserReviewForm addReview={addReview} />
      <UserReviewList reviewList={reviewList} deleteReview={deleteReview} />
      <Paging
        pageNum={curPage}
        getCurPage={getCurPage}
        onePage={5}
        all={totalRows}
      />
    </div>
  );
};
export default UserReviewComponent;
