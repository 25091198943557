import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PlaceListItem from "components/place/place-template/PlaceListItem";

const PlaceList = ({ placeList }) => {
  const showMaxCnt = 4;

  const setting = {
    dots: false,
    infinite: placeList.length >= showMaxCnt,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...setting} className="trending-events__box">
      {placeList.map((placeInfo) => (
        <div className="items" key={placeInfo.id}>
          <div className="item">
            <PlaceListItem placeInfo={placeInfo} />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default PlaceList;
