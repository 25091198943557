import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MobileContext from "../../context/mobile";
import { Grid } from "@mui/material";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import EventListItem from "components/event/event-template/EventListItem";
import PlaceListItem from "components/place/place-template/PlaceListItem";
import MyPageMenu from "./MyPageMenu";

import Paging from "components/ect/Paging";
import BottomNavigation from "../ect/BottomNavigation";

const count = 6;

const RegistrationApplicationView = () => {
  const { state } = useContext(MobileContext);
  const history = useHistory();

  const [curEventPage, setCurEventPage] = useState(1);
  const [curPlacePage, setCurPlacePage] = useState(1);
  const getCurEventPage = (pageNum) => {
    setCurEventPage(pageNum);
  };

  const getCurPlacePage = (pageNum) => {
    setCurPlacePage(pageNum);
  };

  const [eventList, setEventList] = useState([]);
  const [placeList, setPlaceList] = useState([]);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [totalPlaceCount, setTotalPlaceCount] = useState(0);

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  const getEventList = useCallback(
    async (loginId) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/list`;

      const res = await axios.get(url, {
        params: {
          page: curEventPage,
          count: count,
          userid: loginId,
          loginid: loginId,
        },
      });

      if (res.status === 200) {
        const { total_count, list } = res.data;

        setTotalEventCount(total_count);
        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].id,
            imgSrc: getImage(list[i].resources),
            category: list[i].event_type,
            title: list[i].name,
            placeName: list[i].location,
            like: list[i].is_like === 1 ? true : false,
            address: "",
            period: {
              start: list[i].open_date,
              end: list[i].close_date,
            },
            status: list[i].state,
          });
        }

        setEventList(ary);
      }
    },
    [curEventPage]
  );

  const getPlaceList = useCallback(async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list`;
    try {
      const response = await axios.get(url, {
        params: {
          page: curPlacePage,
          count: count,
          userid: window.sessionStorage.getItem("userid"),
        },
      });

      if (response.status === 200) {
        const { total_count, list } = response.data;

        setTotalPlaceCount(total_count);

        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].id,
            imgSrc: getImage(list[i].resources),
            category: list[i].space_type,
            title: list[i].name,
            address: list[i].address1 + " " + list[i].address2,
            status: list[i].state,
          });
        }

        setPlaceList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPlacePage]);

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    getPlaceList();
    getEventList(userid);

    return () => {
      document.body.removeChild(script);
    };
  }, [getEventList, getPlaceList, history]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />
      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />

            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-sm">
                    <h2 className="listing-detail__content-title">
                      등록 신청한 문화행사 ({totalEventCount}건){" "}
                    </h2>
                    <hr />
                  </div>

                  <Grid container columnSpacing={3} rowSpacing={3}>
                    {eventList.map((eventInfo) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        key={eventInfo.id}
                      >
                        <EventListItem
                          eventInfo={eventInfo}
                          key={eventInfo.id}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <div className="explore__box">
                    <div className="explore__wrap iso-call"></div>
                    {totalEventCount > 6 ? (
                      <>
                        <br />
                        <Paging
                          pageNum={curEventPage}
                          getCurPage={getCurEventPage}
                          onePage={6}
                          all={totalEventCount}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="section-title-sm">
                    <h2 className="listing-detail__content-title">
                      등록 신청한 문화공간 ({totalPlaceCount}건){" "}
                    </h2>
                    <hr />
                  </div>

                  <Grid container columnSpacing={3} rowSpacing={3}>
                    {placeList.map((placeInfo) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        key={placeInfo.id}
                      >
                        <PlaceListItem
                          placeInfo={placeInfo}
                          key={placeInfo.id}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <div className="explore__box">
                    <div className="explore__wrap iso-call"></div>
                    {totalPlaceCount > 6 ? (
                      <Paging
                        pageNum={curPlacePage}
                        getCurPage={getCurPlacePage}
                        onePage={6}
                        all={totalPlaceCount}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default RegistrationApplicationView;
