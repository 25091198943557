import SearchLocationMap from "components/map/SearchLocationMap";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import axios from "axios";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import EventList from "../event/event-template/EventList";
import PlaceList from "../place/place-template/PlaceList";
import SearchResultList from "./searchTemplate/SearchResultList";
import TapMenu from "./tapMenu/TapMenu";
import SearchLoactionBar from "./searchTemplate/SearchLocationBar";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const tapMenu = {
  all: [
    {
      text: "전체",
      name: "all",
    },
    {
      text: "공연",
      name: "show",
    },
    {
      text: "전시",
      name: "exhibition",
    },
    {
      text: "기타 행사",
      name: "other",
    },
    {
      text: "공연장",
      name: "hall",
    },
    {
      text: "연습실",
      name: "practice",
    },
    {
      text: "악기상점",
      name: "instrument",
    },
    {
      text: "미술관/갤러리",
      name: "gallery",
    },
  ],
  event: [
    {
      text: "전체",
      name: "all",
    },
    {
      text: "공연",
      name: "show",
    },
    {
      text: "전시",
      name: "exhibition",
    },
    {
      text: "기타 행사",
      name: "other",
    },
  ],
  place: [
    {
      text: "전체",
      name: "all",
    },
    {
      text: "공연장",
      name: "hall",
    },
    {
      text: "연습실",
      name: "practice",
    },
    {
      text: "악기상점",
      name: "instrument",
    },
    {
      text: "미술관/갤러리",
      name: "gallery",
    },
  ],
};

const Search = () => {
  const { state } = useContext(MobileContext);

  const [curPageEvent, setCurPageEvent] = useState(1);
  const [curPagePlace, setCurPagePlace] = useState(1);

  const getCurPageEvent = (pageNum) => {
    setCurPageEvent(pageNum);
  };
  const getCurPagePlace = (pageNum) => {
    setCurPagePlace(pageNum);
  };

  const [menu, setMenu] = useState({
    first: "all",
    second: "all",
  });

  const onSelectFirstMenu = (seleted) => {
    setMenu({
      first: seleted,
      second: "all",
    });
    setCurPageEvent(1);
    setCurPagePlace(1);
  };

  const onSelectSecondMenu = (seleted) => {
    setMenu({
      ...menu,
      second: seleted,
    });
    setCurPageEvent(1);
    setCurPagePlace(1);
  };

  // 기본 : 서초 문화 예술회관
  const [address, setAddress] = useState("서초문화예술회관");

  const [location, setLocation] = useState({
    lat: 37.48181,
    lng: 127.0335499,
  });
  const isActive = useRef();

  // 위도 경도로 주소
  const getAddressByLocation = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=ko&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;

    try {
      const response = await axios.get(url);

      console.log(response.data);

      if (response.status === 200) {
        const { results } = response.data;

        console.log(results);

        setAddress(results[3].formatted_address.replace("대한민국 ", ""));
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 사용자가 버튼 클릭시, 내 위치 활성화
  const onActiveLocation = () => {
    if (isActive.current.className === "contact-form__submit") {
      if (navigator.geolocation) {
        isActive.current.className = "contact-form__submit active";

        navigator.geolocation.getCurrentPosition((position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          getAddressByLocation(
            position.coords.latitude,
            position.coords.longitude
          );
        });
      }
    } else {
      isActive.current.className = "contact-form__submit";
      setLocation({
        lat: 37.48181,
        lng: 127.0335499,
      });

      setAddress("서초문화예술회관");
    }
  };

  const searchByLocation = (location) => {
    setLocation(location);
  };

  const [eventList, setEventList] = useState([]);
  const [placeList, setPlaceList] = useState([]);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [totalPlaceCount, setTotalPlaceCount] = useState(0);
  const [markerList, setMarkerList] = useState([]);

  const getImage = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  const searchEventByLocation = useCallback(
    async (event_type, loginId) => {
      const searchEventUrl = `${process.env.REACT_APP_CULTURE_SERVICE}/api/v2/admin/search/nearest/event`;

      try {
        const resEvent = await axios.get(searchEventUrl, {
          params: {
            page: curPageEvent,
            count: 8,
            loginId: loginId,
            eventType: event_type,
            latitude: location.lat,
            longitude: location.lng,
          },
        });

        let ary = [];
        if (resEvent.status === 200) {
          const { totalRows, data } = resEvent.data;

          setTotalEventCount(totalRows);

          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].id,
              imgSrc: getImage(data[i].images),
              category: data[i].eventType,
              title: data[i].name,
              placeName: data[i].location,
              like: data[i].isLike === 1 ? true : false,
              period: {
                start: data[i].openDate,
                end: data[i].closeDate,
              },
              position: {
                lat: parseFloat(data[i].latitude),
                lng: parseFloat(data[i].longitude),
              },
            });
          }
        }
        setEventList(ary);
        console.log(" ======= FAIL TO GET LIST ========");

        return ary;
      } catch (e) {
        console.log(" ======= FAIL TO GET EVENT LIST ========");
        console.log(e);
      }
    },
    [curPageEvent, location]
  );

  const searchPlaceByLocation = useCallback(
    async (space_type) => {
      const searchPlaceUrl = `${process.env.REACT_APP_CULTURE_SERVICE}/api/v2/admin/search/nearest/space`;

      try {
        const resPlace = await axios.get(searchPlaceUrl, {
          params: {
            page: curPagePlace,
            count: 8,
            spaceType: space_type,
            latitude: location.lat,
            longitude: location.lng,
          },
        });

        let ary = [];

        if (resPlace.status === 200) {
          const { totalRows, data } = resPlace.data;

          setTotalPlaceCount(totalRows);

          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].id,
              imgSrc: getImage(data[i].images),
              category: data[i].spaceType,
              title: data[i].name,
              address: data[i].address1 + " " + data[i].address2,
              position: {
                lat: parseFloat(data[i].latitude),
                lng: parseFloat(data[i].longitude),
              },
            });
          }
        }

        setPlaceList(ary);
        return ary;
      } catch (e) {
        console.log(" ======= FAIL TO GET LIST ========");
        console.log(e);
      }
    },
    [curPagePlace, location]
  );

  const getMarkerList = useCallback(
    async (loginId) => {
      let ary = [];

      if (menu.first === "all") {
        switch (menu.second) {
          case "all":
            ary = await searchEventByLocation("all", loginId);
            ary = ary.concat(await searchPlaceByLocation("all"));
            break;
          case "show":
            ary = await searchEventByLocation(menu.second, loginId);
            setPlaceList([]);
            setTotalPlaceCount(0);
            break;
          case "exhibition":
            ary = await searchEventByLocation(menu.second, loginId);
            setPlaceList([]);
            setTotalPlaceCount(0);
            break;
          case "other":
            ary = await searchEventByLocation(menu.second, loginId);
            setPlaceList([]);
            setTotalPlaceCount(0);
            break;
          case "hall":
            ary = await searchPlaceByLocation(menu.second);
            setEventList([]);
            setTotalEventCount(0);
            break;
          case "practice":
            ary = await searchPlaceByLocation(menu.second);
            setEventList([]);
            setTotalEventCount(0);
            break;
          case "instrument":
            ary = await searchPlaceByLocation(menu.second);
            setEventList([]);
            setTotalEventCount(0);
            break;
          case "gallery":
            ary = await searchPlaceByLocation(menu.second);
            setEventList([]);
            setTotalEventCount(0);
            break;
          default:
            ary = [];
            break;
        }
      } else if (menu.first === "event") {
        ary = await searchEventByLocation(menu.second, loginId);
        setPlaceList([]);
        setTotalPlaceCount(0);
      } else {
        ary = await searchPlaceByLocation(menu.second);
        setEventList([]);
        setTotalEventCount(0);
      }

      setMarkerList(ary);
    },
    [searchEventByLocation, searchPlaceByLocation, menu]
  );

  const [googleMaps, setGoogleMaps] = useState(null);

  const onLoadedApi = (maps) => {
    setGoogleMaps(maps);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    getMarkerList(loginId);

    return () => {
      document.body.removeChild(script);
    };
  }, [getMarkerList]);

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section className="page-title">
        <div className="container">
          {googleMaps && (
            <SearchLoactionBar searchByLocation={searchByLocation} />
          )}

          {/* <input
            className="contact-form__submit"
            type="button"
            ref={isActive}
            value={`현재 내 위치 : ${address}`}
            onClick={() => onActiveLocation()}
            style={{
              cursor: "pointer",
            }}
          /> */}

          <ul className="single-post__list">
            <li className="single-post__list-item">
              <i className=""></i>
              <span></span>
            </li>
            <li className="single-post__list-item">
              <i className=""></i>
              <a></a>
            </li>
          </ul>
        </div>
      </section>

      <section className="explore">
        <div className="container">
          <div id="sc-tab-menu">
            <div id="tab-tit" className="center-button">
              <div id="tab-btn">
                <ul>
                  <li className={menu.first === "all" ? "active" : ""}>
                    <a onClick={() => onSelectFirstMenu("all")}>전체</a>
                  </li>
                  <li className={menu.first === "event" ? "active" : ""}>
                    <a onClick={() => onSelectFirstMenu("event")}>문화행사</a>
                  </li>
                  <li className={menu.first === "place" ? "active" : ""}>
                    <a onClick={() => onSelectFirstMenu("place")}>문화공간</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <TapMenu
            menuList={
              {
                all: tapMenu.all,
                event: tapMenu.event,
                place: tapMenu.place,
              }[menu.first]
            }
            secondMenu={menu.second}
            onSelectSecondMenu={onSelectSecondMenu}
          />

          <SearchLocationMap
            center={location}
            markerList={markerList}
            onLoadedApi={onLoadedApi}
          />

          <br />
          <br />

          {
            {
              all: (
                <>
                  <SearchResultList
                    listTitle="주변 문화행사"
                    resultList={eventList}
                    ListComponent={EventList}
                    curPage={curPageEvent}
                    getCurPage={getCurPageEvent}
                    onePage={8}
                    totalCount={totalEventCount}
                  />
                  <SearchResultList
                    listTitle="주변 문화공간"
                    resultList={placeList}
                    ListComponent={PlaceList}
                    curPage={curPagePlace}
                    getCurPage={getCurPagePlace}
                    onePage={8}
                    totalCount={totalPlaceCount}
                  />
                </>
              ),
              event: (
                <SearchResultList
                  listTitle="주변 문화행사"
                  resultList={eventList}
                  ListComponent={EventList}
                  curPage={curPageEvent}
                  getCurPage={getCurPageEvent}
                  onePage={8}
                  totalCount={totalEventCount}
                />
              ),
              place: (
                <SearchResultList
                  listTitle="주변 문화공간"
                  resultList={placeList}
                  ListComponent={PlaceList}
                  curPage={curPagePlace}
                  getCurPage={getCurPagePlace}
                  onePage={8}
                  totalCount={totalPlaceCount}
                />
              ),
            }[menu.first]
          }
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default Search;
