import React, { useState, useEffect } from "react";
import axios from "axios";

import ListTemplateTitle from "./list-template/ListTemplateTitle";
import EventList from "./list-template/EventList";
import MoreList from "./list-template/MoreList";

const NewEvent = ({ title }) => {
  const [list, setList] = useState([]);
  const [totalNum, setTotalNum] = useState();

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const getNewEventList = async (loginId) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/list/`;

      const res = await axios.get(url, {
        params: {
          search_type: "STATE",
          search_word: "POST",
          page: 1,
          count: 6,
          sort_column: "create_date",
          sort_type: "desc",
          loginid: loginId,
        },
      });

      if (res.status === 200) {
        let ary = [];
        for (let i = 0; i < res.data.list.length; i++) {
          ary.push({
            id: res.data.list[i].id,
            imgSrc: getImage(res.data.list[i].resources),
            category: res.data.list[i].event_type,
            title: res.data.list[i].name,
            placeName: res.data.list[i].location,
            like: res.data.list[i].is_like === 1 ? true : false,
            address: res.data.list[i].address1 + res.data.list[i].address2,
            period: {
              start: res.data.list[i].open_date,
              end: res.data.list[i].close_date,
            },
          });
        }

        setList(ary);
        setTotalNum(res.data.total_count);
      }
    };
    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");
    getNewEventList(loginId);
  }, []);

  return (
    <section className="section-culture-event">
      <div className="container">
        <ListTemplateTitle title={title} />
        <EventList eventList={list} />
        <MoreList moreNum={totalNum} link="event/event-all" />
      </div>
    </section>
  );
};

export default NewEvent;
