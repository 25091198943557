import React, { useEffect, useContext } from "react";
import MobileContext from "context/mobile";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Footer from "../ect/Footer";
import BottomNavigation from "components/ect/BottomNavigation";

const SeoripulArtCenter = () => {
  const { state } = useContext(MobileContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <div className="container">
        <div
          style={{
            fontSize: "10pt",
            fontFamily: "NanumGothic, 나눔고딕, sans-serif",
          }}
        >
          <div
            style={{
              fontSize: "10pt",
              fontFamily: "NanumGothic, 나눔고딕, sans-serif",
            }}
          >
            <div
              style={{
                fontSize: "10pt",
                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
              }}
            >
              <xxxxxxxxxxxlink
                rel="stylesheet"
                type="text/css"
                href="/css/style.css"
              >
                <div
                  style={{
                    margin: "0px auto 4rem",
                    padding: "0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    lineHeight: "25.6px",
                    boxSizing: "border-box",
                    maxWidth: "80rem",
                    position: "relative",
                    orphans: "2",
                    textAlign: "start",
                    textIndent: "0px",
                    widows: "2",
                    textDecorationThickness: "initial",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <div
                    style={{
                      margin: "0px auto",
                      padding: "0px",
                      border: "0px",
                      verticalAlign: "baseline",
                      lineHeight: "25.6px",
                      boxSizing: "border-box",
                      maxWidth: "80rem",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px auto",
                        padding: "0px",
                        border: "0px",
                        verticalAlign: "baseline",
                        lineHeight: "25.6px",
                        boxSizing: "border-box",
                        maxWidth: "68.75rem",
                      }}
                    >
                      <div
                        style={{
                          margin: "0px",
                          padding: "0px",
                          border: "0px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            color: "rgb(84, 84, 84)",
                            fontFamily: "Nanum Gothic, sans-serif",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: "400",
                            letterSpacing: "normal",
                            textTransform: "none",
                            whiteSpace: "normal",
                            wordSpacing: "0px",
                            "-webkit-text-stroke-width": "0px",
                            margin: "0px auto",
                            padding: "0px",
                            border: "0px",
                            verticalAlign: "baseline",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            maxWidth: "80rem",
                            position: "relative",
                          }}
                        >
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                margin: "0px",
                                padding: "0px",
                                border: "0px",
                                fontSize: "2.65rem",
                                verticalAlign: "baseline",
                                lineHeight: "67.84px",
                                boxSizing: "border-box",
                                fontWeight: "600",
                                letterSpacing: "-0.125rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              <img
                                src="/images/sc_imga002.png"
                                loading="lazy"
                              />
                              &nbsp;
                            </span>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                margin: "0px",
                                padding: "0px",
                                border: "0px",
                                fontSize: "2.65rem",
                                verticalAlign: "baseline",
                                lineHeight: "67.84px",
                                boxSizing: "border-box",
                                fontWeight: "600",
                                letterSpacing: "-0.125rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              예술가
                            </span>
                            <span
                              style={{
                                fontSize: "1.745rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              와&nbsp;
                            </span>
                            <span
                              style={{
                                margin: "0px",
                                padding: "0px",
                                border: "0px",
                                fontSize: "2.65rem",
                                verticalAlign: "baseline",
                                lineHeight: "67.84px",
                                boxSizing: "border-box",
                                fontWeight: "600",
                                letterSpacing: "-0.125rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              공간
                            </span>
                            <span
                              style={{
                                fontSize: "1.745rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              ,&nbsp;
                            </span>
                            <span
                              style={{
                                margin: "0px",
                                padding: "0px",
                                border: "0px",
                                fontSize: "2.65rem",
                                verticalAlign: "baseline",
                                lineHeight: "67.84px",
                                boxSizing: "border-box",
                                fontWeight: "600",
                                letterSpacing: "-0.125rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              지역
                            </span>
                            <span
                              style={{
                                fontSize: "1.745rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              이 만나
                            </span>
                            &nbsp;
                          </div>
                          <div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontSize: "1.745rem",
                                  color: "rgb(60, 63, 69)",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                예술이 끊임없이
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(60, 63, 69)",
                                  fontSize: "2.65rem",
                                  fontWeight: "600",
                                  letterSpacing: "-0.125rem",
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "67.84px",
                                  boxSizing: "border-box",
                                }}
                              >
                                창조
                              </span>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  color: "rgb(60, 63, 69)",
                                  fontSize: "1.745rem",
                                }}
                              >
                                되고&nbsp;
                              </span>
                              <span
                                style={{
                                  color: "rgb(60, 63, 69)",
                                  fontSize: "2.65rem",
                                  fontWeight: "600",
                                  letterSpacing: "-0.125rem",
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "67.84px",
                                  boxSizing: "border-box",
                                }}
                              >
                                해석
                              </span>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  color: "rgb(60, 63, 69)",
                                  fontSize: "1.745rem",
                                }}
                              >
                                되는
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontSize: "2.295rem",
                                  letterSpacing: "-0.125rem",
                                  fontWeight: "600",
                                  color: "rgb(60, 63, 69)",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                서초음악문화지구
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                서초음악문화지구 활성화
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                를 위해 조성된 서리풀 청년아트센터는
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                서초음악문화지구 내&nbsp;
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  문화예
                                </span>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  술프로그램
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                마련과 더불어,
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                서초구 청년음악인들을 위한 공간대관, 지원사업
                                등&nbsp;
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  다양한 문화예술 활동
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                을 통해
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                }}
                              >
                                클래식 음악이 흐르는 서초음악문화지구 조성에
                                힘쓰고 있습니다.
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                서리풀 청년아트센터를 통해&nbsp;
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                }}
                              >
                                <b
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    border: "0px",
                                    fontSize: "22.8px",
                                    verticalAlign: "baseline",
                                    lineHeight: "36.48px",
                                    boxSizing: "border-box",
                                    color: "rgb(221, 100, 132)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily:
                                        "NanumGothic, 나눔고딕, sans-serif",
                                    }}
                                  >
                                    예술가
                                  </span>
                                </b>
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  와&nbsp;
                                </span>
                                <b
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    border: "0px",
                                    fontSize: "22.8px",
                                    verticalAlign: "baseline",
                                    lineHeight: "36.48px",
                                    boxSizing: "border-box",
                                    color: "rgb(162, 145, 190)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily:
                                        "NanumGothic, 나눔고딕, sans-serif",
                                    }}
                                  >
                                    공간
                                  </span>
                                </b>
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  ,&nbsp;
                                </span>
                                <b
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    border: "0px",
                                    fontSize: "22.8px",
                                    verticalAlign: "baseline",
                                    lineHeight: "36.48px",
                                    boxSizing: "border-box",
                                    color: "rgb(162, 145, 190)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily:
                                        "NanumGothic, 나눔고딕, sans-serif",
                                    }}
                                  >
                                    지역
                                  </span>
                                </b>
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  이 만나
                                </span>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "22.8px",
                                  letterSpacing: "-0.0625rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "36.48px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  예술이 끊임없이&nbsp;
                                </span>
                                <b
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    border: "0px",
                                    fontSize: "22.8px",
                                    verticalAlign: "baseline",
                                    lineHeight: "36.48px",
                                    boxSizing: "border-box",
                                    color: "rgb(0, 0, 0)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily:
                                        "NanumGothic, 나눔고딕, sans-serif",
                                    }}
                                  >
                                    창조
                                  </span>
                                </b>
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  되고&nbsp;
                                </span>
                                <b
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    border: "0px",
                                    fontSize: "22.8px",
                                    verticalAlign: "baseline",
                                    lineHeight: "36.48px",
                                    boxSizing: "border-box",
                                    color: "rgb(0, 0, 0)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily:
                                        "NanumGothic, 나눔고딕, sans-serif",
                                    }}
                                  >
                                    해석
                                  </span>
                                </b>
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  되는 서초음악문화지구
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                이기를 희망합니다.
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                서리풀 청년아트센터 주요사업
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "1.5rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <img src="/images/sc_imga001.png" />
                              &nbsp;
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              &nbsp;
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontSize: "1.25rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontSize: "1.25rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                서리풀 청년아트센터는&nbsp;
                              </span>
                              <b
                                style={{
                                  fontSize: "1.25rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "32px",
                                  boxSizing: "border-box",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  서초구청
                                </span>
                              </b>
                              <span
                                style={{
                                  fontSize: "1.25rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                이 조성하고&nbsp;
                              </span>
                              <b
                                style={{
                                  fontSize: "1.25rem",
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  lineHeight: "32px",
                                  boxSizing: "border-box",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      "NanumGothic, 나눔고딕, sans-serif",
                                  }}
                                >
                                  서초문화재단
                                </span>
                              </b>
                              <span
                                style={{
                                  fontSize: "1.25rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                이 운영합니다.
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                운영 시간
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                화 ~ 토&nbsp;10:00~19:00 (일,월요일, 국가지정
                                공휴일 휴관) | 점심시간 12:30~13:30
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "16.2px",
                                }}
                              >
                                ※상기 일정은 공간 운영에 따라 변경될 수
                                있습니다.
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                주 소
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                (우)06652 서울특별시 서초구 반포대로58
                                서초아트자이 104동 상가 3층
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                전화/팩스
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                02-3474-2911~2 / 02-3474-0409
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                이 메 일
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily:
                                    '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                  fontSize: "18px",
                                }}
                              >
                                seoripulartcenter@naver.com
                              </span>
                            </div>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontFamily:
                                  '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                fontSize: "18px",
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontFamily:
                                  '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                fontSize: "18px",
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <a
                              className="tab-form__submit"
                              onClick={() =>
                                window.open(
                                  "https://www.seochocf.or.kr/site/main/content/culture_space_art_inform",
                                  "_blank"
                                )
                              }
                            >
                              사이트로 이동
                            </a>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontFamily:
                                  '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                fontSize: "18px",
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontFamily:
                                  '"normal","NanumGothic, 나눔고딕, sans-serif"',
                                fontSize: "18px",
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                          <div align="center" style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                                fontSize: "18px",
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </xxxxxxxxxxxlink>
            </div>
          </div>
        </div>
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default SeoripulArtCenter;
