import React from "react";

const UserReivewListItem = ({ reviewInfo, deleteReview, isMyReview }) => {
  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };
  const onConfirmDelete = () => {
    deleteReview(reviewInfo.id);
    alert("삭제되었습니다");
  };
  const onHandleDelete = useConfirm(
    "리뷰를 삭제하시겠습니까?",
    onConfirmDelete
  );

  const replaceString = (string) => {
    return string.replace(/-/gi, ".");
  };

  const drawStart = () => {
    let starAry = [];
    for (let i = 0; i < 5; i++) {
      if (i < reviewInfo.stars) {
        starAry.push(<i className="la la-star la-star-active"></i>);
      } else {
        starAry.push(<i className="la la-star"></i>);
      }
    }
    return starAry;
  };

  return (
    <li className="reviews-list__item">
      <div className="reviews-list__item-box">
        <div className="reviews-list__item-content">
          <h3 className="reviews-list__item-title">{reviewInfo.user} </h3>
          <span className="reviews-list__items-date">
            {replaceString(reviewInfo.date)}
          </span>
          <p className="reviews-list__item-stars">
            {drawStart()}
            <span className="rate-actual">{reviewInfo.stars}</span> / 5
          </p>
          <p className="reviews-list__item-description">{reviewInfo.content}</p>
          {isMyReview ? (
            <a
              className="reviews-list__item-reply"
              onClick={onHandleDelete}
              style={{ color: "#999999" }}
            >
              <i className="la la-mail-forward"></i>
              삭제{" "}
            </a>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default UserReivewListItem;
