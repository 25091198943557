import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const replaceString = (string) => {
  return string.slice(0, 4) + "." + string.slice(4, 6) + "." + string.slice(6);
};

const EventListItem = ({ eventInfo, isFestival }) => {
  const [isFavorite, setIsFavorite] = useState();

  const getLink = () => {
    switch (eventInfo.status) {
      case "REJECT":
        return `/my-page/event-detail-dismissal/${eventInfo.id}`;

      case "POST":
        return `/event/event-detail/${eventInfo.id}`;

      case "WAIT":
        return `/my-page/event-detail-waiting/${eventInfo.id}`;

      default:
        return `/event/event-detail/${eventInfo.id}`;
    }
  };

  const getCategoryLabel = () => {
    switch (eventInfo.category) {
      case "SHOW":
        return <span className="event-post__rating">공연</span>;
      case "공연":
        return <span className="event-post__rating">공연</span>;
      case "EXHIBITION":
        return <span className="event-post__rating solid-rat">전시</span>;
      case "전시":
        return <span className="event-post__rating solid-rat">전시</span>;
      case "OTHER":
        return <span className="event-post__rating solid-rat-other">기타</span>;
      case "기타":
        return <span className="event-post__rating solid-rat-other">기타</span>;
      default:
        return "";
    }
  };

  const getStatusLabel = () => {
    switch (eventInfo.status) {
      case "REJECT":
        return <span class="tag__dismissal">기각 </span>;

      case "POST":
        return <span class="tag__passed">통과 </span>;

      case "WAIT":
        return <span class="tag__waiting">대기 중 </span>;

      default:
        return "";
    }
  };

  const onClickUnLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}/${eventInfo.id}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}`;

    let data = new Object();
    data.eventIds = [eventInfo.id];

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  useEffect(() => {
    setIsFavorite(eventInfo.like);
  }, [eventInfo.like]);

  return (
    <div className="event-post">
      <div className="event-post__gal-box event-gal">
        <Link
          to={isFestival ? `/event/sc-event-detail/${eventInfo.id}` : getLink()}
        >
          <img
            className="event-gal-image"
            src={getImgSrc(eventInfo.imgSrc)}
            alt="문화행사 포스터"
          />
        </Link>
        {getCategoryLabel()}

        {isFestival ? (
          ""
        ) : (
          <button
            className={
              isFavorite ? "event-post__like active" : "event-post__like"
            }
            type="button"
            onClick={isFavorite ? onClickUnLikedBtn : onClickLikedBtn}
          >
            <i
              className={isFavorite ? "fa fa-heart" : "fa fa-heart-o"}
              aria-hidden="true"
            ></i>
          </button>
        )}
      </div>
      <div className="event-post__content">
        <p className="post__info-time">
          <i className="fa fa-clock-o" aria-hidden="true"></i>
          {eventInfo.period.start.includes(".")
            ? eventInfo.period.start
            : replaceString(eventInfo.period.start)}
          ~
          {eventInfo.period.end.includes(".")
            ? eventInfo.period.end
            : replaceString(eventInfo.period.end)}
        </p>
        <h2 className="event-post__title">
          <Link
            to={
              isFestival ? `/event/sc-event-detail/${eventInfo.id}` : getLink()
            }
          >
            {eventInfo.title}
          </Link>
        </h2>

        <p className="event-post__address">
          {eventInfo.placeName === "" ? (
            ""
          ) : (
            <>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              {eventInfo.placeName}
            </>
          )}
        </p>

        {getStatusLabel()}
      </div>
    </div>
  );
};

export default EventListItem;
