import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import PostCode from "components/ect/PostCode";
import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import MyPageMenu from "./MyPageMenu";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const addDash = (string) => {
  return string.slice(0, 4) + "-" + string.slice(4, 6) + "-" + string.slice(6);
};

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);

  let str = "" + date.getFullYear();

  if (date.getMonth() < 9) {
    str += "0" + (date.getMonth() + 1);
  } else {
    str += date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    str += "0" + date.getDate();
  } else {
    str += date.getDate();
  }
  return str;
};

const UserInfoEditView = () => {
  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };

  const { state } = useContext(MobileContext);

  const history = useHistory();

  const [formInfo, setFormInfo] = useState({
    id: "",
    name: "",
    tel: "",
    email: "",
    birth: "",
    address: "",
    detailAddress: "",
    pwd: "",
  });

  const pwdInput = useRef();
  const secondPwdInput = useRef();
  const telInput = useRef();
  const emailInput = useRef();

  const [regList, setRegList] = useState({
    pwd: true,
    secondPwd: true,
    tel: true,
    email: true,
  });

  const [secondPwd, setSecondPwd] = useState("");

  const [showPopup, setShowPopup] = useState(false);

  const [changePwd, setChangePwd] = useState(false);

  const togglePwdSection = () => {
    if (changePwd) {
      setRegList({
        ...regList,
        pwd: true,
        secondPwd: true,
      });
    }

    setChangePwd(!changePwd);
  };

  const regPwdTest = (pwd) => {
    const regPwdNumCha =
      /^(?=.*[a-zA-Z])(?=.*[#@$!%*?&^-])(?=.*\d)[a-zA-Z\d#@$!%*?&^-]{10,}$/;
    const regPwdNum = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;
    const regPwdCha =
      /^(?=.*[a-zA-Z])(?=.*[#@$!%*?&^-])[a-zA-Z#@$!%*?&^-]{10,}$/;

    if (regPwdNumCha.test(pwd) || regPwdNum.test(pwd) || regPwdCha.test(pwd)) {
      if (!regList.pwd) {
        setRegList({
          ...regList,
          pwd: true,
        });
      }
      pwdInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.pwd)
        setRegList({
          ...regList,
          pwd: false,
        });

      pwdInput.current.focus();
      pwdInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };
  const onChangePwd = (e) => {
    regPwdTest(e.target.value);
    setFormInfo({
      ...formInfo,
      pwd: e.target.value,
    });
  };

  const regSecondPwdTest = (pwd) => {
    if (pwd !== formInfo.pwd) {
      if (regList.secondPwd) {
        setRegList({
          ...regList,
          secondPwd: false,
        });
      }
      secondPwdInput.current.focus();
      secondPwdInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    } else {
      if (!regList.secondPwd) {
        setRegList({
          ...regList,
          secondPwd: true,
        });
      }
      secondPwdInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    }
  };
  const onChangeSecondPwd = (e) => {
    regSecondPwdTest(e.target.value);
    setSecondPwd(e.target.value);
  };

  const regTelTest = (tel) => {
    const regPhone = /^[0-9]{2,3}([0-9]{3,4})([0-9]{4})$/;

    if (regPhone.test(tel)) {
      if (!regList.tel)
        setRegList({
          ...regList,
          tel: true,
        });

      telInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.tel)
        setRegList({
          ...regList,
          tel: false,
        });

      telInput.current.focus();
      telInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };
  const onChangeTel = (e) => {
    regTelTest(e.target.value);
    setFormInfo({
      ...formInfo,
      tel: e.target.value,
    });
  };

  const regEmailTest = (email) => {
    const regEmail = /^[a-zA-Z0-9\.\-_]+@([a-zA-Z0-9\-]+\.)+[a-z]{2,6}$/;

    if (!regEmail.test(email)) {
      if (regList.email)
        setRegList({
          ...regList,
          email: false,
        });

      emailInput.current.focus();
      emailInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    } else {
      if (!regList.email)
        setRegList({
          ...regList,
          email: true,
        });

      emailInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    }
  };
  const onChangeEmail = (e) => {
    regEmailTest(e.target.value);
    setFormInfo({
      ...formInfo,
      email: e.target.value,
    });
  };
  const onChangeBirth = (e) => {
    setFormInfo({
      ...formInfo,
      birth: e.target.value,
    });
  };
  const resetBirth = () => {
    setFormInfo({
      ...formInfo,
      birth: "",
    });
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const onChangeAddress = (result) => {
    setFormInfo({
      ...formInfo,
      address: result,
    });

    togglePopup();
  };

  const onChangeDetailAddress = (e) => {
    setFormInfo({
      ...formInfo,
      detailAddress: e.target.value,
    });
  };

  const resetAddress = () => {
    setFormInfo({
      ...formInfo,
      address: "",
    });
  };

  const cancelComfirm = () => {
    history.push("/my-page/user-info");
  };
  const toCancleEdit = useConfirm(
    "내용이 저장되지 않았습니다. 취소하시겠습니까?",
    cancelComfirm
  );

  const onSubmit = () => {
    if (changePwd) {
      if (!regList.pwd || formInfo.pwd === "") {
        alert("양식에 맞는 비밀번호를 입력해주세요.");
        return;
      } else if (
        !regList.secondPwd ||
        secondPwd === "" ||
        formInfo.pwd !== secondPwd
      ) {
        alert("비밀번호가 일치하지 않습니다.");
        return;
      }
    }

    if (!regList.tel || formInfo.tel === "") {
      alert("잘못된 전화번호 입니다. 확인 후 다시 입력해주세요");
      return;
    } else if (!regList.email || formInfo.email === "") {
      alert("잘못된 형식의 이메일 입니다. 확인 후 다시 입력해주세요.");
      return;
    }

    // "address1": "서울",
    // "address2": "삼성동",
    // "birthday": "20110909",\

    let data = new Object();

    if (changePwd) {
      data.password = formInfo.pwd;
    }

    data.phone = formInfo.tel;
    data.email = formInfo.email;
    data.name = formInfo.name;
    data.address1 = formInfo.address;
    data.address2 = formInfo.detailAddress;
    data.birthday =
      formInfo.birth !== ""
        ? convertDateFormat(formInfo.birth)
        : formInfo.birth;

    editUserInfo(data);
  };

  const editUserInfo = async (data) => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/user/detail/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.put(url, data);

      if (res.status === 201) {
        alert("회원정보 수정이 완료되었습니다.");
        history.push("/my-page/user-info");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const getUserInfo = async () => {
      const url = `${
        process.env.REACT_APP_USER_SERVICE
      }/api/admin/user/detail/${window.sessionStorage.getItem("userid")}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setFormInfo({
            id: res.data.id,
            name: res.data.name,
            tel: res.data.phone,
            email: res.data.email,
            birth: res.data.birthday !== "" ? addDash(res.data.birthday) : "",
            address: res.data.address1,
            detailAddress: res.data.address2,
            pwd: "",
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getUserInfo();
  }, [history]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />

            <div className="col-lg-10">
              <div className="section-title-sm">
                <h2 className="listing-detail__content-title">
                  나의 정보 수정하기{" "}
                </h2>
                <hr />
              </div>

              <div
                className="add-listing__form-box element-waypoint"
                id="general-info"
              >
                <div className="add-listing__form-content">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-id">
                        아이디{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <label className="add-listing__label" htmlFor="list-id">
                        {formInfo.id}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-name">
                        이름{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <label className="add-listing__label" htmlFor="list-name">
                        {formInfo.name}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-password"
                      >
                        {" "}
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="checkbox"
                        name="list-title"
                        id="list-title"
                        checked={changePwd}
                        onChange={() => {
                          togglePwdSection();
                        }}
                      />
                      <label
                        htmlFor="list-password"
                        style={{
                          color: "#363636",
                          fontWeight: "400",
                          fontSize: "0.9375rem",
                          marginLeft: "10px",
                        }}
                      >
                        비밀번호 변경하기
                      </label>
                    </div>
                  </div>
                  {changePwd ? (
                    <>
                      <div className="row">
                        <div className="col-md-2">
                          <label
                            className="add-listing__label"
                            htmlFor="list-password"
                          >
                            비밀번호
                          </label>
                        </div>

                        <div className="col-md-10">
                          <input
                            className="add-listing__input"
                            type="password"
                            name="list-title"
                            id="list-title"
                            placeholder="영문 대소문자 포함, 숫자 또는 특수문자 중 2가지 이상 조합, 10자 이상"
                            onChange={(e) => onChangePwd(e)}
                            ref={pwdInput}
                            value={formInfo.pwd}
                          />
                          {regList.pwd === false ? (
                            <p
                              style={{
                                marginTop: "-20px",
                                color: "#ef5797",
                              }}
                            >
                              비밀번호 형식을 확인해주세요
                              <br />
                              10자 이상, 영문 대소문자 포함, 숫자 또는
                              특수문자(#@$!%*?&amp;-^) 조합
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2">
                          <label
                            className="add-listing__label"
                            htmlFor="list-password"
                          >
                            비밀번호 확인{" "}
                          </label>
                        </div>
                        <div className="col-md-10">
                          <input
                            className="add-listing__input"
                            type="password"
                            name="list-title"
                            id="list-title"
                            placeholder="비밀번호 확인을 위해 다시 한번 입력하세요."
                            onChange={(e) => {
                              onChangeSecondPwd(e);
                            }}
                            value={secondPwd}
                            ref={secondPwdInput}
                          />
                          {regList.secondPwd === false ? (
                            <p
                              style={{
                                marginTop: "-20px",
                                color: "#ef5797",
                              }}
                            >
                              입력하신 비밀번호가 일치하지 않습니다
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-tel">
                        전화번호{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="text"
                        name="list-title"
                        id="list-tel"
                        value={formInfo.tel}
                        placeholder="전화번호를 입력해주세요"
                        onChange={(e) => onChangeTel(e)}
                        ref={telInput}
                      />
                      {regList.tel === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 전화번호가 올바르지 않습니다
                          <br />
                          -을 제외한 숫자만 입력해주세요
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-email"
                      >
                        이메일{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="add-listing__input"
                        type="email"
                        name="list-title"
                        id="list-email"
                        value={formInfo.email}
                        placeholder="사용하실 이메일을 입력해주세요"
                        onChange={(e) => onChangeEmail(e)}
                        ref={emailInput}
                      />
                      {regList.email === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 이메일이 올바르지 않습니다
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="add-listing__form-box element-waypoint"
                id="general-info"
              >
                <div className="add-listing__form-content">
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-birth"
                      >
                        생년월일{" "}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        className="add-listing__input"
                        type="date"
                        name="date"
                        id="list-title"
                        value={formInfo.birth}
                        onChange={(e) => onChangeBirth(e)}
                      />
                    </div>
                    <div className="btn-brard-reply">
                      <a className="btn-sc__item-reply" onClick={resetBirth}>
                        <i className="la la-mail-forward"></i>
                        초기화{" "}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-adress"
                      >
                        주소{" "}
                      </label>
                    </div>
                    <div className="col-md-5">
                      <input
                        className="add-listing__input"
                        type="address"
                        name="list-adress"
                        id="list-title"
                        placeholder="검색버튼 눌러서 주소찾기"
                        value={formInfo.address}
                        disabled={true}
                      />
                    </div>
                    <button
                      className="button-sc__submit btn_float-right"
                      type="button"
                      onClick={togglePopup}
                    >
                      검색{" "}
                    </button>
                    {showPopup ? (
                      <PostCode
                        getAddress={onChangeAddress}
                        closePopup={togglePopup}
                      />
                    ) : null}

                    <div className="btn-brard-reply">
                      <a className="btn-sc__item-reply" onClick={resetAddress}>
                        <i className="la la-mail-forward"></i>
                        초기화{" "}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-title"
                      >
                        상세주소{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        className="add-listing__input"
                        type="address"
                        name="list-title"
                        id="list-title"
                        placeholder="상세주소를 입력해주세요"
                        value={formInfo.detailAddress}
                        onChange={(e) => onChangeDetailAddress(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="center-button">
                <input
                  type="submit"
                  name="btn_js_alert"
                  id="btn_js_alert"
                  className="btn-round-lg__border-gray"
                  onClick={toCancleEdit}
                  value="취소"
                />
                <input
                  type="submit"
                  name="btn_js_alert"
                  id="btn_js_confirm"
                  className="btn-round-lg__pink"
                  onClick={onSubmit}
                  value="저장"
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default UserInfoEditView;
