import React from "react";

const AddFilter = ({
  showFields,
  exhibitionFields,
  otherFields,
  themeSeasons,
  themeTimes,
  themeFeelings,
  themeParties,
  scEvents,
  getFilterInfo,
  resetFilters,
  category,
}) => {
  return (
    <div className="row">
      <div className="explore__filter col-lg-12">
        <span className="title">장르</span>

        {
          {
            default: "",
            SHOW: (
              <ul className="explore__form-checkbox-list">
                <li>
                  <span className="explore__list_stitle sub-title event-post__ratings">
                    공연
                  </span>
                </li>
                {showFields.map((field) => {
                  return (
                    <li key={field.id}>
                      <input
                        className="explore__input-checkbox"
                        type="checkbox"
                        name="open-check"
                        id="open-check"
                        checked={field.isActive}
                        onChange={() => {
                          getFilterInfo("show", field.id);
                        }}
                      />
                      <span className="explore__checkbox-style"></span>
                      <span className="explore__checkbox-text">
                        {field.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            ),
            EXHIBITION: (
              <ul className="explore__form-checkbox-list">
                <li>
                  <span className="explore__list_stitle sub-title event-post__ratings solid-rats">
                    전시
                  </span>
                </li>
                {exhibitionFields.map((field) => {
                  return (
                    <li key={field.id}>
                      <input
                        className="explore__input-checkbox"
                        type="checkbox"
                        name="open-check"
                        id="open-check"
                        checked={field.isActive}
                        onChange={() => {
                          getFilterInfo("exhibition", field.id);
                        }}
                      />
                      <span className="explore__checkbox-style"></span>
                      <span className="explore__checkbox-text">
                        {field.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            ),
            OTHER: (
              <ul className="explore__form-checkbox-list">
                <li>
                  <span className="explore__list_stitle sub-title event-post__ratings solid-rats-others">
                    기타
                  </span>
                </li>
                {otherFields.map((field) => {
                  return (
                    <li key={field.id}>
                      <input
                        className="explore__input-checkbox"
                        type="checkbox"
                        name="open-check"
                        id="open-check"
                        checked={field.isActive}
                        onChange={() => {
                          getFilterInfo("other", field.id);
                        }}
                      />
                      <span className="explore__checkbox-style"></span>
                      <span className="explore__checkbox-text">
                        {field.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            ),
          }[category]
        }

        <span className="title">테마</span>
        <ul className="explore__form-checkbox-list">
          <li>
            <span className="explore__list_stitle sub-title">계절</span>
          </li>
          {themeSeasons.map((season) => {
            return (
              <li key={season.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={season.isActive}
                  onChange={() => getFilterInfo("themeSeasons", season.id)}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{season.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title">시기</span>
          </li>
          {themeTimes.map((time) => {
            return (
              <li key={time.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={time.isActive}
                  onChange={() => {
                    getFilterInfo("themeTimes", time.id);
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{time.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title">느낌</span>
          </li>
          {themeFeelings.map((feeling) => {
            return (
              <li key={feeling.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={feeling.isActive}
                  onChange={() => {
                    getFilterInfo("themeFeelings", feeling.id);
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{feeling.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title">일행</span>
          </li>
          {themeParties.map((party) => {
            return (
              <li key={party.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={party.isActive}
                  onChange={() => {
                    getFilterInfo("themeParties", party.id);
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{party.name}</span>
              </li>
            );
          })}
        </ul>

        <span className="title">서초 축제</span>
        <ul className="explore__form-checkbox-list">
          {/* <li>
            <span className="explore__list_stitle sub-title">계절</span>
          </li> */}
          {scEvents.map((scEvent) => {
            return (
              <li key={scEvent.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={scEvent.isActive}
                  onChange={() => getFilterInfo("scEvents", scEvent.id)}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{scEvent.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <br />
      <br />
      <div className="col-lg-12">
        <div className="col-lg-8"></div>
        <div className="col-lg-4 float-right">
          <a className="btn-item__thin" onClick={() => resetFilters()}>
            <i className="la la-mail-forward"></i>
            초기화{" "}
          </a>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default AddFilter;
