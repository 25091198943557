import React, { useState, memo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  InfoBox,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";

const SearchLocationMap = memo(({ center, markerList, onLoadedApi }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
    language: "ko",
  });

  const getIcon = (category) => {
    switch (category) {
      case "SHOW":
        return <i className="la la-music" aria-hidden="true"></i>;
      case "EXHIBITION":
        return <i className="fa fa-paint-brush" aria-hidden="true"></i>;
      case "OTHER":
        return <i className="fa fa-ticket" aria-hidden="true"></i>;
      case "HALL":
        return <i className="la la-university" aria-hidden="true"></i>;
      case "INSTRUMENT":
        return <i className="la la-music" aria-hidden="true"></i>;
      case "GALLERY":
        return <i className="la la-photo" aria-hidden="true"></i>;
      default:
        return <i className="la la-university" aria-hidden="true"></i>;
    }
  };

  // info box - 별점
  const rateFunction = (elem) => {
    let arr = [];
    for (var i = 0; i < 4; i++) {
      if (i < elem) {
        arr.push(<i className="fa fa-usd red-col" aria-hidden="true"></i>);
      } else {
        arr.push(<i className="fa fa-usd" aria-hidden="true"></i>);
      }
    }
    return arr;
  };

  const getDetailUrl = (category, postId) => {
    if (
      category === "SHOW" ||
      category === "EXHIBITION" ||
      category === "OTHER"
    ) {
      return "/event/event-detail/" + postId;
    } else {
      return "/place/place-detail/" + postId;
    }
  };

  const [showInfo, setShowInfoBox] = useState({
    id: 0,
    show: false,
  });

  const onChangeMarkerToInfo = (selectedId) => {
    setShowInfoBox({
      id: selectedId,
      show: true,
    });
  };

  const onChangeInfoToMarker = (selectedId) => {
    setShowInfoBox({
      id: selectedId,
      show: false,
    });
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/info1.jpg";
    }
  };

  const onLoad = (map) => {
    onLoadedApi(map);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={"map-wrapper"}
      zoom={16}
      center={center}
      onLoad={onLoad}
      options={{
        scrollwheel: false,
        scaleControl: false,
        panControl: false,
        navigationControl: false,
        streetViewControl: false,
        draggable: true, // 아래 변경 사항 적용 안되어 추가
        gestureHandling: "greedy", //"cooperative" -> "greedy"
      }}
    >
      {markerList.map((marker) =>
        showInfo.id === marker.id && showInfo.show ? (
          <InfoBox
            position={marker.position}
            options={{
              disableAutoPan: false,
              alignBottom: true,
              maxWidth: 0,
              pixelOffset: new window.google.maps.Size(-150, -55),
              // zIndex: null,
              boxStyle: {
                paddingright: "8px",
                paddingTop: "8px",
                width: "300px",
                height: "170px",
              },
              closeBoxMargin: "-3px",
              infoBoxClearance: new window.google.maps.Size(25, 25),
              isHidden: false,
              pane: "floatPane",
              enableEventPropagation: false,
              // enableEventPropagation: true,
            }}
          >
            <div
              className="event-post info-style"
              style={{
                background: "white",
                border: "0px solid white",
                borderRadius: "8px",
              }}
            >
              <div className="event-post__gal-box">
                <img
                  className="event-post__image"
                  src={getImgSrc(marker.imgSrc)}
                  alt="event-poster-image"
                />
              </div>
              <div className="event-post__content">
                <div className="infoBox-close">
                  <i
                    className="fa fa-times"
                    onClick={() => {
                      onChangeInfoToMarker(marker.id);
                    }}
                  ></i>
                </div>
                <h2 className="event-post__title">
                  <Link to={getDetailUrl(marker.category, marker.id)}>
                    {marker.title}
                  </Link>
                </h2>
                <p className="post__info-time">
                  <span>
                    {
                      {
                        practiceroom: "연습실",
                        show: "공연",
                        other: "기타",
                        gallery: "미술관/갤러리",
                        exhibition: "전시",
                        hall: "공연장",
                        instrument: "악기상점",
                      }[marker.category]
                    }
                  </span>
                  <span>locationOpen</span>
                </p>
                <p className="event-post__description">
                  <span className="event-post__rating-2 average-rat">
                    {marker.score}
                  </span>
                  {/* <span>{rateFunction(marker.score)}</span> */}
                  <span>{rateFunction(3)}</span>
                </p>
                <p className="event-post__address">{marker.address} </p>
              </div>
            </div>
          </InfoBox>
        ) : (
          ""
        )
      )}

      {markerList.map((marker) => (
        <OverlayView
          position={marker.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={marker.id}
        >
          <div
            className="marker-box"
            onClick={() => {
              onChangeMarkerToInfo(marker.id);
            }}
          >
            <div className="marker-holder">
              <div className="circle-icon">
                <div className="circle-icon-item">
                  {getIcon(marker.category)}
                </div>
                <div className="border-arrow-btm"></div>
              </div>
            </div>
          </div>
        </OverlayView>
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
});

export default SearchLocationMap;
