import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import MobileContext from "../../context/mobile";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";

import PlaceList from "./place-template/PlaceList";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const count = 8;

const PlaceView = ({ pageTitle, space_type }) => {
  const { state } = useContext(MobileContext);
  const [curPage, setCurPage] = useState(1);
  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const [placeList, setPlaceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  const getPlaceList = useCallback(async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list/${space_type}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: curPage,
          count: count,
          search_type: "STATE",
          search_word: "POST",
        },
      });

      if (response.status === 200) {
        const { total_count, list } = response.data;

        setTotalCount(total_count);

        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].id,
            imgSrc: getImage(list[i].resources),
            category: list[i].space_type,
            title: list[i].name,
            address: list[i].address1 + " " + list[i].address2,
            status: list[i].state,
          });
        }

        setPlaceList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPage, space_type]);

  useEffect(() => {
    getPlaceList();

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [getPlaceList]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle={pageTitle} />

      <section className="explore">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="explore__box">
                <h2 className="explore__filter-title">
                  <span>총</span> {totalCount}건
                </h2>

                <PlaceList list={placeList} />
                <Paging
                  pageNum={curPage}
                  getCurPage={getCurPage}
                  onePage={8}
                  all={totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default PlaceView;
