import React from "react";
import { Link } from "react-router-dom";

const FrequentlyVisitedPlaceListItem = ({ placeInfo, deletePlace }) => {
  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };

  const onConfirmDelete = () => {
    alert("삭제되었습니다.");

    deletePlace(placeInfo.id);
  };

  const onClickDelBtn = useConfirm(
    placeInfo.title + "을(를) 삭제하시겠습니까?",
    onConfirmDelete
  );

  const getLabel = () => {
    switch (placeInfo.category) {
      case "HALL":
        return (
          <span className="place-post__date place-post__hall">공연장</span>
        );
      case "PRACTICE":
        return (
          <span className="place-post__date place-post__practice">연습실</span>
        );
      case "INSTRUMENT":
        return (
          <span className="place-post__date place-post__instrument">
            악기상점
          </span>
        );
      case "GALLERY":
        return (
          <span className="place-post__date place-post__gallery">
            미술관/갤러리
          </span>
        );
      default:
        return "";
    }
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/place-poster.jpg";
    }
  };

  return (
    <div className="">
      <div className="place-post">
        <div className="place-post__gal">
          <Link to={`/place/place-detail/${placeInfo.id}`}>
            <img src={getImgSrc(placeInfo.imgSrc)} alt="문화공간 포스터" />
          </Link>
          {getLabel()}
        </div>
        <div className="post_content1">
          <h2 className="post_title1">
            <Link to={`/place/place-detail/${placeInfo.id}`}>
              {placeInfo.title}
            </Link>
          </h2>
          <p className="place-post__description">{placeInfo.address}</p>
          <input
            type="button"
            name="btn_js_confirm"
            id="btn_js_confirm"
            className="btn-square-sm__gray"
            onClick={onClickDelBtn}
            value="삭제"
          />
        </div>
      </div>
    </div>
  );
};
export default FrequentlyVisitedPlaceListItem;
