import React from "react";
import EventListItem from "./EventListItem";

import Grid from "@mui/material/Grid";

const EventList = ({ list, isFestival }) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      {list.map((eventInfo) => (
        <Grid item lg={3} md={3} sm={6} xs={12} key={eventInfo.id}>
          <EventListItem
            eventInfo={eventInfo}
            key={eventInfo.id}
            isFestival={isFestival}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default EventList;
