import React from "react";
import DOMPurify from "dompurify";

const addDot = (string) => {
  let result = "";
  return result.concat(
    string.slice(0, 4),
    ".",
    string.slice(4, 6),
    ".",
    string.slice(6)
  );
};

const BlogReviewListItem = ({ reviewInfo }) => {
  return (
    <li className="reviews-list__item">
      <div className="reviews-list__item-box">
        {/* <img
          className="reviews-list__item-image"
          src={reviewInfo.imgSrc}
          alt=""
        /> */}
        <div className="reviews-list__item-content">
          <h3 className="reviews-list__item-title">{reviewInfo.userName} </h3>
          <span className="reviews-list__items-date">
            {addDot(reviewInfo.date)}
          </span>
          <a
            className="btn-sc__item-reply btn-float-right"
            onClick={() => window.open(`${reviewInfo.url}`, "_blank")}
          >
            보러가기{" "}
          </a>
          <p
            className="reviews-list__item-description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(reviewInfo.title),
            }}
          ></p>
          <p
            className="reviews-list__item-description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(reviewInfo.description),
            }}
          ></p>
        </div>
      </div>
    </li>
  );
};

export default BlogReviewListItem;
