import React from "react";

const PageTitle = ({ pageTitle }) => {
  return (
    <section className="page-title">
      <div className="container">
        <h1 className="page-title__title">{pageTitle}</h1>
      </div>
    </section>
  );
};

export default PageTitle;
