import React, { useContext } from "react";
import MobileContext from "context/mobile";

import Footer from "components/ect/Footer";
import Header from "components/ect/Header";
import MenuBar from "components/ect/MenuBar";
import BottomNavigation from "components/ect/BottomNavigation";

const PrivacView = () => {
  const { state } = useContext(MobileContext);
  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section className="page-title">
        <div className="container">
          <h1 className="page-title__title">개인정보 처리 방침</h1>

          <ul className="single-post__list">
            <li className="single-post__list-item">
              <i className=""></i>
              <span></span>
            </li>
            <li className="single-post__list-item">
              <i className=""></i>
              <a href="#"></a>
            </li>
          </ul>
        </div>
      </section>

      <section className="explore">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sc-content cont-privacy">
                <div id="contents mt-20">
                  <div className="linebox">
                    <br />
                    <p className="mgb10">
                      {" "}
                      서초구청은 「개인정보보호법」등 관련법령상의 개인정보
                      보호규정을 준수하며, 개인정보 처리방침은 아래와 같습니다.{" "}
                    </p>
                    <p>
                      이 방침은 서초구청에서 처리하는 모든 개인정보파일에
                      적용되며, 소관업무 처리를 위해 해당 부서에서 별도의
                      개인정보처리방침을 제정·시행하는 경우에는 그에 따르고,
                      해당부서에서 운영하는 홈페이지에 게시함을 알려드립니다.
                    </p>
                  </div>
                  <br />

                  <dl className="law-wrap">
                    <dt>제1조(개인정보의 처리 목적)</dt>
                    <dd>
                      <p>
                        서초구청에서 개인정보는 다음의 목적을 위해 처리합니다.
                        처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
                        사용되지 않으며, 이용 목적이 변경되는 경우에는
                        개인정보보호법 제18조에 따라 별도의 동의를 받는 등
                        필요한 조치를 이행할 예정입니다.{" "}
                      </p>
                      <ol className="con-list">
                        <li>
                          <b>1. 홈페이지 회원 가입 및 관리</b>
                          <p>
                            회원가입, 회원제 서비스 이용에 따른 본인 식별·인증
                            회원자격 유지·관리, 제한적 본인 확인제 시행에 따른
                            본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의
                            개인정보 처리시 법정대리인의 동의여부 확인, 각종
                            고지·통지, 고충처리, 온라인 수강신청 및 서비스신청
                            처리 등을 목적으로 개인정보를 처리합니다.{" "}
                          </p>
                        </li>
                        <li>
                          <b>2. 민원 사무 처리</b>
                          <p>
                            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한
                            연락·처리결과 통보 등의 목적으로 개인정보를
                            처리합니다.{" "}
                          </p>
                        </li>
                        <li>
                          <b>3. 행정 서비스 </b>
                          <p>
                            자치행정, 교통, 도시계획, 주택건축, 보건복지, 교육,
                            문화예술, 환경관리 등 주민생활과 밀접한 공공서비스
                            제공을 목적으로 개인정보를 처리합니다.{" "}
                          </p>
                        </li>
                      </ol>
                    </dd>

                    <dt>
                      제2조(개인정보의 수집항목, 수집방법, 보유근거, 보유기간)
                    </dt>
                    <dd>
                      <p>
                        서초구청에서 서비스 제공을 위해 처리하는
                        개인정보파일(수집항목, 수집방법, 보유근거, 보유기간
                        등)은 개인정보보호 종합 포털에 등록·공개하고 있습니다.
                      </p>
                      <p className="mgt10">
                        개인정보는 개인정보의 처리 목적이 달성되면 즉시
                        파기합니다. 다만, 관련법령에 의해서 개인정보를
                        보존해야할 필요가 있는 경우에는 해당 법률의 규정에
                        따릅니다.
                      </p>
                      <p className="mgt10">
                        개인정보보호 종합 포털(
                        <a
                          title="새창"
                          className="link new"
                          href="http://www.privacy.go.kr"
                          target="_blank"
                        >
                          http://www.privacy.go.kr
                        </a>
                        ) → 개인정보민원 → 개인정보 열람 등 요구 → 개인정보파일
                        목록 검색에서 기관명에 “서초구”입력 후 조회{" "}
                      </p>
                    </dd>

                    <dt>제3조(개인정보의 제3자 제공)</dt>
                    <dd>
                      개인정보는 제1조(개인정보의 처리 목적)에서 명시한 범위
                      내에서만 처리하며, 정보주체의 사전 동의 없이는 본래의
                      범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
                      단, 개인정보보호법 제17조, 제18조 및 다른 법률에 해당 되는
                      경우에는 개인정보를 제3자에게 제공할 수 있습니다.
                    </dd>

                    <dt>제4조(개인정보처리의 위탁)</dt>
                    <dd>
                      <p>
                        서초구청은 개인정보 처리업무 위탁 계약 체결시 개인정보
                        보호법에 따라 위탁업무 수행 목적 외 개인정보 처리금지,
                        기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한
                        관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등
                        문서에 명시하고, 수탁자가 개인정보를 안전하게
                        처리하는지를 감독하고 있습니다.
                      </p>
                    </dd>

                    <dt>
                      제5조(정보주체 및 법정대리인의 권리·의무 및 행사방법)
                    </dt>
                    <dd>
                      <p>
                        정보주체(혹은 해당 주체의 법정대리인)는 다음과 같은
                        권리를 행사할 수 있습니다.{" "}
                      </p>
                      <ol className="con-list">
                        <li>
                          <b>1. 개인정보 열람 요구 </b>
                          <p>
                            「개인정보보호법」 제35조(개인정보의 열람)에 따라
                            열람을 요구할 수 있습니다. 다음의 경우는
                            「개인정보보호법」 제35조 4항에 의하여 열람을 제한할
                            수 있습니다.
                          </p>
                          <ul>
                            <li>
                              가. 법률에 따라 열람이 금지되거나 제한되는 경우
                            </li>
                            <li>
                              나. 다른 사람의 생명·신체를 해할 우려가 있거나
                              다른 사람의 재산과 그 밖의 이익을 부당하게 침해할
                              우려가 있는 경우
                            </li>
                            <li>
                              다. 공공기관이 다음 각 목의 어느 하나에 해당하는
                              업무를 수행할 때 중대한 지장을 초래하는 경우
                              <ul className="mgl20">
                                <li>
                                  1) 조세의 부과·징수 또는 환급에 관한 업무{" "}
                                </li>
                                <li>
                                  2) 「초·중등교육법」 및 「고등교육법」에 따른
                                  각급학교, 「평생교육법」에 따른 평생교육시설,
                                  그 밖의 다른 법률에 따라 설치된
                                  고등교육기관에서의 성적 평가 또는 입학자
                                  선발에 관한 업무{" "}
                                </li>
                                <li>
                                  3) 학력·기능 및 채용에 관한 시험, 자격심사에
                                  관한 업무{" "}
                                </li>
                                <li>
                                  4) 보상금·급부금 산정 등에 대하여 진행 중인
                                  평가 또는 판단에 관한 업무{" "}
                                </li>
                                <li>
                                  5) 다른 법률에 따라 진행 중인 감사 및 조사에
                                  관한 업무{" "}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>2. 개인정보 정정·삭제 요구 </b>
                          <p>
                            「개인정보보호법」 제36조 따라 개인정보의
                            정정·삭제를 요구할 수 있습니다. 다만, 다른 법령에서
                            그 개인정보가 수집 대상으로 명시되어 있는 경우에는
                            그 삭제를 요구할 수 없습니다.{" "}
                          </p>
                        </li>
                        <li>
                          <b>3. 개인정보 처리정지 요구 </b>
                          <p>
                            {" "}
                            「개인정보보호법」 제37조(개인정보의 처리정지 등)에
                            따라 처리정지를 요구할 수 있습니다. 단, 아래에
                            해당하는 경우에는 법 제37조 2항에 의하여 처리정지
                            요구를 거절 할 수 있습니다.{" "}
                          </p>
                          <ul>
                            <li>
                              가. 법률에 특별한 규정이 있거나 법령상 의무를
                              준수하기 위하여 불가피한 경우{" "}
                            </li>
                            <li>
                              나. 다른 사람의 생명·신체를 해할 우려가 있거나
                              다른 사람의 재산과 그 밖의 이익을 부당하게 침해 할
                              우려가 있는 경우
                            </li>
                            <li>
                              다. 공공기관이 개인정보를 처리하지 아니하면 다른
                              법률에서 정하는 소관업무를 수행할 수 없는 경우
                            </li>
                            <li>
                              라. 개인정보를 처리하지 아니하면 정보주체와 약정한
                              서비스를 제공하지 못하는 등 계약의 이행이 곤란한
                              경우로서 정보주체가 그 계약의 해지 의사를 명확하게
                              밝히지 아니한 경우
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>4. 개인정보 열람, 정정·삭제, 처리정지 절차</b>
                          <ul>
                            <li>
                              가. 개인정보 열람, 정정, 삭제, 처리정지 청구는
                              아래와 같은 절차로 처리됩니다.
                              <div className="img-wrap mgt20 mgb20">
                                <img
                                  alt="개인정보 열람, 정정, 삭제, 처리정지 청구 절차"
                                  src="../images/privacy2017.gif"
                                />
                                <div className="accessibility">
                                  <ol>
                                    <li>개인정보파일목록 검색</li>
                                    <li>
                                      열람청구
                                      <ol>
                                        <li>
                                          청구 주체 확인 및 개인정보 열람 범위
                                          확인
                                        </li>
                                        <li>개인정보 열람 제한사항 확인</li>
                                        <li>
                                          <ul>
                                            <li>
                                              열람결정 통지(허용/제한/연기) -
                                              열람
                                            </li>
                                            <li>열람결정 통지(거부)</li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </li>
                                    <li>
                                      정정ㆍ삭제, 처리정지 청구
                                      <ol>
                                        <li>
                                          청구 주체 확인 및 개인정보 정정ㆍ삭제,
                                          처리정지 범위 확인
                                        </li>
                                        <li>
                                          개인정보 정정ㆍ삭제, 처리정지 제한사항
                                          확인
                                        </li>
                                        <li>
                                          <ul>
                                            <li>
                                              정정ㆍ삭제, 처리정지 결과 통지
                                            </li>
                                            <li>
                                              정정ㆍ삭제, 처리정지 제한사항
                                              통지(거절, 타 법령 관련 사항 등)
                                            </li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </li>
                            <li>
                              나. 개인정보 열람 등 요구 방법
                              <p>
                                개인정보보호 종합 포털(
                                <a
                                  title="새창"
                                  className="link new"
                                  href="http://www.privacy.go.kr"
                                  target="_blank"
                                >
                                  http://www.privacy.go.kr
                                </a>
                                )을 통해 “온라인 신청” 또는 서초구청본관 1층
                                오-케이 민원센터를 통해 “오프라인 신청”을 하실
                                수 있습니다.{" "}
                              </p>
                              <div className="table-wrap">
                                <table className="__se_tbl_ext">
                                  <caption>
                                    개인정보 열람 등 요구 방법(온라인, 오프라인)
                                  </caption>
                                  <thead>
                                    <tr>
                                      <th scope="col">온라인(인터넷)</th>
                                      <th scope="col">오프라인(서면)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="left top">
                                        <ul>
                                          <li>
                                            1) 개인정보보호 종합 포털(
                                            <a href="http://www.privacy.go.kr)에">
                                              www.privacy.go.kr)에
                                            </a>
                                            접속{" "}
                                          </li>
                                          <li>
                                            2) 개인정보민원 → 개인정보열람 등
                                            요구안내 → [개인정보 열람 등 요구]
                                            클릭
                                          </li>
                                          <li>
                                            3) 아이핀(I-PIN) 또는 휴대폰
                                            인증으로 본인 확인
                                          </li>
                                          <li>
                                            4) [개인정보파일 목록검색]을 통한
                                            대상기관 선택
                                          </li>
                                          <li>
                                            5) 신청유형 선택 (개인정보 열람,
                                            정정·삭제, 처리정지 중 택 1)
                                          </li>
                                          <li>6) 요구서 작성 후 민원청구</li>
                                        </ul>
                                      </td>
                                      <td className="left top">
                                        <ul>
                                          <li>
                                            1) [
                                            <a
                                              className="link new"
                                              href="/common/files/Download.do?cfIdx=CF00056057&amp;cfGroup=COMMON&amp;cfRename=20210617151129_bdvlwqinrvtjhglpydjvk1ox1oflpkla.pdf"
                                            >
                                              개인정보 열람, 정정·삭제, 처리정지
                                              요구서
                                            </a>
                                            ]를 작성하여 서초구청 오-케이
                                            민원센터에 제출
                                          </li>
                                          <li>
                                            ※ 신청서 작성시, 제2조에 따라
                                            개인정보파일 목록 검색을 통해
                                            열람하고자 하는 개인정보파일명과
                                            처리부서명을 확인하여 기재
                                          </li>
                                          <li>
                                            2) 청구주체 확인을 위하여
                                            주민등록증·운전면허증·여권 등의
                                            신분증명서를 제시하여함
                                          </li>
                                          <li>
                                            ※ 만일, 대리인을 통하여 신청할
                                            경우에는 [위임장]을 제출해야 함{" "}
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </dd>

                    <dt>제6조(개인정보의 파기)</dt>
                    <dd>
                      <p>
                        서초구청은 개인정보 처리목적이 달성된 경우에는 지체 없이
                        해당 개인정보를 파기합니다. 파기 절차 및 파기 방법은
                        다음과 같습니다. 다만, 민원서류 발급이력 등은 “공공기관
                        기록물관리법”에 의거한 파기절차를 따릅니다.{" "}
                      </p>
                      <ol>
                        <li>
                          <b>1. 파기 절차</b>
                          <p>
                            개인정보 파기 사유가 발생한 개인정보 선정 후
                            파기계획 수립 및 분야별 개인정보보호 책임자의 승인
                            하에 개인정보를 파기합니다. 목적이 달성된 개인정보는
                            기록물 파기심의를 거치는 동안 별도의 DB로
                            옮겨져(종이의 경우에는 별도의 서류함에 보관)
                            일정기간 저장된 후 파기됩니다.{" "}
                          </p>
                        </li>
                        <li>
                          <b>2. 파기 방법 </b>
                          <p>
                            전자적 파일 형태로 기록·저장된 개인정보는 기록을
                            재생할 수 없도록 파기하며, 종이 문서에 기록·저장된
                            개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.{" "}
                          </p>
                        </li>
                      </ol>
                    </dd>

                    <dt>
                      제7조(인터넷 접속정보파일 등 개인정보를 자동으로 수집하는
                      장치의 설치·운영 및 거부에 관한 사항)
                    </dt>
                    <dd>
                      <ol>
                        <li>
                          <b>1. 수집되는 정보</b>
                          <p>
                            서초구 홈페이지 회원가입 및 이용 시 아래와 같은
                            정보가 자동으로 수집됩니다.
                          </p>
                          <ul className="mgl20">
                            <li>접속로그</li>
                            <li>쿠키</li>
                            <li>접속IP정보</li>
                            <li>홈페이지 접근경로 등</li>
                          </ul>
                        </li>
                        <li>
                          <b>2. 수집되는 정보의 용도</b>
                          <p>
                            자동수집된 정보는 부정이용 방지, 비인가 사용방지,
                            통계분석 등에 사용됩니다.
                          </p>
                        </li>
                        <li>
                          <b>3. 쿠키</b>
                          <p>
                            서초구는 개인 맞춤서비스 제공 및 회원식별과 로그인
                            상태를 유지하기 위해서 이용자에 대한 정보를 저장하고
                            수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는
                            웹사이트 서버가 이용자의 브라우저에게 전송하는
                            소량의 정보로서 이용자 컴퓨터의 하드디스크에
                            저장됩니다.
                          </p>
                        </li>
                        <li>
                          <b>4. 쿠키 설치에 대한 선택권</b>
                          <p>
                            홈페이지 이용자는 쿠키 설치에 대한 선택권을 가지고
                            있습니다. 웹 브라우저에서 옵션을 조정 함으로써 모든
                            쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을
                            거치도록 할 수 있습니다.
                          </p>
                          <p>
                            다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며
                            일부 서비스 이용에 어려움이 있을 수 있습니다.
                          </p>
                          <p className="mgl20">
                            <b>[쿠키 설치 허용 여부를 지정하는 방법]</b>
                          </p>
                          <ul className="mgl20">
                            <li>
                              - Internet Explorer의 경우 : 웹브라우저 상단의
                              도구메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정
                            </li>
                            <li>
                              - Chrome의 경우 : 웹브라우저 우측의 설정메뉴 &gt;
                              화면 하단의 고급설정 표시 &gt; 개인정보의 콘텐츠
                              설정 버튼 &gt; 쿠키
                            </li>
                          </ul>
                          <p>&nbsp;</p>
                        </li>
                      </ol>
                    </dd>

                    <dt>제8조(개인정보의 안전성 확보조치)</dt>
                    <dd>
                      <p>
                        개인정보의 안전성 확보를 위해 관리적·기술적·물리적
                        조치를 하고 있습니다.
                      </p>
                      <ol className="con-list">
                        <li>
                          <b>1. 관리적 조치</b>
                          <ul>
                            <li>가. 내부관리계획 수립 및 시행 </li>
                            <li>나. 개인정보 취급담당자 지정 </li>
                            <li>
                              다. 개인정보보호법 등 관련법규 준수를 위한 교육
                              실시{" "}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>2. 기술적 조치</b>
                          <ul>
                            <li>
                              가. 개인정보에 대한 접근 제한 (정보보안시스템,
                              정보처리시스템 운영 및 접근권한 관리)
                            </li>
                            <li>
                              나. 보안프로그램 설치 및 주기적인 갱신·점검 차단
                              (해킹, 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                              훼손 방지)
                            </li>
                            <li>
                              다. 주민등록번호 등 주요정보는 암호화하여 저장
                              관리
                            </li>
                            <li>
                              {" "}
                              라. 접속기록의 보관 및 위조·변조 방지 (접속기록을
                              최소 1년 이상 보관, 관리)
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>3. 물리적 조치</b>
                          <ul>
                            <li>
                              가. 개인정보를 보관하고 있는 물리적 장소 별도 운영
                            </li>
                            <li>나. 비인가에 대한 출입 통제 </li>
                          </ul>
                        </li>
                      </ol>
                    </dd>

                    <dt>제9조(개인정보 보호책임자)</dt>
                    <dd>
                      <p>
                        서초구청은 개인정보 처리에 관한 업무를 총괄해서
                        책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
                        피해구제 등을 위하여 아래와 같이 개인정보 보호책임자,
                        분야별책임자 및 담당자를 지정하고 있습니다.{" "}
                      </p>
                      <ol>
                        <li>
                          1. 개인정보보호책임자 및 개인정보보호담당자
                          <div className="table-wrap">
                            <table className="__se_tbl_ext">
                              <caption>
                                개인정보보호책임자 및 개인정보보호담당자 정보
                              </caption>
                              <thead>
                                <tr>
                                  <th scope="col">
                                    서초구청 개인정보보호책임자
                                  </th>
                                  <th scope="col">
                                    서초구청 개인정보보호담당자
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    밝은미래국장 박성준
                                    <br />
                                    연락처: 02-2155-6427
                                    <br />
                                    (Fax:02-2155-6439 )<br />※ 개인정보 보호
                                    담당부서로 연결됩니다.
                                  </td>
                                  <td className="left">
                                    <ul className="con-icon2">
                                      <li>부서 : 스마트도시과</li>
                                      <li>성명 : 이형수</li>
                                      <li>연락처 : 02-2155-6427</li>
                                      <li>
                                        이메일 :{" "}
                                        <a href="mailto:lhs3470@seocho.go.kr">
                                          lhs3470@seocho.go.kr
                                        </a>
                                      </li>
                                      <li>FAX : 02-2155-6439</li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </li>
                        <li>
                          2. 개인정보보호 분야별 책임자 : 개인정보를 처리하는
                          해당 부서의 장{" "}
                        </li>
                      </ol>
                    </dd>

                    <dt>제10조(권익침해 구제방법)</dt>
                    <dd>
                      <p>
                        정보주체는 개인정보침해로 인한 구제를 받기 위하여
                        개인정보분쟁조정위원회, 한국인터넷진흥원
                        개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할
                        수 있습니다.
                      </p>
                      <p>
                        또한, 개인정보침해의 신고 및 상담은 아래의 기관에 문의할
                        수 있습니다.
                      </p>
                      <ul className="con-icon2 mgt10">
                        <li>
                          개인정보 분쟁조정위원회 :{" "}
                          <a
                            title="새창"
                            className="link new"
                            href="http://kopico.go.kr"
                            target="_blank"
                          >
                            http://kopico.go.kr
                          </a>{" "}
                          (
                          <span className="tel" title="전화번호">
                            ☎
                          </span>{" "}
                          02-1833-6972){" "}
                        </li>
                        <li>
                          개인정보 침해신고센터 :{" "}
                          <a
                            title="새창"
                            className="link new"
                            href="http://privacy.kisa.or.kr"
                            target="_blank"
                          >
                            http://privacy.kisa.or.kr
                          </a>{" "}
                          (
                          <span className="tel" title="전화번호">
                            ☎
                          </span>{" "}
                          국번없이 118){" "}
                        </li>
                        <li>
                          경찰청 사이버안전국 :{" "}
                          <a
                            title="새창"
                            className="link new"
                            href="http://cyberbureau.police.go.kr"
                            target="_blank"
                          >
                            http://cyberbureau.police.go.kr
                          </a>{" "}
                          (
                          <span className="tel" title="전화번호">
                            ☎
                          </span>{" "}
                          국번없이 182){" "}
                        </li>
                        <li>
                          개인정보보호 종합 포털 :{" "}
                          <a
                            title="새창"
                            className="link new"
                            href="http://www.privacy.go.kr"
                            target="_blank"
                          >
                            http://www.privacy.go.kr
                          </a>{" "}
                          (
                          <span className="tel" title="전화번호">
                            ☎
                          </span>{" "}
                          02-2100-4047){" "}
                        </li>
                        <li>
                          대검찰청 사이버수사과 :{" "}
                          <a
                            title="새창"
                            className="link new"
                            href="http://www.spo.go.kr"
                            target="_blank"
                          >
                            http://www.spo.go.kr
                          </a>{" "}
                          (
                          <span className="tel" title="전화번호">
                            ☎
                          </span>{" "}
                          국번없이 1301){" "}
                        </li>
                      </ul>
                      <p>
                        또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한
                        정보주체의 요구에 대하여 공공기관의 장이 행한 처분 또는
                        부작위로 인하여 권리 또는 이익을 침해 받은 자는
                        행정심판법이 정하는 바에 따라 행정심판을 청구할 수
                        있습니다.
                      </p>
                      <p>
                        ☞ 중앙행정심판위원회(
                        <a
                          title="새창"
                          className="link new"
                          href="http://center.simpan.go.kr"
                          target="_blank"
                        >
                          center.simpan.go.kr
                        </a>
                        )의 전화번호 안내 참조
                      </p>
                    </dd>

                    <dt>제11조(개인정보의 추가적인 이용 또는 제공의 기준)</dt>
                    <dd>
                      <p>
                        서초구청은 개인정보 보호법 제15조 제3항 및 제17조
                        제4항에 따라 정보주체의 동의 없이 개인정보를 추가적으로
                        이용·제공 할 수 있습니다. 이에 따라 추가적인 이용 또는
                        제공하는 경우, 다음 각 호의 사항을 고려하고 있습니다.
                      </p>
                      <ol>
                        <li>1. 당초 수집 목적과 관련성이 있는지 여부</li>
                        <li>
                          2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼
                          때 개인정보의 추가적인 이용 또는 제공에 대한 예측
                          가능성이 있는지 여부
                        </li>
                        <li>3. 정보주체의 이익을 부당하게 침해하는지 여부</li>
                        <li>
                          4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를
                          하였는지 여부
                        </li>
                      </ol>
                    </dd>

                    <dt>제12조(개인정보 처리방침의 변경)</dt>
                    <dd>
                      <p>
                        분야별로 관리되는 개인정보파일의 추가, 삭제, 정정 등이
                        있는 경우 별도의 고지를 생략합니다. 이
                        개인정보처리방침은 2021년 6월 18일 부터 적용되며, 이전의
                        개인정보 처리방침은 아래에서 확인할 수 있습니다.{" "}
                      </p>
                      <ul className="con-icon2 mgt10">
                        <li>
                          <a className="link" href="#">
                            2012년 5월 1일 ~ 2013년 4월 12일
                          </a>
                        </li>
                        <li>
                          <a className="link" href="#">
                            2011년 12월 30일 ~ 2012년 4월 30일
                          </a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default PrivacView;
