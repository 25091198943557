import React from "react";
import { useHistory } from "react-router";
const AddPostButton = ({ onSubmitEvent }) => {
  const history = useHistory();

  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };

  const onConfirmCancel = () => {
    alert("취소되었습니다");
    history.goBack();
  };

  const onHandleCancel = useConfirm(
    "등록 신청 수정을 취소하시겠습니까?",
    onConfirmCancel
  );

  const onConfirmSubmit = () => {
    onSubmitEvent();
  };

  const onHandleSubmit = useConfirm(
    "등록 요청을 하시겠습니까?",
    onConfirmSubmit
  );

  return (
    <div className="center-button">
      <input
        type="button"
        name="btn_js_alert"
        id="btn_js_alert"
        className="btn-round-lg__border-gray"
        onClick={onHandleCancel}
        value="취소"
      />
      <input
        type="submit"
        name="btn_js_alert"
        id="btn_js_confirm"
        className="btn-round-lg__border-pink"
        onClick={onHandleSubmit}
        value="등록요청"
      />
    </div>
  );
};

export default AddPostButton;
