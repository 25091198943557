import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import MobileContext from "context/mobile";

import ReRegistorPostButton from "../common/Button/ReRegistorPostButton";
import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Editor from "../editor-video-template/Editor";
import BottomNavigation from "components/ect/BottomNavigation";

import RelatedVideoContainer from "../editor-video-template/RelatedVideoContainer";
import AddFilter from "../event/event-template/AddFilter";
import EventFormTemplate from "../event/event-template/EventFormTemplate";

const addDash = (string) => {
  return string.slice(0, 4) + "-" + string.slice(4, 6) + "-" + string.slice(6);
};

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);

  let str = "" + date.getFullYear();

  if (date.getMonth() < 9) {
    str += "0" + (date.getMonth() + 1);
  } else {
    str += date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    str += "0" + date.getDate();
  } else {
    str += date.getDate();
  }
  return str;
};

const DismissalEventDetailView = ({ match }) => {
  const { state } = useContext(MobileContext);

  const history = useHistory();

  const [rejectionReason, setRejectionReason] = useState({
    name: "",
    text: "",
  });

  const [formInfo, setFormInfo] = useState(null);
  const [detail, setDetail] = useState("");

  const [showFields, setShowFields] = useState([]);
  const [exhibitionFields, setExhibitionFields] = useState([]);
  const [otherFields, setOtherFields] = useState([]);

  const [themeSeasons, setThemeSeasons] = useState([]);
  const [themeTimes, setThemeTimes] = useState([]);
  const [themeFeelings, setThemeFeelings] = useState([]);
  const [themeParties, setThemeParties] = useState([]);
  const [scEvents, setScEvents] = useState([]);

  const [videoAry, setVideoAry] = useState(null);

  const onSubmitEvent = () => {
    console.log("등록 요청");

    let scEventActiveCount = 0;

    for (let i = 0; i < scEvents.length; i++) {
      if (scEvents[i].isActive) {
        scEventActiveCount++;
      }
    }

    if (scEventActiveCount > 1) {
      alert("서초구 축제는 하나만 입력할 수 있습니다");
      return;
    }

    const formData = new FormData();

    formData.append("id", formInfo.id);

    if (formInfo.imgFile) {
      formData.append("file", formInfo.imgFile[0]);
    }

    formData.append("name", formInfo.title);

    let inputText = true;
    for (let i = 0; i < placeOptions.length; i++) {
      if (formInfo.placeName === placeOptions[i].id) {
        inputText = false;
        formData.append("location", placeOptions[i].text);
        break;
      }
    }

    if (inputText) {
      formData.append("location", formInfo.placeName);
    }

    formData.append("address1", formInfo.address);
    formData.append("address2", formInfo.detailAddress);
    formData.append(
      "open_date",
      formInfo.period.start === ""
        ? ""
        : convertDateFormat(formInfo.period.start)
    );
    formData.append(
      "close_date",
      formInfo.period.end === "" ? "" : convertDateFormat(formInfo.period.end)
    );
    formData.append("age", formInfo.age);
    formData.append("homepage", formInfo.homePage);
    formData.append("reservation_site", formInfo.reservPage);
    formData.append("phone", formInfo.tel);
    formData.append("price", formInfo.price === "init" ? 0 : formInfo.price);
    formData.append("open_time", formInfo.time.open);
    formData.append("close_time", formInfo.time.close);
    formData.append("more_information", detail);
    formData.append("state", "WAIT");

    var ary;

    // field
    ary = new Array();

    switch (formInfo.category) {
      case "SHOW":
        for (let i = 0; i < showFields.length; i++) {
          if (showFields[i].isActive) {
            ary.push(showFields[i].id);
          }
        }
        break;
      case "EXHIBITION":
        for (let i = 0; i < exhibitionFields.length; i++) {
          if (exhibitionFields[i].isActive) {
            ary.push(exhibitionFields[i].id);
          }
        }
        break;
      case "OTHER":
        for (let i = 0; i < otherFields.length; i++) {
          if (otherFields[i].isActive) {
            ary.push(otherFields[i].id);
          }
        }
        break;
      default:
        break;
    }

    formData.append("event_field", ary);

    // theme
    ary = new Array();
    for (let i = 0; i < themeSeasons.length; i++) {
      if (themeSeasons[i].isActive) {
        ary.push(themeSeasons[i].id);
      }
    }

    for (let i = 0; i < themeTimes.length; i++) {
      if (themeTimes[i].isActive) {
        ary.push(themeTimes[i].id);
      }
    }

    for (let i = 0; i < themeFeelings.length; i++) {
      if (themeFeelings[i].isActive) {
        ary.push(themeFeelings[i].id);
      }
    }

    for (let i = 0; i < themeParties.length; i++) {
      if (themeParties[i].isActive) {
        ary.push(themeParties[i].id);
      }
    }
    formData.append("event_theme", ary);

    for (let i = 0; i < scEvents.length; i++) {
      if (scEvents[i].isActive) {
        formData.append("festival_id", scEvents[i].id);
        break;
      }
    }

    formData.append("event_type", formInfo.category);

    ary = new Array();
    let temp;
    for (let i = 0; i < videoAry.length; i++) {
      temp = new Object();
      temp.url = videoAry[i].vId;
      ary.push(temp);
    }
    formData.append("videos", JSON.stringify(ary));

    formData.append("userid", window.sessionStorage.getItem("userid"));

    for (let key of formData.keys()) {
      console.log(key);
    }

    for (let value of formData.values()) {
      console.log(value);
    }

    requestPostEvent(formData);
  };

  const requestPostEvent = async (eventData) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/regist`;

    try {
      const res = await axios.post(url, eventData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        if (res.data.id === "") {
          alert("저장 도중 오류가 발생하였습니다. ");
        } else {
          alert("재등록 신청이 완료되었습니다.");
          history.push("/my-page/user-cont-refused");
        }
      }
    } catch (e) {
      console.log(" ====== POST ERROR ======");
      console.log(e);
    }
  };

  const getFormInfo = (dataName, data) => {
    if (dataName === "start" || dataName === "end") {
      setFormInfo({
        ...formInfo,
        period: {
          ...formInfo.period,
          [dataName]: data,
        },
      });
    } else if (dataName === "open" || dataName === "close") {
      setFormInfo({
        ...formInfo,
        time: {
          ...formInfo.time,
          [dataName]: data,
        },
      });
    } else {
      setFormInfo({
        ...formInfo,
        [dataName]: data,
      });
    }
  };

  const getDetail = useCallback((result) => {
    setDetail(result);
  }, []);

  const onChangeFilter = (filterAry, dataId) => {
    let ary = [];
    for (let i = 0; i < filterAry.length; i++) {
      if (filterAry[i].id === dataId) {
        ary.push({
          id: dataId,
          isActive: !filterAry[i].isActive,
          name: filterAry[i].name,
        });
      } else {
        ary.push(filterAry[i]);
      }
    }

    return ary;
  };

  const onResetFilterAry = (filterAry) => {
    let ary = [];

    for (let i = 0; i < filterAry.length; i++) {
      ary.push({
        ...filterAry[i],
        isActive: false,
      });
    }

    return ary;
  };

  const resetFilters = () => {
    setShowFields(onResetFilterAry(showFields));
    setExhibitionFields(onResetFilterAry(exhibitionFields));
    setOtherFields(onResetFilterAry(otherFields));
    setThemeSeasons(onResetFilterAry(themeSeasons));
    setThemeTimes(onResetFilterAry(themeTimes));
    setThemeFeelings(onResetFilterAry(themeFeelings));
    setThemeParties(onResetFilterAry(themeParties));
    setScEvents(onResetFilterAry(scEvents));
  };

  const getFilterInfo = (filterName, dataId) => {
    switch (filterName) {
      case "show":
        setShowFields(onChangeFilter(showFields, dataId));
        break;
      case "exhibition":
        setExhibitionFields(onChangeFilter(exhibitionFields, dataId));
        break;
      case "other":
        setOtherFields(onChangeFilter(otherFields, dataId));
        break;
      case "themeSeasons":
        setThemeSeasons(onChangeFilter(themeSeasons, dataId));
        break;
      case "themeTimes":
        setThemeTimes(onChangeFilter(themeTimes, dataId));
        break;
      case "themeFeelings":
        setThemeFeelings(onChangeFilter(themeFeelings, dataId));
        break;
      case "themeParties":
        setThemeParties(onChangeFilter(themeParties, dataId));
        break;
      case "scEvents":
        setScEvents(onChangeFilter(scEvents, dataId));
        break;
      default:
        console.log(filterName, dataId);
        break;
    }
  };

  const onChangeUrls = (videoAry) => {
    setVideoAry(videoAry);
  };

  const deleteDismissalEvent = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/${formInfo.id}`;
    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        history.push("/my-page/user-cont-refused");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [placeOptions, setPlaceOptions] = useState([
    { id: "default", text: "장소 선택" },
  ]);

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const { postId } = match.params;

    const getDetail = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/detail/${postId}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setFormInfo({
            id: res.data.id,
            like: false,
            imgSrc: getImage(res.data.resources),
            imgFile: null,
            category: res.data.event_type,
            title: res.data.name,
            address: res.data.address1,
            detailAddress: res.data.address2,
            period: {
              start: addDash(res.data.open_date),
              end: addDash(res.data.close_date),
            },
            placeName: res.data.location,
            age: parseInt(res.data.age),
            time: {
              open: res.data.open_time,
              close: res.data.close_time,
            },
            homePage: res.data.homepage,
            reservPage: res.data.reservation_site,
            tel: res.data.phone,
            price: res.data.price,
          });

          setRejectionReason({
            name: res.data.rejection_reason_name,
            text: res.data.rejection_reason_text,
          });

          setDetail(res.data.more_information);

          let ary = [];
          if (Object.keys(res.data.resources).includes("videos")) {
            let id = 1000;

            for (let i = 0; i < res.data.resources.videos.length; i++) {
              ary.push({
                id: id++,
                vId: res.data.resources.videos[i].url,
              });
            }
          }
          setVideoAry(ary);

          const event_theme = res.data.event_theme.includes(",")
            ? res.data.event_theme.replace(/"/gi, "").split(",")
            : [res.data.event_theme.replace(/"/gi, "")];

          let seasonsFormat = [
            { id: "SPRING", name: "봄", isActive: false },
            { id: "SUMMER", name: "여름", isActive: false },
            { id: "FALL", name: "가을", isActive: false },
            { id: "WINTER", name: "겨울", isActive: false },
          ];
          let feelingsFormat = [
            { id: "FUNNY", name: "재미있는", isActive: false },
            { id: "UNIQUE", name: "이색적인", isActive: false },
            { id: "LEARN", name: "학습을 돕는", isActive: false },
            { id: "IMPRESSIVE", name: "감동적인", isActive: false },
          ];
          let partiesFormat = [
            { id: "ALONE", name: "나홀로", isActive: false },
            { id: "FRIEND", name: "친구랑", isActive: false },
            { id: "COUPLE", name: "커플", isActive: false },
            { id: "KID", name: "아이랑", isActive: false },
            { id: "PARENTS", name: "부모님", isActive: false },
            { id: "FAMILY", name: "가족", isActive: false },
          ];
          let timesFormat = [
            { id: "CHRISTMAS", name: "크리스마스", isActive: false },
            { id: "VALENTINES_DAY", name: "발렌타인데이", isActive: false },
            { id: "HOLIDAY", name: "명절", isActive: false },
            { id: "ANNIVERSARY", name: "기념일", isActive: false },
            { id: "SUNNY", name: "화창함", isActive: false },
            { id: "CLOUDY_RAIN", name: "흐림/비", isActive: false },
          ];

          for (let i = 0; i < seasonsFormat.length; i++) {
            for (let j = 0; j < event_theme.length; j++) {
              if (event_theme[j] === seasonsFormat[i].id) {
                seasonsFormat[i] = {
                  ...seasonsFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setThemeSeasons(seasonsFormat);

          for (let i = 0; i < feelingsFormat.length; i++) {
            for (let j = 0; j < event_theme.length; j++) {
              if (event_theme[j] === feelingsFormat[i].id) {
                feelingsFormat[i] = {
                  ...feelingsFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setThemeFeelings(feelingsFormat);

          for (let i = 0; i < partiesFormat.length; i++) {
            for (let j = 0; j < event_theme.length; j++) {
              if (event_theme[j] === partiesFormat[i].id) {
                partiesFormat[i] = {
                  ...partiesFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setThemeParties(partiesFormat);

          for (let i = 0; i < timesFormat.length; i++) {
            for (let j = 0; j < event_theme.length; j++) {
              if (event_theme[j] === timesFormat[i].id) {
                timesFormat[i] = {
                  ...timesFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setThemeTimes(timesFormat);

          const event_field = res.data.event_field.includes(",")
            ? res.data.event_field.replace(/"/gi, "").split(",")
            : [res.data.event_field.replace(/"/gi, "")];

          let showFieldsFormat = [
            { id: "MUSICAL_OPERA", name: "뮤지컬/오페라", isActive: false },
            { id: "MUSIC_CONCERT", name: "음악/콘서트", isActive: false },
            { id: "PLAY", name: "연극", isActive: false },
            { id: "CHILD_DRAMA", name: "아동극/인형극", isActive: false },
            { id: "DANCE", name: "무용", isActive: false },
            { id: "OTHER", name: "기타", isActive: false },
            { id: "COME_KOREA", name: "내한", isActive: false },
            { id: "CLASSIC", name: "클래식", isActive: false },
            { id: "TRADITIONAL_ART", name: "전통예술", isActive: false },
            { id: "INDIE", name: "인디", isActive: false },
            { id: "JAZZ", name: "재즈", isActive: false },
            { id: "KPOP", name: "K-POP", isActive: false },
            { id: "ROCK", name: "Rock", isActive: false },
            { id: "BALLAD", name: "발라드", isActive: false },
            { id: "DANCE_POP", name: "댄스", isActive: false },
            { id: "RNB", name: "R&B/소울", isActive: false },
            { id: "HIPHOP", name: "힙합", isActive: false },
          ];
          let exhibitionFieldsFormat = [
            { id: "KOREAN_WRITERS", name: "국내 작가", isActive: false },
            { id: "FOREIGN_WRITERS", name: "해외 작가", isActive: false },
            { id: "HISTORY", name: "역사", isActive: false },
            { id: "SCULPTURE", name: "조각", isActive: false },
            { id: "PHOTO", name: "사진", isActive: false },
            { id: "FOLK_ART", name: "전통미술", isActive: false },
            { id: "KOREAN_PAINTING", name: "한국화", isActive: false },
            { id: "ENGRAVING", name: "판화", isActive: false },
            { id: "WATERCOLOR", name: "수채화", isActive: false },
            { id: "CRAFTS", name: "공예", isActive: false },
            { id: "CALLIGRPHY", name: "서예", isActive: false },
            { id: "EXHIBITION_OTHER", name: "기타", isActive: false },
          ];
          let otherFieldsFormat = [
            { id: "FESTIVAL", name: "축제", isActive: false },
            { id: "EXPERIENCE_EDUCATION", name: "체험/교육", isActive: false },
            { id: "MOVIE", name: "영화", isActive: false },
            { id: "OTHER_OTHER", name: "기타", isActive: false },
          ];

          for (let i = 0; i < showFieldsFormat.length; i++) {
            for (let j = 0; j < event_field.length; j++) {
              if (event_field[j] === showFieldsFormat[i].id) {
                showFieldsFormat[i] = {
                  ...showFieldsFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setShowFields(showFieldsFormat);

          for (let i = 0; i < exhibitionFieldsFormat.length; i++) {
            for (let j = 0; j < event_field.length; j++) {
              if (event_field[j] === exhibitionFieldsFormat[i].id) {
                exhibitionFieldsFormat[i] = {
                  ...exhibitionFieldsFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setExhibitionFields(exhibitionFieldsFormat);

          for (let i = 0; i < otherFieldsFormat.length; i++) {
            for (let j = 0; j < event_field.length; j++) {
              if (event_field[j] === otherFieldsFormat[i].id) {
                otherFieldsFormat[i] = {
                  ...otherFieldsFormat[i],
                  isActive: true,
                };
              }
            }
          }
          setOtherFields(otherFieldsFormat);

          return res.data.festival_id;
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getPlaceList = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list`;

      try {
        const response = await axios.get(url, {
          params: {
            sort_type: "desc",
            sort_column: "create_date",
            page: 1,
            count: 1000,
            search_type: "STATE",
            search_word: "POST",
          },
        });

        if (response.status === 200) {
          let options = [];
          options.push({ id: "default", text: "장소 선택" });

          for (let i = 0; i < response.data.list.length; i++) {
            options.push({
              id: response.data.list[i].id + i,
              text: response.data.list[i].name,
            });
          }
          setPlaceOptions(options);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getScEventList = async (festivalId) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/seochogu-festival/list`;

      try {
        const res = await axios.get(url, {
          params: {
            page: 1,
            count: 1000,
            search_type: "STATE",
            search_word: "POST",
          },
        });

        if (res.status === 200) {
          const { list } = res.data;

          const today = new Date();
          let fromDate = "" + today.getFullYear();

          if (today.getMonth() < 9) {
            fromDate += "0" + (today.getMonth() + 1);
          } else {
            fromDate += today.getMonth() + 1;
          }

          if (today.getDate() < 10) {
            fromDate += "0" + today.getDate();
          } else {
            fromDate += today.getDate();
          }

          let ary = [];

          // 끝나지 않은 서초구 축제만
          for (let i = 0; i < list.length; i++) {
            if (fromDate <= list[i].close_date) {
              ary.push({
                id: list[i].id,
                name: list[i].name,
                isActive: list[i].id === festivalId ? true : false,
              });
            }
          }

          setScEvents(ary);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getDetail().then((festivalId) => {
      getScEventList(festivalId);
    });
    getPlaceList();
  }, [history, match.params]);

  if (formInfo === null || videoAry === null) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <h1 className="page__title page__title-black">
                <span>문화공간 등록하기</span>
              </h1>
              <p className="listing-detail__subtitle">
                <Link to="/main">홈</Link> /{" "}
                <Link to="my-page/user-info">프로필</Link> /{" "}
                <Link to="/my-page/user-cont-refused">
                  내가 등록신청한 게시물
                </Link>{" "}
                / <span className="active">기각된 게시물</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="page-top" style={{ textAlign: "center" }}>
        <div
          className="user-stay"
          style={{
            backgroundColor: "grey",
            textAlign: "center",
            padding: "5px",
          }}
        >
          기각
        </div>
        기각사유 : 해당 게시물은 {rejectionReason.name}으로 등록 요청이 기각
        되었습니다
        <br />
        {`(상세 : ${rejectionReason.text})`}
      </div>

      <section className="listing-detail add-listing">
        <div className="listing-detail__content">
          <div className="container">
            <div className="container">
              <EventFormTemplate
                getFormInfo={getFormInfo}
                formInfo={formInfo}
                placeOptions={placeOptions}
              />
              <AddFilter
                showFields={showFields}
                exhibitionFields={exhibitionFields}
                otherFields={otherFields}
                themeSeasons={themeSeasons}
                themeTimes={themeTimes}
                themeFeelings={themeFeelings}
                themeParties={themeParties}
                scEvents={scEvents}
                getFilterInfo={getFilterInfo}
                resetFilters={resetFilters}
                category={formInfo.category}
              />

              <div className="row">
                <div className="col-lg-12">
                  <div className="listing-detail__content-box listing-detail__content-box-nopadding">
                    <Editor
                      editorTitle="공연정보"
                      getDetail={getDetail}
                      initDetail={detail}
                    />
                    <RelatedVideoContainer
                      onChangeUrls={onChangeUrls}
                      initVideoInfos={videoAry}
                    />

                    <ReRegistorPostButton
                      onSubmitEvent={onSubmitEvent}
                      deleteDismissal={deleteDismissalEvent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default DismissalEventDetailView;
