import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import MobileContext from "context/mobile";
import axios from "axios";

import Header from "../ect/Header";
import BottomNavigation from "components/ect/BottomNavigation";

const FindIdPwdView = () => {
  const { state } = useContext(MobileContext);

  const history = useHistory();
  const [tapMenu, setTapMenu] = useState(0);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  // const [varificationCode, setVarificationCode] = useState("");
  const [id, setId] = useState("");
  const [sns, setSns] = useState("");

  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");

  const [showFindResultView, setShowFindResultView] = useState(false);

  const [nameVerification, setNameVerification] = useState("none");

  const [regList, setRegList] = useState({
    pwd: true,
    secondPwd: true,
  });

  const onChangeTapMenu = (menu) => {
    setTapMenu(menu);

    setShowFindResultView(false);
    // setNameVerification("none");

    setPhone("");
    if (menu === 0) {
      setId("");
    } else {
      setName("");
    }
  };

  const onClickVarificationBtn = () => {
    let popup = window.open(
      "https://culture.seocho.go.kr:8443/mobileAuth/request",
      "verificationWindow",
      "width=455px , height=100%"
    );

    const successToVerification = (e) => {
      popup.close();
      if (e.data.message === "OK") {
        setNameVerification(true);
        setPhone(e.data.cellNo);
      } else {
        setNameVerification(false);
      }
    };

    window.addEventListener("message", successToVerification, false);
  };

  const findId = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/user/find/id`;

    try {
      const response = await axios.get(url, {
        params: {
          name: name,
          phone: phone,
        },
      });

      if (response.status === 200) {
        const { userId, sns } = response.data;

        if (userId === "USER_NOT_FOUND") {
          alert("존재하지 않는 회원입니다.\n회원정보를 확인해주세요.");
        } else {
          setId(userId);
          setSns(sns);

          setShowFindResultView(true);
        }
      }
    } catch (e) {
      alert("아이디 찾기 중 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const onSubmitFindId = (e) => {
    e.preventDefault();

    if (name === "") {
      alert("이름을 입력해주세요.");
      return;
    } else if (nameVerification === "none") {
      alert("실명 인증을 진행해주세요.");
      return;
    } else if (nameVerification === false) {
      alert("실명인증에 실패하였습니다.\n실명인증을 다시 진행해주세요.");
      return;
    }

    findId();
  };

  const isEmail = (id) => {
    const regEmail = /^[a-zA-Z0-9\.\-_]+@([a-zA-Z0-9\-]+\.)+[a-z]{2,6}$/;

    if (regEmail.test(id)) {
      return true;
    } else {
      return false;
    }
  };

  const checkUserId = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/user/exists/${id}`;

    try {
      const res = await axios.get(url);

      if (res.status === 200) {
        if (res.data === 1) {
          isEmail(id)
            ? alert(
                "회원님께서는 sns로 회원가입 하셨습니다. \n로그인 화면 하단의 sns 로그인 버튼을 통해 로그인 해주세요"
              )
            : setShowFindResultView(true);
        } else {
          alert("존재하지 않는 아이디입니다.\n아이디를 확인해주세요.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitFindPwd = (e) => {
    e.preventDefault();

    if (id === "") {
      alert("아이디를 입력해주세요.");
      return;
    } else if (nameVerification !== true) {
      alert("실명인증을 진행해주세요.");
      return;
    }

    checkUserId();
  };

  const pwdInput = useRef();
  const secondPwdInput = useRef();

  const regPwdTest = (pwd) => {
    const regPwdNumCha =
      /^(?=.*[a-zA-Z])(?=.*[#@$!%*?&^-])(?=.*\d)[a-zA-Z\d#@$!%*?&^-]{10,}$/;
    const regPwdNum = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;
    const regPwdCha =
      /^(?=.*[a-zA-Z])(?=.*[#@$!%*?&^-])[a-zA-Z#@$!%*?&^-]{10,}$/;

    if (regPwdNumCha.test(pwd) || regPwdNum.test(pwd) || regPwdCha.test(pwd)) {
      if (!regList.pwd) {
        setRegList({
          ...regList,
          pwd: true,
        });
      }
      pwdInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.pwd)
        setRegList({
          ...regList,
          pwd: false,
        });

      pwdInput.current.focus();
      pwdInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };

  const regSecondPwdTest = (pwd) => {
    if (pwd !== pwd1) {
      if (regList.secondPwd) {
        setRegList({
          ...regList,
          secondPwd: false,
        });
      }
      secondPwdInput.current.focus();
      secondPwdInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    } else {
      if (!regList.secondPwd) {
        setRegList({
          ...regList,
          secondPwd: true,
        });
      }
      secondPwdInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    }
  };

  const onChangePwd = (number, e) => {
    if (number === 1) {
      regPwdTest(e.target.value);
      setPwd1(e.target.value);
    } else {
      regSecondPwdTest(e.target.value);
      setPwd2(e.target.value);
    }
  };

  const resetPassword = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/user/reset/pw`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 200) {
        alert("비밀번호 변경가 변경되었습니다.\n로그인 페이지로 이동합니다.");
        history.push("/common/log-in");
      }
    } catch (e) {
      alert("비밀번호 변경에 실패하였습니다.");
      console.log(e);
    }
  };

  const onSubmitResetPwd = (e) => {
    e.preventDefault(e);

    if (!regPwdTest(pwd1) || !regList.pwd) {
      alert("입력하신 비밀번호를 확인해주세요.");
      return;
    } else if (!regSecondPwdTest(pwd2) || !regList.secondPwd) {
      alert("입력하신 비밀번호가 일치하지 않습니다.");
      return;
    }

    let data = new Object();

    data.id = id;
    data.password = pwd1;

    resetPassword(data);
  };

  return (
    <div id="container">
      <Header />

      <section
        className="sign"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="sign__area">
          <nav>
            <div className="nav nav-tabs" id="nav-tab">
              <a
                className={
                  tapMenu === 0
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                onClick={() => onChangeTapMenu(0)}
                style={{
                  cursor: "pointer",
                }}
              >
                아이디 찾기
              </a>
              <a
                className={
                  tapMenu === 1
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                onClick={() => onChangeTapMenu(1)}
                style={{
                  cursor: "pointer",
                }}
              >
                비밀번호 찾기
              </a>
            </div>
          </nav>
          <div style={{ display: "block", backgroundColor: "#ffff" }}>
            {
              {
                0: showFindResultView ? (
                  <div>
                    <div className="tab-form">
                      {sns === "" && (
                        <>
                          <label
                            className="tab-htmlForm__label"
                            htmlFor="username"
                          >
                            회원님의 정보와 일치하는 아이디입니다.{" "}
                          </label>
                          <div className="tab-form__checkbox">
                            <span className="tab-form__checkbox-text">
                              {id}
                            </span>
                          </div>
                        </>
                      )}

                      {sns && (
                        <>
                          <label className="tab-form__label" htmlFor="username">
                            {`회원님께서는 "
                        ${
                          {
                            NAVER: "네이버",
                            GOOGLE: "구글",
                            FACEBOOK: "페이스북",
                          }[sns]
                        }
                        (SNS)"로 로그인 하셨습니다.`}
                          </label>
                          <label className="tab-form__laber" htmlFor="username">
                            로그인 화면 하단의 sns 로그인 버튼을 통해 로그인
                            해주세요
                          </label>
                        </>
                      )}
                      <button
                        className="tab-form__submit"
                        id="submit-loggin"
                        type="button"
                        onClick={() => history.push("/common/log-in")}
                      >
                        로그인하기{" "}
                      </button>
                      <button
                        className="tab-form__submit"
                        id="submit-loggin"
                        type="buttom"
                        onClick={() => onChangeTapMenu(1)}
                      >
                        비밀번호 찾기{" "}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <form className="tab-form" onSubmit={onSubmitFindId}>
                      <label className="tab-form__label" htmlFor="username">
                        이름{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="text"
                        name="username"
                        id="username"
                        placeholder="이름을 입력하세요"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <label className="tab-form__label" htmlFor="password">
                        휴대전화{" "}
                      </label>
                      <div className="row">
                        <div className="flex">
                          <input
                            className="tab-form__input-text"
                            type="text"
                            name="id"
                            id="id"
                            placeholder="휴대전화 번호"
                            disabled={true}
                            value={phone}
                            // onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <input
                          type="button"
                          name="btn_js_alert"
                          id="btn_js_confirm"
                          className="button-sc__submit btn_float-left"
                          value="실명인증"
                          onClick={() => onClickVarificationBtn()}
                        />
                      </div>
                      {nameVerification === true ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증에 성공하였습니다.
                        </p>
                      ) : nameVerification === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증에 실패하였습니다.
                        </p>
                      ) : (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증 버튼을 눌러 실명인증을 먼저 진행해주세요.
                        </p>
                      )}
                      <br />
                      <button
                        className="tab-form__submit"
                        id="submit-loggin"
                        type="submit"
                      >
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                        확인{" "}
                      </button>
                    </form>
                  </div>
                ),
                1: showFindResultView ? (
                  <div>
                    <form className="tab-form" onSubmit={onSubmitResetPwd}>
                      <div className="tab-form__checkbox">
                        <span className="tab-form__checkbox-text">{`${id}님, `}</span>
                      </div>
                      <label className="tab-form__label" for="username">
                        비밀번호를 재설정해주세요.
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="비밀번호를 입력하세요"
                        value={pwd1}
                        ref={pwdInput}
                        onChange={(e) => onChangePwd(1, e)}
                      />
                      {regList.pwd === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          비밀번호 형식을 확인해주세요
                          <br />
                          10자 이상, 영문 대소문자 포함, 숫자 또는
                          특수문자(#@$!%*?&amp;-^) 조합
                        </p>
                      ) : (
                        ""
                      )}
                      <label className="tab-form__label" htmlFor="password">
                        비밀번호 재입력{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="비밀번호를 입력하세요"
                        value={pwd2}
                        ref={secondPwdInput}
                        onChange={(e) => onChangePwd(2, e)}
                      />
                      {regList.secondPwd === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          입력하신 비밀번호가 일치하지 않습니다
                        </p>
                      ) : (
                        ""
                      )}
                      <button
                        className="tab-form__submit"
                        id="submit-loggin"
                        type="submit"
                        // onClick={() => history.push("/common/log-in")}
                      >
                        확인{" "}
                      </button>
                    </form>
                  </div>
                ) : (
                  <div>
                    <form className="tab-form" onSubmit={onSubmitFindPwd}>
                      <label className="tab-form__label" htmlFor="username">
                        아이디{" "}
                      </label>
                      <input
                        className="tab-form__input-text"
                        type="text"
                        name="userId"
                        id="userId"
                        placeholder="아이디를 입력하세요"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                      />
                      <label className="tab-form__label" htmlFor="password">
                        휴대전화{" "}
                      </label>
                      <div className="row">
                        <div className="flex">
                          <input
                            className="tab-form__input-text"
                            type="text"
                            name="id"
                            id="id"
                            placeholder="휴대전화 번호"
                            value={phone}
                            disabled={true}
                            // onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <input
                          type="button"
                          name="btn_js_alert"
                          id="btn_js_confirm"
                          className="button-sc__submit btn_float-left"
                          value="실명인증"
                          onClick={() => onClickVarificationBtn()}
                        />
                      </div>
                      {nameVerification === true ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증에 성공하였습니다.
                        </p>
                      ) : nameVerification === false ? (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증에 실패하였습니다.
                        </p>
                      ) : (
                        <p
                          style={{
                            marginTop: "-20px",
                            color: "#ef5797",
                          }}
                        >
                          실명인증 버튼을 눌러 실명인증을 먼저 진행해주세요.
                        </p>
                      )}
                      {/* <input
                        className="tab-form__input-text"
                        type="text"
                        name="username"
                        id="username"
                        placeholder="인증번호를 입력하세요"
                        value={varificationCode}
                        // onChange={(e) => setVarificationCode(e.target.value)}
                      /> */}
                      <button
                        className="tab-form__submit"
                        id="submit-loggin"
                        type="submit"
                      >
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                        확인{" "}
                      </button>
                    </form>
                  </div>
                ),
              }[tapMenu]
            }
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
    </div>
  );
};

export default FindIdPwdView;
