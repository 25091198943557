import React, { useState, useRef } from "react";

const ReservForm = ({ addReserv }) => {
  const [reservInfo, setReservInfo] = useState({
    name: "",
    tel: "",
    content: "",
  });

  const [regList, setRegList] = useState({
    name: true,
    tel: true,
  });

  const nameInput = useRef();
  const telInput = useRef();

  const regNameTest = (name) => {
    const regNameKorean = /^[가-힣\s]{2,15}$/; // 한글 2 ~ 15자
    const regNameEnglish = /^[a-zA-Z\s]{2,30}$/; // 영어 2자 ~ 30자 (공백 가능)

    if (regNameKorean.test(name) || regNameEnglish.test(name)) {
      if (regList.name === false) {
        setRegList({
          ...regList,
          name: true,
        });
      }

      nameInput.current.style =
        "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.name === true) {
        setRegList({
          ...regList,
          name: false,
        });
      }

      nameInput.current.focus();
      nameInput.current.style =
        "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };
  const onChangeName = (e) => {
    regNameTest(e.target.value);
    setReservInfo({
      ...reservInfo,
      name: e.target.value,
    });
  };

  const regTelTest = (tel) => {
    const regPhone = /^[0-9]{2,3}([0-9]{3,4})([0-9]{4})$/;

    if (regPhone.test(tel)) {
      if (!regList.tel)
        setRegList({
          ...regList,
          tel: true,
        });

      telInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
      return true;
    } else {
      if (regList.tel)
        setRegList({
          ...regList,
          tel: false,
        });

      telInput.current.focus();
      telInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
      return false;
    }
  };
  const onChangeTel = (e) => {
    regTelTest(e.target.value);
    setReservInfo({
      ...reservInfo,
      tel: e.target.value,
    });
  };

  const onChangeContent = (e) => {
    setReservInfo({
      ...reservInfo,
      content: e.target.value,
    });
  };

  const onSubmitForm = () => {
    addReserv(reservInfo.name, reservInfo.tel, reservInfo.content);
    setReservInfo({
      name: "",
      tel: "",
      content: "",
    });
  };

  return (
    <div className="inner-review__form">
      <div className="row">
        <div className="col-md-1">
          <label className="add-listing__label" for="list-title">
            이름 :{" "}
          </label>
        </div>
        <div className="col-md-11">
          <input
            className="add-listing__input"
            type="text"
            name="list-title"
            id="list-title"
            placeholder="이름"
            value={reservInfo.name}
            onChange={(e) => {
              onChangeName(e);
            }}
            ref={nameInput}
          />
          {regList.name === false ? (
            <p
              style={{
                marginTop: "-18px",
                color: "#ef5797",
              }}
            >
              이름을 확인해주세요
            </p>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-1">
          <label className="add-listing__label" for="list-title">
            전화번호 :{" "}
          </label>
        </div>
        <div className="col-md-11">
          <input
            className="add-listing__input"
            type="tel"
            name="list-title"
            id="list-title"
            placeholder="전화번호"
            value={reservInfo.tel}
            onChange={(e) => {
              onChangeTel(e);
            }}
            ref={telInput}
          />
          {regList.tel === false ? (
            <p
              style={{
                marginTop: "-18px",
                color: "#ef5797",
              }}
            >
              입력하신 전화번호가 올바르지 않습니다
              <br />
              -을 제외한 숫자만 입력해주세요
            </p>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-1">
          <label className="add-listing__label" for="list-title">
            메모 :{" "}
          </label>
        </div>
        <div className="col-md-11">
          <textarea
            className="contact-form__textarea"
            name="comment"
            id=""
            placeholder="메세지를 입력하세요..."
            value={reservInfo.content}
            onChange={(e) => {
              onChangeContent(e);
            }}
          ></textarea>
        </div>
        <div className="col-md-12">
          <input
            className="contact-form__submit btn-float-right"
            type="button"
            name="submit-contact"
            id="submit_contact"
            value="등록하기"
            onClick={onSubmitForm}
          />
        </div>
      </div>
    </div>
  );
};

export default ReservForm;
