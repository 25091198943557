import React from "react";

import BoardListItem from "./BoardListItem";

const BoardList = ({ boardList, now, newWindow }) => {
  let i = -100;
  return (
    <div className="board-list">
      <ul>
        {boardList.map((boardListItem) => (
          <BoardListItem
            boardListItem={boardListItem}
            key={boardListItem.id + i--}
            now={now}
            newWindow={newWindow}
          />
        ))}
      </ul>
    </div>
  );
};

export default BoardList;
