import axios from "axios";
import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { useHistory } from "react-router-dom";

const AppleLoginBtn = () => {
  const history = useHistory();

  const signInOnSuccess = async (res) => {
    console.log(res);
    //     {
    //       "authorization": {
    //         "state": "[STATE]",
    //         "code": "[CODE]",
    //         "id_token": "[ID_TOKEN]"
    //       },
    //       "user": {
    //         "email": "[EMAIL]",
    //         "name": {
    //           "firstName": "[FIRST_NAME]",
    //           "lastName": "[LAST_NAME]"
    //         }
    //       }
    //    }

    // let response = {
    //   "authorization":{
    //     "code": "c2b8d3c778ae44b679b4edcc5f34343b9.0.rrxus.Dgae0ElwPMsRK0dKdbyRmw",
    //     "id_token": "eyJraWQiOiJZdXlYb1kiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoia3IuY28ua3NicGFydG5lcnMuc2VvY2hvLmN1bHR1cmUiLCJleHAiOjE2NjEzMDM4MzEsImlhdCI6MTY2MTIxNzQzMSwic3ViIjoiMDAxNzQyLmMwNjI0MDY0ZjI5NzQzYjBiODY2MmFmM2Q4ZGNhM2JiLjAxMTciLCJjX2hhc2giOiI3WVRmRjZZYkd0MUtmZ01BaGxLWU1BIiwiZW1haWwiOiJqajk5OTAwMDBAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOiJ0cnVlIiwiYXV0aF90aW1lIjoxNjYxMjE3NDMxLCJub25jZV9zdXBwb3J0ZWQiOnRydWV9.KaJvrFnhg1x6zTdJfPagA5xVdzxfURpbOBLUc_5jPCpFmpcyA8xeFn0xnqxaBFNiFiKUS1fAUMuyoZ1bOlL4Kh40fJV9ahbcBKqQRODMsPUe5L1RGChuv5zpM0pABnK6qDu605xPW3KKsu_DWdh4ImXRaeJSm5SN_KvueL48bXxOuSGvag3pcGlpxzO4mLsZwtXrtT0k2sXxT47U9Gzpt-s7yR13KUnwwqppnSgdtNcMPuNcsLsvbs_zU90-lneX5JC-fC2DnaMdL4m_oTqqtTTRPyjP11KkRIKgeBqS6nq3arqzrsA-RHAYbzOQjmtSMZDfhrhBUVBqV82CEPcbwQ",
    //     "state": "seochocultureapp",
    //   },
    //   "user":{
    //     "email": "jj9990000@gmail.com",
    //     "name":{
    //       "firstName": "주형",
    //       "lastName": "정",
    //     }
    //   }
    // };

    let data = {
      state: res.authorization.state,
      code: res.authorization.code,
      id_token: res.authorization.id_token,
      userEmail: Object.keys(res).includes("user")
        ? Object.keys(res.user).includes("email")
          ? res.user.email
          : null
        : null,
      userFirstName: Object.keys(res).includes("user")
        ? Object.keys(res.user).includes("name")
          ? res.user.name.firstName
          : null
        : null,
      userLastName: Object.keys(res).includes("user")
        ? Object.keys(res.user).includes("name")
          ? res.user.name.lastName
          : null
        : null,
    };

    login(data);
  };

  const login = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/sns/apple/login`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 200) {
        const { token, userid } = res.headers;

        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("userid", userid);

        // 로그인 인포
        window.sessionStorage.setItem("isFirstVisit", "2");

        history.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const signInOnFailure = (err) => {
    console.log(err);
  };

  // useEffect(() => {
  // const params = {
  //   clientId: "seochocultureapp",
  //   redirectURI: "https://culture.seocho.go.kr/common/log-in",
  //   // redirectURI: "http://localhost:4000/common/log-in",
  //   scope: "name email code id_token",
  //   state: "seochocultureapp",
  //   usePopup: true,
  // };

  // window.AppleID.auth.init();

  //   document.addEventListener("AppleIDSignInOnSuccess", signInOnSuccess);
  //   document.addEventListener("AppleIDSignInOnFailure", signInOnFailure);
  // });

  return (
    // <div
    //   id="appleid-signin"
    //   data-color="black"
    //   data-border="true"
    //   data-type="sign in"
    //   style={{ position: "absolute", top: "-10000px" }}
    // ></div>

    <AppleSignin
      authOptions={{
        clientId: "kr.co.ksbpartners.seocho.culture",
        scope: "name email",
        redirectURI: "https://culture.seocho.go.kr/common/log-in",
        state: "seochocultureapp",
        usePopup: true,
      }}
      uiType="dark"
      onSuccess={signInOnSuccess}
      onError={signInOnFailure}
      className="naver"
      render={(props) => (
        <a
          style={{
            color: "Black",
            fontWeight: "600",
            cursor: "pointer",
          }}
          className="naver"
          {...props}
        >
          <i className="fa fa-apple"></i>
        </a>
      )}
    />
  );
};

export default AppleLoginBtn;
