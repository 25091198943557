import React from "react";
import { Link } from "react-router-dom";

const MoreList = ({ moreNum, link }) => {
  return (
    <div className="center-button">
      <Link className="text-btn" to={link}>
        전체보기 <span>({moreNum})</span>
      </Link>
    </div>
  );
};

export default MoreList;
