import React, { useState, useEffect, useRef } from "react";

let searchBox;

const SearchLoactionBar = ({ searchByLocation }) => {
  const [searchWord, setSearchWord] = useState("");
  const [location, setLocation] = useState({
    lat: 0.0,
    lng: 0.0,
  });
  const searchBoxRef = useRef();

  const handleScriptLoad = () => {
    searchBox = new window.google.maps.places.SearchBox(searchBoxRef.current, {
      componentRestrictions: { country: "ko" },
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      setSearchWord(places[0].name);
      setLocation({
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      });
    });
  };

  const onSubmitKeyword = () => {
    searchByLocation(location);
  };

  useEffect(() => {
    handleScriptLoad(setSearchWord, searchBoxRef);
  }, []);

  return (
    <div className="search-form btn-float-right">
      <div className="search-form__input-holders">
        <input
          className="search-form__input"
          type="text"
          name="search-event"
          placeholder="위치 검색..."
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          ref={searchBoxRef}
        />
      </div>
      <button
        className="btn-round-lg__pink"
        type="button"
        onClick={onSubmitKeyword}
      >
        위치 검색{" "}
      </button>
    </div>
  );
};

export default SearchLoactionBar;
