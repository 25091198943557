import React from "react";
import { Link } from "react-router-dom";

const Footer = React.memo(() => {
  return (
    <footer className="footer footer-black">
      <div className="container">
        <div className="footer__up-part ">
          <Link to="/main">
            <img
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="seocho_culture-logo"
              style={{
                width: "60px",
                height: "40px",
              }}
            />
          </Link>
          <Link to="/common/privacy">개인정보 처리</Link>
          <Link to="/common/userguide">사용자 가이드</Link>
        </div>
        <div className="footer__down-part footer__down-part-black">
          <div className="row">
            <div className="col-md-7">
              <p className="footer__copyright">
                서초 문화포털
                <br />
                (06750) 서울특별시 서초구 남부순환로 2584 (서초동)
                <br />© COPYRIGHTS@ SEOCHO DISTRICT, ALL RIGHT RESERVED
              </p>
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
