import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import ReRegistorPostButton from "../common/Button/ReRegistorPostButton";
import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PlaceFormTemplate from "../place/place-template/PlaceFormTemplate";
import Editor from "../editor-video-template/Editor";
import RelatedVideoContainer from "../editor-video-template/RelatedVideoContainer";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const DismissalPlaceDetailView = ({ match }) => {
  const { state } = useContext(MobileContext);

  const history = useHistory();

  const [rejectionReason, setRejectionReason] = useState({
    name: "",
    text: "",
  });

  const [formInfo, setFormInfo] = useState({
    imgFile: null,
    title: "",
    category: "default",
    address: "",
    detailAddress: "",
    time: {
      open: "default",
      close: "default",
    },
    homePage: "",
    tel: "",
    dayOff: "",
  });

  const getFormInfo = (dataName, data) => {
    if (dataName === "open" || dataName === "close") {
      setFormInfo({
        ...formInfo,
        time: {
          ...formInfo.time,
          [dataName]: data,
        },
      });
    } else {
      setFormInfo({
        ...formInfo,
        [dataName]: data,
      });
    }
  };

  const [detail, setDetail] = useState("");
  const getDetail = useCallback((text) => {
    setDetail(text);
  }, []);

  const [videoAry, setVideoAry] = useState(null);
  const onChangeUrls = (videoAry) => {
    setVideoAry(videoAry);
  };

  const onSubmitEvent = () => {
    console.log("등록 요청");

    const formData = new FormData();

    if (formInfo.imgFile) {
      formData.append("file", formInfo.imgFile[0]);
    }

    formData.append("id", formInfo.id);
    formData.append("name", formInfo.title);
    formData.append("location", formInfo.title);

    formData.append("space_type", formInfo.category);

    formData.append("address1", formInfo.address);
    formData.append("address2", formInfo.detailAddress);

    formData.append("open_time", formInfo.time.open);
    formData.append("close_time", formInfo.time.close);

    formData.append("homepage", formInfo.homePage);

    formData.append("phone", formInfo.tel);

    formData.append("holiday", formInfo.dayOff);

    formData.append("more_information", detail);

    formData.append("state", "WAIT");

    let ary = new Array();
    let temp;
    for (let i = 0; i < videoAry.length; i++) {
      temp = new Object();
      temp.url = videoAry[i].vId;
      ary.push(temp);
    }
    formData.append("videos", JSON.stringify(ary));

    formData.append("userid", window.sessionStorage.getItem("userid"));

    requestPostPlace(formData);
  };

  const requestPostPlace = async (formData) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/regist`;

    try {
      const res = await axios.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        if (res.data.id === "") {
          alert("저장 도중 오류가 발생하였습니다. ");
        } else {
          alert("재등록 신청이 완료되었습니다.");
          history.push("/my-page/user-cont-refused");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteDismissalPlace = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/${formInfo.id}`;
    // const url = `/api/admin/cultural-event/${formInfo.id}`;
    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        history.push("/my-page/user-cont-refused");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const { postId } = match.params;

    const getPlaceDetail = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/detail/${postId}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setFormInfo({
            id: res.data.id,
            like: false,
            imgFile: null,
            imgSrc: getImage(res.data.resources),
            category: res.data.space_type,
            title: res.data.name,
            address: res.data.address1,
            detailAddress: res.data.address2,
            time: {
              open: res.data.open_time,
              close: res.data.close_time,
            },
            homePage: res.data.homepage,
            tel: res.data.phone,
            dayOff: res.data.holiday,
          });

          setRejectionReason({
            name: res.data.rejection_reason_name,
            text: res.data.rejection_reason_text,
          });

          setDetail(res.data.more_information);

          let ary = [];
          if (Object.keys(res.data.resources).includes("videos")) {
            let id = 1000;

            for (let i = 0; i < res.data.resources.videos.length; i++) {
              ary.push({
                id: id++,
                vId: res.data.resources.videos[i].url,
              });
            }
          }
          setVideoAry(ary);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getPlaceDetail();
  }, [history, match.params]);

  if (formInfo === null || videoAry === null) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-sm-9">
              <h1 class="page__title page__title-black">
                <span>문화행사 등록하기</span>
              </h1>
              <p className="listing-detail__subtitle">
                <Link to="/main">홈</Link> /{" "}
                <Link to="my-page/user-info">프로필</Link> /{" "}
                <Link to="/my-page/user-cont-refused">
                  내가 등록신청한 게시물
                </Link>{" "}
                / <span className="active">기각된 게시물</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="page-top" style={{ textAlign: "center" }}>
        <div
          className="user-stay"
          style={{
            backgroundColor: "grey",
            textAlign: "center",
            padding: "5px",
          }}
        >
          기각
        </div>
        기각사유 : 해당 게시물은 {rejectionReason.name}으로 등록 요청이 기각
        되었습니다
        <br />
        {`(상세 : ${rejectionReason.text})`}
      </div>

      <section class="listing-detail add-listing">
        <div class="listing-detail__content">
          <div class="container">
            <div class="container">
              <PlaceFormTemplate
                getFormInfo={getFormInfo}
                formInfo={formInfo}
              />

              <div class="row">
                <div class="col-lg-12">
                  <div class="listing-detail__content-box listing-detail__content-box-nopadding">
                    <Editor
                      editorTitle="공간 정보"
                      getDetail={getDetail}
                      initDetail={detail}
                    />
                    <RelatedVideoContainer
                      onChangeUrls={onChangeUrls}
                      initVideoInfos={videoAry}
                    />

                    <ReRegistorPostButton
                      onSubmitEvent={onSubmitEvent}
                      deleteDismissal={deleteDismissalPlace}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default DismissalPlaceDetailView;
