import React from "react";
import RelatedVideoListItem from "./RelatedVideoListItem";

import Grid from "@mui/material/Grid";

const RelatedVideoList = ({ videoAry, isEdit, deleteVideo }) => {
  return (
    <div className="listing-detail__video-inner iso-call">
      <Grid container spacing={2}>
        {videoAry.map((videoInfo) => (
          <Grid item lg={4} md={6} sm={6} xs={12} key={videoInfo.id}>
            <RelatedVideoListItem
              videoInfo={videoInfo}
              isEdit={isEdit}
              deleteVideo={deleteVideo}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RelatedVideoList;
