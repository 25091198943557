import React, { useState, useEffect } from "react";

const TapMenu = ({ menuList, secondMenu, onSelectSecondMenu }) => {
  const [menu, setMenu] = useState(secondMenu);
  const selectSecondMenu = (selected) => {
    setMenu(selected);
    onSelectSecondMenu(selected);
  };

  useEffect(() => {
    setMenu(secondMenu);
  }, [secondMenu]);

  return (
    <nav>
      <div className="nav nav-tabs" id="nav-tab">
        {menuList.map((menuItem) => (
          <a
            className={
              menu === menuItem.name
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              selectSecondMenu(menuItem.name);
            }}
            style={{
              cursor: "pointer",
              color: menu === menuItem.name ? "black" : "#ccc",
            }}
            key={menuItem.name}
          >
            {menuItem.text}
          </a>
        ))}
      </div>
    </nav>
  );
};

export default TapMenu;
