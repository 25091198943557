import React, { memo, useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import MenuContext from "context/menu";
// import axios from "axios";

const Header = memo(() => {
  const history = useHistory();
  const { actions } = useContext(MenuContext);

  const onChangeMenu = (selectedCommunityMenu) => {
    actions.setCommunityMenu(selectedCommunityMenu);
  };

  const [userid, setUserid] = useState();

  const onClickLogoutBtn = () => {
    alert("로그아웃 되었습니다.");
    window.sessionStorage.removeItem("userid");
    window.sessionStorage.removeItem("token");

    setUserid(null);
    history.push("/");
  };

  const [menu, setMenu] = useState();
  const [showMenu, setShowMenu] = useState(true);

  const onSelectMenu = (selectedMenu) => {
    if (selectedMenu === menu) {
      setShowMenu(!showMenu);
    } else {
      setMenu(selectedMenu);
      setShowMenu(true);
    }
  };

  // const onClickLogoutBtn = async () => {
  //   const url = "https://culture.seocho.go.kr:8443/user-service/logout";

  //   try {
  //     const res = await axios.get(url, {
  //       params: {
  //         userId: userid,
  //       },
  //     });

  //     if (res.status === 200) {
  //       alert("로그아웃 되었습니다.");

  //       window.sessionStorage.removeItem("userid");
  //       window.sessionStorage.removeItem("token");

  //       setUserid(null);
  //       history.push("/");
  //     }
  //   } catch (e) {
  //     alert("로그아웃에 실패하였습니다.");
  //     console.log(e);
  //   }
  // };
  useEffect(() => {
    setUserid(window.sessionStorage.getItem("userid"));
  }, []);

  return (
    <header className="clearfix pink-header-style">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
        <div className="container">
          <Link className="navbar-brand" to="/main">
            <img
              src="../../images/logo@white.png"
              alt=""
              width="80px"
              height="48px"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li>
                <Link className="navbar-nav-mn" to="/main">
                  문화행사{" "}
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
                <Link className="navbar-nav-mn" to="/main">
                  서초 문화소식{" "}
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
                <Link className="navbar-nav-mn" to="/main">
                  문화공간{" "}
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
                <Link className="navbar-nav-mn" to="/main">
                  서리풀 악기거리{" "}
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
                {/* 문화 파트너스 숨김  
                <Link className="navbar-nav-mn" to="/main">
                  문화 파트너스{" "}
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
                 */}
                <ul className="dropdown">
                  <li>
                    <ul className="dropdown-list">
                      <li>
                        <span>문화행사</span>
                      </li>
                      <li>
                        <Link to="/event/event-all">문화행사 검색</Link>
                      </li>
                      <li>
                        <Link to="/event/concert">공연</Link>
                      </li>
                      <li>
                        <Link to="/event/exhibit">전시</Link>
                      </li>
                      <li>
                        <Link to="/event/others">기타</Link>
                      </li>
                      <li>
                        <Link to="/event/sc-event">서초구 축제</Link>
                      </li>
                    </ul>

                    <ul className="dropdown-list">
                      <li>
                        <span>서초 문화소식</span>
                      </li>
                      <li>
                        <Link
                          to="/community/press-release"
                          onClick={() => onChangeMenu(3)}
                        >
                          서초 문화소식
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/community/event-board"
                          onClick={() => onChangeMenu(1)}
                        >
                          이벤트
                        </Link>
                      </li> 
                      <li>
                        <Link
                          to="/community/news-board"
                          onClick={() => onChangeMenu(2)}
                        >
                          문화계소식
                        </Link>
                      </li>*/}
                    </ul>
                    <ul className="dropdown-list">
                      <li>
                        <span>문화공간</span>
                      </li>
                      <li>
                        <Link to="/place/place-all">전체보기</Link>
                      </li>
                      <li>
                        <Link to="/place/concert-hall">공연장</Link>
                      </li>
                      <li>
                        <Link to="/place/practice-room">연습실</Link>
                      </li>
                      <li>
                        <Link to="/place/instrument-store">악기상점</Link>
                      </li>
                      <li>
                        <Link to="/place/gallery">미술관/갤러리</Link>
                      </li>
                      <li>
                        <Link to="/place/sc-place/center">
                          서리풀 청년아트 센터
                        </Link>
                      </li>
                      <li>
                        <Link to="/place/sc-place/gallery">
                          서리풀 청년아트 갤러리
                        </Link>
                      </li>
                    </ul>
                    <ul className="dropdown-list">
                      <li>
                        <span>서리풀 악기거리</span>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            window.open(
                              "https://www.youtube.com/channel/UC729XaJOib3wjotPiWFtY_g",
                              "_blank"
                            )
                          }
                        >
                          영상
                        </a>
                      </li>
                      <li>
                        <Link to="/seoripul-akgigeori/introduce">소개</Link>
                      </li>
                    </ul>
                    {/*
                    <ul className="dropdown-list">
                      <li>
                        <span>커뮤니티</span>
                      </li>
                      <li>
                        <Link
                          to="/community/notice-board"
                          onClick={() => onChangeMenu(0)}
                        >
                          공지사항
                        </Link>
                      </li>
                       <li>
                        <Link
                          to="/community/event-board"
                          onClick={() => onChangeMenu(1)}
                        >
                          이벤트
                        </Link>
                      </li> 
                      <li>
                        <Link
                          to="/community/news-board"
                          onClick={() => onChangeMenu(2)}
                        >
                          문화계소식
                        </Link>
                      </li>
                    </ul>  */}


                    {/* 문화 파트너스 숨김  
                    <ul className="dropdown-list">
                      <li>
                        <span>문화 파트너스</span>
                      </li>
                      <li>
                        <Link to="/community/related-sites">문화 파트너스</Link>
                      </li>
                    </ul>*/}
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto right-list">
              {userid !== undefined && userid !== null ? (
                <>
                  <li
                    className="line-h-100"
                    onClick={() => actions.setMyPageMenu(0)}
                  >
                    <Link to="/my-page/user-info" className="user-names">
                      <i className="fa fa-user-o" aria-hidden="true"></i>{" "}
                      <span className="color-pink">
                        <span className="web-hidden">&#124;&nbsp;</span>
                        {userid}{" "}
                      </span>
                      <span className="font-light web-hidden"> 님, 프로필</span>
                    </Link>
                  </li>
                  <li className="line-h-100">
                    <a onClick={() => onClickLogoutBtn()}>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      ></i>{" "}
                      로그아웃
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="line-h-100">
                    <Link
                      to="/common/join"
                      onClick={() => actions.setLoginMenu(1)}
                    >
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      ></i>
                      회원가입
                    </Link>
                  </li>
                  <li className="line-h-100">
                    <Link
                      to="/common/log-in"
                      onClick={() => actions.setLoginMenu(0)}
                    >
                      <i className="fa fa-user-o" aria-hidden="true"></i> 로그인
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <div className="dropdown_menu">
              <button onClick={() => onSelectMenu(0)} className="dropdown_btn">
                문화행사
              </button>
              <div
                id="mydropdown_menu"
                className={
                  menu === 0 && showMenu
                    ? "dropdown_menu-content show"
                    : "dropdown_menu-content"
                }
              >
                <Link to="/event/event-all">문화행사 검색</Link>
                <Link to="/event/concert">공연</Link>
                <Link to="/event/exhibit">전시</Link>
                <Link to="/event/others">기타</Link>
                <Link to="/event/sc-event">서초구 축제</Link>
              </div>
              <button onClick={() => onSelectMenu(3)} className="dropdown_btn">
                서초 문화소식
              </button>
              <div
                id="mydropdown_menu4"
                className={
                  menu === 3 && showMenu
                    ? "dropdown_menu-content show"
                    : "dropdown_menu-content"
                }
              >
                <Link
                  to="/community/press-release"
                  onClick={() => onChangeMenu(3)}
                >
                  서초 문화소식
                </Link>
                {/* <Link
                  to="/community/event-board"
                  onClick={() => onChangeMenu(1)}
                >
                  이벤트
                </Link> 
                <Link
                  to="/community/news-board"
                  onClick={() => onChangeMenu(2)}
                >
                  문화계소식
                </Link>*/}
              </div>  


              <button onClick={() => onSelectMenu(1)} className="dropdown_btn">
                문화공간
              </button>
              <div
                id="mydropdown_menu2"
                className={
                  menu === 1 && showMenu
                    ? "dropdown_menu-content show"
                    : "dropdown_menu-content"
                }
              >
                <Link to="/place/place-all">전체보기</Link>
                <Link to="/place/concert-hall">공연장</Link>
                <Link to="/place/practice-room">연습실</Link>
                <Link to="/place/instrument-store">악기상점</Link>
                <Link to="/place/gallery">미술관/갤러리</Link>
                <Link to="/place/sc-place/center">서리풀 청년아트 센터</Link>
                <Link to="/place/sc-place/gallery">서리풀 청년아트 갤러리</Link>
              </div>
              <button onClick={() => onSelectMenu(2)} className="dropdown_btn">
                서리풀 악기거리
              </button>
              <div
                id="mydropdown_menu3"
                className={
                  menu === 2 && showMenu
                    ? "dropdown_menu-content show"
                    : "dropdown_menu-content"
                }
              >
                <a
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC729XaJOib3wjotPiWFtY_g",
                      "_blank"
                    )
                  }
                >
                  영상
                </a>
                <Link to="/seoripul-akgigeori/introduce">소개</Link>
              </div>

              {/* 문화 파트너스 숨김
              <button onClick={() => onSelectMenu(4)} className="dropdown_btn">
                문화 파트너스
              </button>
              */}
              <div
                id="mydropdown_menu5"
                className={
                  menu === 4 && showMenu
                    ? "dropdown_menu-content show"
                    : "dropdown_menu-content"
                }
              >
                <Link to="/community/related-sites">문화 파트너스</Link>
              </div>

              {userid !== undefined && userid !== null ? (
                <>
                  <button className="dropdown_btn">
                    <Link to="/my-page/user-info">
                      <i className="fa fa-user-o" aria-hidden="true"></i>{" "}
                      {userid}
                    </Link>
                  </button>
                  <button
                    className="dropdown_btn"
                    onClick={() => onClickLogoutBtn()}
                  >
                    <i
                      className="fa fa-arrow-circle-o-right"
                      aria-hidden="true"
                    ></i>
                    로그아웃
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="dropdown_btn"
                    onClick={() => actions.setLoginMenu(0)}
                  >
                    <Link to="/common/log-in">
                      <i className="fa fa-user-o" aria-hidden="true"></i> 로그인
                    </Link>
                  </button>
                  <button
                    className="dropdown_btn"
                    onClick={() => actions.setLoginMenu(1)}
                  >
                    <Link to="/common/join">
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      ></i>
                      회원가입
                    </Link>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
});

export default memo(Header);
