import React from "react";
import YouTube from "react-youtube";

const VideoListItem = ({ videoInfo }) => {
  return (
    <div className="item">
      <div className="sc-video">
        <YouTube
          videoId={videoInfo.vId}
          opts={{ width: "560px", height: "315px" }}
        />
      </div>
    </div>
  );
};

export default VideoListItem;
