import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import EventListItem from "components/event/event-template/EventListItem";

const EventList = ({ eventList }) => {
  const showMaxCnt = 5;

  const setting = {
    dots: false,
    infinite: eventList.length > showMaxCnt,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...setting} className="section-culture-event__box">
      {eventList.map((eventInfo) => (
        <div className="items" key={eventInfo.id}>
          <div className="item">
            <EventListItem eventInfo={eventInfo} />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default EventList;
