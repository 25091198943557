import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import ReservListItem from "components/ect/detail-page-template/ReservListItem";

const MyPlaceReservListItem = ({ placeReserv, deletePlaceReserv }) => {
  const { placeInfo, reservInfo } = placeReserv;

  const getLabel = () => {
    switch (placeInfo.category) {
      case "HALL":
        return (
          <span className="place-post__date place-post__hall">공연장</span>
        );
      case "PRACTICE":
        return (
          <span className="place-post__date place-post__practice">연습실</span>
        );
      case "INSTRUMENT":
        return (
          <span className="place-post__date place-post__instrument">
            악기상점
          </span>
        );
      case "GALLERY":
        return (
          <span className="place-post__date place-post__gallery">
            미술관/갤러리
          </span>
        );
      default:
        return "";
    }
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <div className="explore__wrap iso-call">
      <div className="item col-lg-4">
        <div className="place-post">
          <div className="place-post__gal">
            <Link to={`/place/place-detail/${placeInfo.id}`}>
              <img src={getImgSrc(placeInfo.imgSrc)} alt="문화공간 포스터" />
            </Link>
            {getLabel()}
          </div>
        </div>
      </div>
      <div className="item col-lg-8">
        <div className="place-post">
          <h2 className="post_title1">
            <Link to={`/place/place-detail/${placeInfo.id}`}>
              {placeInfo.title}
            </Link>
          </h2>
          <p className="place-post__description">{placeInfo.address}</p>
        </div>
        <hr />
        <div className="listing-detail__reviews-box   reviews-list__item-content-mine">
          <ReservListItem
            reservInfo={reservInfo}
            deleteReserv={deletePlaceReserv}
          />
        </div>
      </div>
    </div>
  );
};

export default MyPlaceReservListItem;
