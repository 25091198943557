import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BannerListItem from "./banner-template/BannerListItem";

const MainBanner = ({ banner }) => {
  const getImgSrc = (banner) => {
    if (banner) {
      return `${process.env.REACT_APP_COMMUNITY_SERVICE}/main/${banner}`;
    } else {
      return "../upload/slide5@2x2.jpg";
    }
  };

  const [recommendationEventList, setRecommendationEventList] = useState([]);

  const setting = {
    dots: true,
    infinite: recommendationEventList.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const getRecommendationEventList = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/main/list/recommendations`;

      try {
        const res = await axios.get(url, {
          params: {
            type: "cultural_event",
          },
        });

        if (res.status === 200) {
          setRecommendationEventList(res.data.list);
        }
      } catch (e) {}
    };

    // getRecommendationPlaceList();
    getRecommendationEventList();
  }, []);

  return (
    <section
      className="discover discover-best"
      style={{ backgroundImage: `url(${getImgSrc(banner)})` }}
    >
      <div className="container">
        <Slider {...setting} className="discover__box-place">
          {recommendationEventList.map((listItem) => (
            <div className="itmes" key={listItem.content_id}>
              <BannerListItem listItem={listItem} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default MainBanner;
