import React, { useState, useEffect } from "react";

const style = {
  cursor: "pointer",
};

const calcLastPage = (itemNum, onePage) => {
  if (itemNum % onePage === 0) {
    return parseInt(itemNum / onePage);
  } else {
    return parseInt(itemNum / onePage) + 1;
  }
};

const Paging = ({ pageNum, getCurPage, onePage, all }) => {
  // 한 페이지에 들어가는 게시물 수
  // 마지막 페이지

  const [curPage, setCurPage] = useState(pageNum);
  const [page, setPage] = useState({
    start: 1,
    end: 1,
  });
  const [lastpage, setLastPage] = useState(1);

  const onClickPageNumber = (e) => {
    setCurPage(parseInt(e.target.innerText));
    getCurPage(parseInt(e.target.innerText));
  };

  const paging = () => {
    let pageAry = [];

    for (let i = page.start; i <= page.end; i++) {
      pageAry.push(
        <a
          className={curPage === i ? "on" : ""}
          style={style}
          onClick={(e) => {
            onClickPageNumber(e);
          }}
          key={i}
        >
          {i}
        </a>
      );
    }

    return pageAry;
  };

  const goFirstPage = () => {
    if (curPage === 1) return;

    setCurPage(1);
    getCurPage(1);
    setPage({
      start: 1,
      end: lastpage > 4 ? 5 : lastpage,
    });
  };

  const goPrevPage = () => {
    if (curPage === 1) return;

    setCurPage(curPage - 1);
    getCurPage(curPage - 1);

    if (page.start === curPage) {
      setPage({
        start: curPage - 5,
        end: curPage - 1,
      });
    }
  };

  const goNextPage = () => {
    if (curPage === lastpage) {
      return;
    }

    getCurPage(curPage + 1);
    setCurPage(curPage + 1);

    // 페이징 범위 바뀌어야 함
    if (page.end === curPage) {
      setPage({
        start: curPage + 1,
        end: curPage + 5 < lastpage ? curPage + 5 : lastpage,
      });
    }
  };

  const goLastPage = () => {
    if (curPage === lastpage) {
      return;
    }

    setCurPage(lastpage);
    getCurPage(lastpage);

    if (lastpage % 5 === 0) {
      setPage({
        start: lastpage - 4,
        end: lastpage,
      });
    } else {
      setPage({
        start: lastpage > 5 ? lastpage - (lastpage % 5) + 1 : 1,
        end: lastpage,
      });
    }
  };

  useEffect(() => {
    setCurPage(curPage);

    const lastpage = calcLastPage(all, onePage);

    setLastPage(lastpage);
    setPage({
      start: 1,
      end: lastpage > 4 ? 5 : lastpage,
    });
  }, [all, curPage, onePage]);

  return (
    <div className="page">
      <a className="prenext" alt="첫페이지" onClick={goFirstPage} style={style}>
        &#60;&#60;
      </a>
      <a className="prenext" alt="이전" onClick={goPrevPage} style={style}>
        &#60;
      </a>
      {paging()}
      <a className="prenext" alt="다음" onClick={goNextPage} style={style}>
        &#62;
      </a>
      <a className="prenext" alt="끝페이지" onClick={goLastPage} style={style}>
        &#62;&#62;
      </a>
    </div>
  );
};

export default Paging;
