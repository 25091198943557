import React from "react";

const ToggleBtn = ({ btnId, status, onToggle }) => {
  const onHandleClick = () => {
    onToggle(btnId, !status);
  };

  return (
    <div className="btn-switch" style={{ float: "right" }} id={btnId}>
      {status ? <p>알림 받기</p> : <p>알림 끄기</p>}

      <label className="switch">
        <input type="checkbox" checked={status} />
        <span className="slider round" onClick={onHandleClick}></span>
      </label>
    </div>
  );
};

export default ToggleBtn;
