import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import SearchResultList from "./searchTemplate/SearchResultList";
import EventList from "../event/event-template/EventList";
import PlaceList from "../place/place-template/PlaceList";
import SearchBar from "./searchTemplate/SearchBar";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const count = 8;

const SearchAllView = () => {
  const { state } = useContext(MobileContext);

  const [curPageEvent, setCurPageEvent] = useState(1);
  const [curPageFestival, setCurPageFestival] = useState(1);
  const [curPagePlace, setCurPagePlace] = useState(1);

  const getCurPageEvent = (pageNum) => {
    setCurPageEvent(pageNum);
  };

  const getcurPageFestival = (pageNum) => {
    console.log(pageNum);
    setCurPageFestival(pageNum);
  };
  const getCurPagePlace = (pageNum) => {
    setCurPagePlace(pageNum);
  };

  const [searchKeyword, setSearchKeyword] = useState("");

  const search = (keyword) => {
    setSearchKeyword(keyword);

    setCurPageEvent(1);
    setCurPageFestival(1);
    setCurPagePlace(1);
  };

  const searchKeywordEvent = useCallback(
    async (loginId) => {
      const searchEventUrl = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/list`;

      if (searchKeyword === "") {
        try {
          const res = await axios.get(searchEventUrl, {
            params: {
              page: curPageEvent,
              count: count,
              search_type: "STATE",
              search_word: "POST",
              loginid: loginId,
            },
          });

          if (res.status === 200) {
            setTotalEventCount(res.data.total_count);

            let ary = [];

            for (let i = 0; i < res.data.list.length; i++) {
              ary.push({
                id: res.data.list[i].id,
                imgSrc: getImage(res.data.list[i].resources),
                category: res.data.list[i].event_type,
                title: res.data.list[i].name,
                placeName: res.data.list[i].location,
                like: res.data.list[i].is_like === 1 ? true : false,
                address: res.data.list[i].address1 + res.data.list[i].address2,
                period: {
                  start: res.data.list[i].open_date,
                  end: res.data.list[i].close_date,
                },
              });
            }

            setSearchResultsEvent(ary);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          axios
            .all([
              axios.get(searchEventUrl, {
                params: {
                  page: curPageEvent,
                  count: count,
                  search_type: "EVENT_NAME",
                  search_word: searchKeyword,
                  loginid: loginId,
                },
              }),
              axios.get(searchEventUrl, {
                params: {
                  page: curPageEvent,
                  count: count,
                  search_type: "LOCATION",
                  search_word: searchKeyword,
                  loginid: loginId,
                },
              }),
            ])
            .then(
              axios.spread((resEventName, resLocation) => {
                let ary = [];
                let sum = 0;

                for (let i = 0; i < resEventName.data.list.length; i++) {
                  if (resEventName.data.list[i].state === "POST") {
                    ary.push({
                      id: resEventName.data.list[i].id,
                      imgSrc: getImage(resEventName.data.list[i].resources),
                      category: resEventName.data.list[i].event_type,
                      title: resEventName.data.list[i].name,
                      placeName: resEventName.data.list[i].location,
                      like:
                        resEventName.data.list[i].is_like === 1 ? true : false,
                      address:
                        resEventName.data.list[i].address1 +
                        resEventName.data.list[i].address2,
                      period: {
                        start: resEventName.data.list[i].open_date,
                        end: resEventName.data.list[i].close_date,
                      },
                    });
                    sum++;
                  }
                }

                for (let i = 0; i < resLocation.data.list.length; i++) {
                  if (resLocation.data.list[i].state === "POST") {
                    ary.push({
                      id: resLocation.data.list[i].id,
                      imgSrc: getImage(resLocation.data.list[i].resources),
                      category: resLocation.data.list[i].event_type,
                      title: resLocation.data.list[i].name,
                      placeName: resLocation.data.list[i].location,
                      like:
                        resLocation.data.list[i].is_like === 1 ? true : false,
                      address:
                        resLocation.data.list[i].address1 +
                        resLocation.data.list[i].address2,
                      period: {
                        start: resLocation.data.list[i].open_date,
                        end: resLocation.data.list[i].close_date,
                      },
                    });
                    sum++;
                  }
                }

                setSearchResultsEvent(ary);
                // setTotalEventCount(sum);
                setTotalEventCount(
                  resEventName.data.total_count + resLocation.data.total_count
                );
              })
            );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [curPageEvent, searchKeyword]
  );

  const searchKeywordFestival = useCallback(async () => {
    const searchFestivalUrl = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/seochogu-festival/list`;

    try {
      let params;
      if (searchKeyword === "") {
        params = {
          page: curPageFestival,
          count: count,
          search_type: "STATE",
          search_word: "POST",
        };
      } else {
        params = {
          page: curPageFestival,
          count: count,
          search_type: "FESTIVAL_NAME",
          search_word: searchKeyword,
        };
      }

      const res = await axios.get(searchFestivalUrl, {
        params: params,
      });

      if (res.status === 200) {
        let ary = [];
        let sum = 0;

        for (let i = 0; i < res.data.list.length; i++) {
          if (res.data.list[i].state === "POST") {
            ary.push({
              id: res.data.list[i].id,
              imgSrc: getImage(res.data.list[i].resources),
              category: "festival",
              title: res.data.list[i].name,
              placeName: "",
              like: false,
              address: "",
              period: {
                start: res.data.list[i].open_date,
                end: res.data.list[i].close_date,
              },
            });
            sum++;
          }
        }
        // setTotalFestivalCount(sum);
        setTotalFestivalCount(res.data.total_count);
        setSearchResultsFestival(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPageFestival, searchKeyword]);

  const searchKeywordPlace = useCallback(async () => {
    const searchPlaceUrl = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list`;

    try {
      if (searchKeyword === "") {
        const res = await axios.get(searchPlaceUrl, {
          params: {
            page: curPagePlace,
            count: count,
            search_type: "STATE",
            search_word: "POST",
          },
        });

        if (res.status === 200) {
          let ary = [];

          setTotalPlaceCount(res.data.total_count);

          for (let i = 0; i < res.data.list.length; i++) {
            ary.push({
              id: res.data.list[i].id,
              imgSrc: getImage(res.data.list[i].resources),
              category: res.data.list[i].space_type,
              title: res.data.list[i].name,
              address:
                res.data.list[i].address1 + " " + res.data.list[i].address2,
              status: res.data.list[i].state,
            });
          }

          setSearchResultsPlace(ary);
        }
      } else {
        axios
          .all([
            axios.get(searchPlaceUrl, {
              params: {
                page: curPagePlace,
                count: count,
                search_type: "SPACE_NAME",
                search_word: searchKeyword,
              },
            }),
            axios.get(searchPlaceUrl, {
              params: {
                page: curPagePlace,
                count: count,
                search_type: "LOCATION",
                search_word: searchKeyword,
              },
            }),
          ])
          .then(
            axios.spread((resSpaceName, resLocation) => {
              let ary = [];
              let sum = 0;

              for (let i = 0; i < resSpaceName.data.list.length; i++) {
                if (resSpaceName.data.list[i].state === "POST") {
                  ary.push({
                    id: resSpaceName.data.list[i].id,
                    imgSrc: getImage(resSpaceName.data.list[i].resources),
                    category: resSpaceName.data.list[i].space_type,
                    title: resSpaceName.data.list[i].name,
                    address:
                      resSpaceName.data.list[i].address1 +
                      " " +
                      resSpaceName.data.list[i].address2,
                    status: resSpaceName.data.list[i].state,
                  });
                  sum++;
                }
              }

              for (let i = 0; i < resLocation.data.list.length; i++) {
                if (resLocation.data.list[i].state === "POST") {
                  ary.push({
                    id: resLocation.data.list[i].id,
                    imgSrc: getImage(resLocation.data.list[i].resources),
                    category: resLocation.data.list[i].space_type,
                    title: resLocation.data.list[i].name,
                    address:
                      resLocation.data.list[i].address1 +
                      " " +
                      resLocation.data.list[i].address2,
                    status: resLocation.data.list[i].state,
                  });
                  sum++;
                }
              }
              // setTotalPlaceCount(sum);
              setTotalPlaceCount(
                resLocation.data.total_count + resSpaceName.data.total_count
              );
              setSearchResultsPlace(ary);
            })
          );
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPagePlace, searchKeyword]);

  const [searchResultsEvent, setSearchResultsEvent] = useState([]);
  const [searchResultsFestival, setSearchResultsFestival] = useState([]);
  const [searchResultsPlace, setSearchResultsPlace] = useState([]);

  const [totalEventCount, setTotalEventCount] = useState();
  const [totalFestivalCount, setTotalFestivalCount] = useState();
  const [totalPlaceCount, setTotalPlaceCount] = useState();

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    searchKeywordEvent(loginId);
    searchKeywordFestival();
    searchKeywordPlace();

    return () => {
      document.body.removeChild(script);
    };
  }, [searchKeywordEvent, searchKeywordFestival, searchKeywordPlace]);

  if (searchResultsEvent === null || searchResultsPlace === null) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section className="page-title">
        <div className="container">
          <SearchBar search={search} />
          <br />
          <p></p>
          <ul className="single-post__list">
            <li className="single-post__list-item">
              <i className=""></i>
              <span></span>
            </li>
            <li className="single-post__list-item">
              <i className=""></i>
              <a></a>
            </li>
          </ul>
        </div>
        <br />
      </section>

      <section className="explore">
        <div className="container">
          <SearchResultList
            listTitle="문화행사 검색 결과"
            resultList={searchResultsEvent}
            ListComponent={EventList}
            curPage={curPageEvent}
            getCurPage={getCurPageEvent}
            onePage={count}
            totalCount={totalEventCount}
          />

          <SearchResultList
            listTitle="서초구 축제 검색 결과"
            resultList={searchResultsFestival}
            ListComponent={EventList}
            curPage={curPageFestival}
            getCurPage={getcurPageFestival}
            onePage={count}
            totalCount={totalFestivalCount}
            isFestival={true}
          />

          <SearchResultList
            listTitle="문화공간 검색 결과"
            resultList={searchResultsPlace}
            ListComponent={PlaceList}
            curPage={curPagePlace}
            getCurPage={getCurPagePlace}
            onePage={count}
            totalCount={totalPlaceCount}
          />
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default SearchAllView;
