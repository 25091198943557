import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Header from "../ect/Header";
import Footer from "../ect/Footer";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";

import PlaceListModal from "./PlaceListModal";
import MyPageMenu from "./MyPageMenu";
import MyPageTitle from "./my-page-template/MyPageTitle";
import InterestAreaFilter from "./my-page-template/InterestAreaFilter";
import FrequentlyVisitedPlaceList from "./my-page-template/FrequentlyVisitedPlaceList";
import ToggleBtn from "./my-page-template/ToggleBtn";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const addDash = (string) => {
  return string.slice(0, 4) + "-" + string.slice(4, 6) + "-" + string.slice(6);
};

const UserInfoView = () => {
  const { state } = useContext(MobileContext);

  const [userInfo, setUserInfo] = useState({
    id: "",
    name: "",
    tel: "",
    email: "",
    birth: "",
    address: "",
    detailAddress: "",
  });

  const history = useHistory();
  const [culturalEventAlarm, setCulturalEventAlarm] = useState({
    id: "CULTURAL_EVENT",
    state: true,
  });
  const [culturalPlaceAlarm, setCulturalPlaceAlarm] = useState({
    id: "CULTURAL_SPACE",
    state: true,
  });

  const onToggle = (btnId, state) => {
    let data = new Object();

    data.type = btnId;

    if (btnId === "CULTURAL_EVENT") {
      state ? (data.culturalEvent = 1) : (data.culturalEvent = 0);
    } else {
      state ? (data.culturalSpace = 1) : (data.culturalSpace = 0);
    }

    editAlarm(data);
  };

  const editAlarm = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE
      }/api/alarm/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        getAlarmStates();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAlarmStates = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE
      }/api/alarm/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.get(url);

      if (res.status === 200) {
        const { culturalEvent, culturalSpace } = res.data;

        setCulturalEventAlarm({
          id: "CULTURAL_EVENT",
          state: culturalEvent === 1 ? true : false,
        });
        setCulturalPlaceAlarm({
          id: "CULTURAL_SPACE",
          state: culturalSpace === 1 ? true : false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const [placeList, setPlaceList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const onToggleModal = () => {
    setShowModal(!showModal);
    getFavoritePlaceList();
  };

  const getImage = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  const getFavoritePlaceList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE
      }/api/favorite-space/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.get(url, {
        params: {
          page: 1,
          count: 100,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalRows(totalRows);
        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].id,
            imgSrc: getImage(data[i].images),
            category: data[i].space_type,
            title: data[i].name,
            address: data[i].address1 + " " + data[i].address2,
            status: data[i].state,
          });
        }
        setPlaceList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const deletePlace = async (rId) => {
    const url = `${process.env.REACT_APP_USER_SERVICE
      }/api/favorite-space/user/${window.sessionStorage.getItem(
        "userid"
      )}/${rId}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        getFavoritePlaceList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const getUserInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE
        }/api/admin/user/detail/${window.sessionStorage.getItem("userid")}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setUserInfo({
            id: res.data.id,
            name: res.data.name,
            tel: res.data.phone,
            email: res.data.email,
            birth: res.data.birthday ? addDash(res.data.birthday) : "",
            address: res.data.address1,
            detailAddress: res.data.address2,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getUserInfo();
    getFavoritePlaceList();
    getAlarmStates();

    return () => {
      document.body.removeChild(script);
    };
  }, [getAlarmStates, getFavoritePlaceList, history]);

  if (userInfo === null) {
    return null;
  }

  return showModal ? (
    <PlaceListModal onToggleModal={onToggleModal} disabledList={placeList} />
  ) : (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />

            <div className="col-lg-10">
              <MyPageTitle pageTitle="나의 정보 수정하기" />

              <div
                className="add-listing__form-box element-waypoint"
                id="general-info"
              >
                <div className="add-listing__form-content">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-id">
                        아이디{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <label className="add-listing__label" htmlFor="list-id">
                        {userInfo.id}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-name">
                        이름{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <label className="add-listing__label" htmlFor="list-name">
                        {userInfo.name}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-tel">
                        전화번호{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <label className="add-listing__label" htmlFor="list-tel">
                        {userInfo.tel}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-email"
                      >
                        이메일{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <label
                        className="add-listing__label"
                        htmlFor="list-email"
                      >
                        {userInfo.email}{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="add-listing__form-box element-waypoint"
                id="general-info"
              >
                <div className="add-listing__form-content">
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-birth"
                      >
                        생년월일{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <label
                        className="add-listing__label"
                        htmlFor="list-birth"
                      >
                        {userInfo.birth}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label
                        className="add-listing__label"
                        htmlFor="list-adress"
                      >
                        주소{" "}
                      </label>
                    </div>
                    <div className="col-md-10">
                      <label
                        className="add-listing__label"
                        htmlFor="list-adress"
                      >
                        {userInfo.address} {userInfo.detailAddress}{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="center-button">
                <button
                  className="btn-round-lg__border-pink"
                  type="button"
                  onClick={() => history.push("/my-page/user-info-edit")}
                >
                  수정하러 가기{" "}
                </button>
                <button
                  className="btn-round-lg__border-gray ml-5"
                  type="button"
                  onClick={() => history.push("/my-page/user-info-withdrawal")}
                >
                  회원탈퇴{" "}
                </button>
              </div>
              <br />
              <br />
              <br />

              <h5 style={{ float: "left" }}>문화행사 관심분야</h5>
              <ToggleBtn
                btnId={culturalEventAlarm.id}
                status={culturalEventAlarm.state}
                onToggle={onToggle}
              />
              <br />

              <InterestAreaFilter />

              <div className="row">
                <div className="col-lg-12">
                  <h5 style={{ float: "left" }}>자주 찾는 문화공간</h5>
                  <ToggleBtn
                    btnId={culturalPlaceAlarm.id}
                    status={culturalPlaceAlarm.state}
                    onToggle={onToggle}
                  />
                  <br />
                  <FrequentlyVisitedPlaceList
                    placeList={placeList}
                    onToggleModal={onToggleModal}
                    totalRows={totalRows}
                    deletePlace={deletePlace}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default UserInfoView;
