import React from "react";

import ScheduleListItem from "./ScheduleListItem";

const ScheduleList = ({ scheduleList }) => {
  return (
    <>
      <div className="section-title-sm">
        <h2 className="listing-detail__content-title">축제 일정</h2>
        <hr />
      </div>

      <div className="row sc-event-box">
        {scheduleList.map((schedule) =>
          schedule.eventList.length === 0 ? (
            ""
          ) : (
            <ScheduleListItem schedule={schedule} key={schedule.date} />
          )
        )}
      </div>
    </>
  );
};

export default ScheduleList;
