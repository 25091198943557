import React from "react";
import { Link } from "react-router-dom";

const BoardListItem = ({ boardListItem, now, newWindow }) => {
  return (
    <li>
      {newWindow ? (
        <span
          className={
            now === boardListItem.id
              ? "board-list_title2 active"
              : "board-list_title2"
          }
          onClick={() => window.open(boardListItem.url, "_blank")}
        >
          {boardListItem.title}
        </span>
      ) : (
        <span
          className={
            now === boardListItem.id
              ? "board-list_title2 active"
              : "board-list_title2"
          }
        >
          <Link
            to={
              boardListItem.link
                ? boardListItem.link
                : `/community/board-detail/${boardListItem.id}`
            }
          >
            {boardListItem.title}
          </Link>
        </span>
      )}
      <span className="date">{boardListItem.date}</span>
    </li>
  );
};

export default BoardListItem;
