import React from "react";

const ListTemplateTitle = ({ title }) => {
  return (
    <div className="section-header">
      <h1 className="section-header__title">{title} </h1>
      <p className="section-header__description"></p>
    </div>
  );
};

export default ListTemplateTitle;
