import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import MobileContext from "context/mobile";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import PopularEventList from "./event-template/PopularEventList";
import EventList from "./event-template/EventList";
import Footer from "../ect/Footer";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const count = 8;

const EventView = ({ pageTitle, eventType }) => {
  const { state } = useContext(MobileContext);
  const [curPage, setCurPage] = useState(1);
  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const [totalCount, setTotalCount] = useState();

  const [searchResults, setSearchResults] = useState([]);

  // 오늘로 초기화

  const [sortBtn, setSortBtn] = useState({
    column: "create_date",
    type: "desc",
  });

  const getImageByRank = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  const getEventListByRank = useCallback(
    async (userId) => {
      const url = `${process.env.REACT_APP_USER_SERVICE}/api/search/match`;

      const res = await axios.get(url, {
        params: {
          page: curPage,
          count: count,
          sort_column: sortBtn.column,
          sort_type: sortBtn.type,
          userId: userId,
          event_type: eventType.toUpperCase(),
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalCount(totalRows);
        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].id,
            imgSrc: getImageByRank(data[i].images),
            category: "",
            title: data[i].name,
            placeName: data[i].location,
            like: data[i].isLike === 1 ? true : false,
            address: "",
            period: {
              start: data[i].openDate,
              end: data[i].closeDate,
            },
          });
        }

        setSearchResults(ary);
      }
    },
    [curPage, eventType, sortBtn]
  );

  const getEventList = useCallback(
    async (loginId) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/list/${eventType}`;

      const res = await axios.get(url, {
        params: {
          page: curPage,
          count: count,
          sort_column: sortBtn.column,
          sort_type: sortBtn.type,
          search_type: "STATE",
          search_word: "POST",
          loginid: loginId,
        },
      });

      if (res.status === 200) {
        const { total_count, list } = res.data;

        setTotalCount(total_count);
        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].id,
            imgSrc: getImage(list[i].resources),
            category: list[i].event_type,
            title: list[i].name,
            placeName: list[i].location,
            like: res.data.list[i].is_like === 1 ? true : false,
            address: "",
            period: {
              start: list[i].open_date,
              end: list[i].close_date,
            },
          });
        }

        setSearchResults(ary);
      }
    },
    [curPage, eventType, sortBtn]
  );

  const sortByNewest = () => {
    setSortBtn({ column: "create_date", type: "desc" });

    // 1. axios 최신 정렬순으로 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법 : 최신순
    // 2. reponse data 반영
  };

  const sortByRank = () => {
    setSortBtn({ column: "rank", type: "asc" });

    // 1. axios 주간 랭킹순으로 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법 : 주간 랭킹순
    // 2. reponse data 반영
  };

  const sortByName = () => {
    setSortBtn({ column: "name", type: "asc" });

    // 1. axios 이름순으로 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법 : 이름순
    // 2. reponse data 반영
  };

  const sortByEnd = () => {
    setSortBtn({ column: "close_date", type: "asc" });
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    if (sortBtn.column === "rank") {
      getEventListByRank();
    } else {
      getEventList(loginId);
    }
  }, [getEventList, getEventListByRank, sortBtn.column]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle={pageTitle} />
      <PopularEventList type={eventType} />

      <section className="explore">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="explore__box">
                <h2 className="explore__filter-title">
                  <span>총</span> {totalCount}건
                  <ul className="explore__form-tab-list">
                    <button
                      className={
                        sortBtn.column === "create_date"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByNewest}
                    >
                      최신{" "}
                    </button>
                    <button
                      className={
                        sortBtn.column === "rank"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByRank}
                    >
                      주간랭킹{" "}
                    </button>
                    <button
                      className={
                        sortBtn.column === "name"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByName}
                    >
                      이름{" "}
                    </button>
                    <button
                      className={
                        sortBtn.column === "close_date"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByEnd}
                    >
                      종료임박{" "}
                    </button>
                  </ul>
                </h2>

                <EventList list={searchResults} />
                <Paging
                  pageNum={curPage}
                  getCurPage={getCurPage}
                  onePage={8}
                  all={totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default EventView;
