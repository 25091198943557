import React, { useState, useEffect } from "react";
import axios from "axios";

const CountDown = () => {
  const [totalNumOfEvent, setTotalNumOfEvent] = useState();
  const [totalNumOfReview, setTotalNumOfReview] = useState();
  const [totalNumOfPlace, setTotalNumOfPlace] = useState();

  useEffect(() => {
    const getTodayStat = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE_J}/api/admin/stat/today`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          setTotalNumOfEvent(res.data.todayEvents);
          setTotalNumOfReview(res.data.todayReviews);
          setTotalNumOfPlace(res.data.nearSpaces);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getTodayStat();
  }, []);

  return (
    <section className="statistic statistic-bg-img2">
      <div className="container">
        <div className="statistic__box">
          <div className="row">
            <div className="col-lg-4 col-sm-6 padding-left-4rem">
              <div className="statistic-post">
                <span className="statistic-post_icon statistic-post_icon-primary">
                  <i className="la la-list-ul" aria-hidden="true"></i>
                </span>
                <div className="statistic-post_content">
                  <h1 className="statistic-post_title statistic-post_title-black">
                    <span
                      className="timer"
                      data-from="0"
                      data-to={totalNumOfEvent}
                    >
                      {totalNumOfEvent}
                    </span>{" "}
                    +
                  </h1>
                  <p className="statistic-post_description">오늘 올라온 공연</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 padding-left-4rem">
              <div className="statistic-post">
                <span className="statistic-post_icon statistic-post_icon-primary">
                  <i className="la la-comment" aria-hidden="true"></i>
                </span>
                <div className="statistic-post_content">
                  <h1 className="statistic-post_title statistic-post_title-black">
                    <span
                      className="timer"
                      data-from="0"
                      data-to={totalNumOfReview}
                    >
                      {totalNumOfReview}
                    </span>{" "}
                    +
                  </h1>
                  <p className="statistic-post_description">오늘 올라온 리뷰</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 padding-left-4rem">
              <div className="statistic-post">
                <span className="statistic-post_icon statistic-post_icon-primary">
                  <i className="la la-map" aria-hidden="true"></i>
                </span>
                <div className="statistic-post_content">
                  <h1 className="statistic-post_title statistic-post_title-black">
                    <span
                      className="timer"
                      data-from="0"
                      data-to={totalNumOfPlace}
                    >
                      {totalNumOfPlace}
                    </span>{" "}
                    +
                  </h1>
                  <p className="statistic-post_description">내 주변 문화공간</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountDown;
