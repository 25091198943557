import React from "react";
import { Link } from "react-router-dom";
import ScheduleList from "../event-template/ScheduleList";

const replaceString = (string) => {
  return string.replace(/-/gi, ".");
};

const addDot = (string) => {
  let result = "";
  return result.concat(
    string.slice(0, 4),
    ".",
    string.slice(4, 6),
    ".",
    string.slice(6)
  );
};

const ScEventList = ({ scEvent }) => {
  const getImageSrc = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return `${process.env.REACT_APP_ADMIN_SERVICE}${resources.images[0].url}`;
    } else {
      return process.env.PUBLIC_URL + "/upload/seocho-img@2x.jpg";
    }
  };

  return (
    <>
      <div class="item">
        <div class="listing-detail__content-box listing-detail__content-box-nopadding">
          <div class="listing-detail__sc-event">
            <img src={getImageSrc(scEvent.resources)} />
          </div>
          <h1 class="listing-detail-page__content-title">{scEvent.name}</h1>
          <Link
            class="btn-sc__item-reply btn-float-right"
            to={`/event/sc-event-detail/${scEvent.id}`}
          >
            자세히 보기 &gt;{" "}
          </Link>
          <p class="post__info-time">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            {addDot(scEvent.open_date)}~{addDot(scEvent.close_date)}
          </p>
          <p class="listing-detail__content-description">
            {scEvent.description}
          </p>
        </div>
      </div>
      {/* {scEvent.scheduleList.length > 0 ? (
        <ScheduleList scheduleList={scEvent.scheduleList} />
      ) : null} */}
    </>
  );
};

export default ScEventList;
