import React, { useEffect, useCallback } from "react";

const KakaoShareBtn = ({ title, url, address, category, imgUrl }) => {
  const createKakaoButton = useCallback(() => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
      }
      kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: title,
          description: address + "\n#서초문화포털 #" + category,
          imageUrl: imgUrl,
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
        buttons: [
          {
            title: "웹으로 보기",
            link: {
              mobileWebUrl: url,
              webUrl: url,
            },
          },
        ],
      });
    }
  }, [address, category, imgUrl, title, url]);

  useEffect(() => {
    createKakaoButton();
  }, [createKakaoButton]);

  return (
    <div className="kakao-share-button">
      <button
        id="kakao-link-btn"
        style={{
          height: "90px",
          width: "90px",
          background: "white",
          border: "3px solid rgb(230, 230, 230)",
          borderRadius: "13px",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/images/kakao_logo.png"}
          alt="btn-kakao"
          style={{
            height: "80%",
          }}
        />
      </button>
    </div>
  );
};

export default KakaoShareBtn;
