import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import ListTemplateTitle from "./list-template/ListTemplateTitle";
import MoreList from "./list-template/MoreList";
import PlaceList from "./list-template/PlaceList";

const FrequentlyVistedPlace = ({ title }) => {
  const [menu, setMenu] = useState(0);
  const onChangeMenu = (menuNum) => {
    setMenu(menuNum);
    setListInfo(menuNum);
  };

  const [frequentlyVisitedPlaceList, setFrequentlyVisitedPlaceList] = useState(
    []
  );

  const [totalCount, setTotalCount] = useState(0);

  // api 호출 function으로 바꾸어야함
  const setListInfo = (menuNum) => {
    switch (menuNum) {
      case 0:
        getPlaceList("");

        break;
      case 1:
        getPlaceList("hall");

        break;
      case 2:
        getPlaceList("practice");

        break;
      case 3:
        getPlaceList("instrument");

        break;
      case 4:
        getPlaceList("gallery");

        break;
      default:
        break;
    }
  };

  const setLink = () => {
    switch (menu) {
      case 0:
        return "/place/place-all";
      case 1:
        return "/place/concert-hall";
      case 2:
        return "/place/practice-room";
      case 3:
        return "/place/instrument-store";
      case 4:
        return "/place/gallery";

      default:
    }
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  const getPlaceList = useCallback(async (space_type) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list/${space_type}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          count: 8,
          sort_column: "view_count",
          sort_type: "desc",
          search_type: "STATE",
          search_word: "POST",
        },
      });

      if (response.status === 200) {
        const { total_count, list } = response.data;

        setTotalCount(total_count);

        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].id,
            imgSrc: getImage(list[i].resources),
            category: list[i].space_type,
            title: list[i].name,
            address: list[i].address1 + " " + list[i].address2,
            status: list[i].state,
          });
        }

        setFrequentlyVisitedPlaceList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    getPlaceList("");

    return () => {
      document.body.removeChild(script);
    };
  }, [getPlaceList]);

  return (
    <section className="section-culture-place">
      <div className="container">
        <ListTemplateTitle title={title} />

        <ul className="tabs-block2__list">
          <li className="tabs-block2__list-item">
            <a
              className={menu === 0 ? "active-list" : ""}
              onClick={() => onChangeMenu(0)}
            >
              전체
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 1 ? "active-list" : ""}
              onClick={() => onChangeMenu(1)}
            >
              공연장
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 2 ? "active-list" : ""}
              onClick={() => onChangeMenu(2)}
            >
              연습실
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 3 ? "active-list" : ""}
              onClick={() => onChangeMenu(3)}
            >
              악기상점
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 4 ? "active-list" : ""}
              onClick={() => onChangeMenu(4)}
            >
              갤러리
            </a>
          </li>
        </ul>
        <PlaceList placeList={frequentlyVisitedPlaceList} />
        <MoreList moreNum={totalCount} link={setLink()} />
      </div>
    </section>
  );
};

export default FrequentlyVistedPlace;
