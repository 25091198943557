import React from "react";

const ReservListItem = ({ reservInfo, deleteReserv }) => {
  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };
  const onConfirmDelete = () => {
    deleteReserv(reservInfo.id);
    alert("삭제되었습니다");
  };
  const onHandleDelete = useConfirm(
    "예약 신청을 삭제하시겠습니까?",
    onConfirmDelete
  );

  const replaceString = (string) => {
    return string.replace(/-/gi, ".");
  };

  return (
    <li className="reviews-list__item">
      <div className="reviews-list__item-box">
        <div className="reviews-list__item-content">
          <h3 className="reviews-list__item-title">{reservInfo.userId} </h3>
          <span className="reviews-list__items-date">
            {replaceString(reservInfo.date)}
          </span>
          <p className="reviews-list__item-description">
            <span>{reservInfo.name}</span> / <span>{reservInfo.tel}</span>
          </p>
          <p className="reviews-list__item-description">{reservInfo.content}</p>
          {reservInfo.userId === window.sessionStorage.getItem("userid") ? (
            <a className="reviews-list__item-reply" onClick={onHandleDelete}>
              <i className="la la-mail-forward"></i>
              삭제{" "}
            </a>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default ReservListItem;
