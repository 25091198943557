import React, { useState, useCallback, memo } from "react";
import { GoogleMap, useJsApiLoader, OverlayView } from "@react-google-maps/api";

const SingleMarkerMap = ({ center, category }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = useState(null);

  const getIcon = () => {
    switch (category) {
      case "performance":
        return <i className="la la-music" aria-hidden="true"></i>;
      case "exhibition":
        return <i className="fa fa-paint-brush" aria-hidden="true"></i>;
      case "ect":
        return <i className="fa fa-ticket" aria-hidden="true"></i>;
      case "theater":
        return <i className="la la-university" aria-hidden="true"></i>;
      case "instrumentStore":
        return <i className="la la-money" aria-hidden="true"></i>;
      case "gallery":
        return <i className="la la-photo" aria-hidden="true"></i>;
      default:
        return <i className="la la-university" aria-hidden="true"></i>;
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={"map-wrapper"}
      zoom={16}
      center={center}
      options={{
        scrollwheel: false,
        scaleControl: false,
        panControl: false,
        navigationControl: false,
        streetViewControl: false,
        gestureHandling: "cooperative",
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      }}
    >
      <OverlayView position={center} mapPaneName="markerLayer">
        <div className="marker-box">
          <div className="marker-holder">
            <div className="circle-icon">
              <div className="circle-icon-item">{getIcon()}</div>
              <div className="border-arrow-btm"></div>
            </div>
          </div>
        </div>
      </OverlayView>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default SingleMarkerMap;
