import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import ReservForm from "./ReservForm";
import ReservListItem from "./ReservListItem";
import Paging from "../Paging";

const UserReservComponent = ({ postId, type }) => {
  const history = useHistory();

  const [curPage, setCurPage] = useState(1);
  const getCurPage = (pageNumber) => {
    setCurPage(pageNumber);
  };

  const [totalRows, setTotalRows] = useState(0);
  const [reservList, setReservList] = useState([]);

  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };

  const onConfirmMoveLogin = () => {
    history.push("/common/log-in");
  };

  const comfirmLogin = useConfirm(
    "로그인 후에 이용하실 수 있습니다.\n로그인 화면으로 이동하시겠습니까?",
    onConfirmMoveLogin
  );

  const addReserv = (name, tel, content) => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      comfirmLogin();
      return;
    }

    if (window.location.href.includes("-detail-waiting")) {
      alert("대기중인 게시물은 예약 신청을 하실 수 없습니다.");
      return;
    }

    let data = new Object();

    data.contentId = postId;
    data.contentType = type.toUpperCase();
    data.userName = name;
    data.userPhone = tel;
    data.userMemo = content;

    registReserve(data);
  };

  const registReserve = async (data) => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/admin/reserve/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        getReservList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteReserv = async (rId) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/admin/reserve/${rId}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        getReservList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getReservList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/admin/reserve/${type}/${postId}`;

    try {
      const res = await axios.get(url, {
        params: {
          page: curPage,
          rows: 5,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].id,
            userId: data[i].createUid,
            name: data[i].userName,
            date: data[i].reserveDate,
            tel: data[i].userPhone,
            content: data[i].userMemo,
          });
        }

        setReservList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPage, postId, type]);

  useEffect(() => {
    getReservList();
  }, [getReservList]);

  return (
    <div>
      <div className="listing-detail__reviews" id="tips-reviews-box">
        <h2 className="listing-detail__content-title">
          예약 신청({totalRows}건){" "}
        </h2>
        <form className="contact-form inner-review" id="leave-review">
          <ReservForm addReserv={addReserv} />
          <hr />

          <div className="listing-detail__reviews-box">
            <ul className="reviews-list">
              {reservList.map((reservInfo) => (
                <ReservListItem
                  reservInfo={reservInfo}
                  key={reservInfo.id}
                  deleteReserv={deleteReserv}
                />
              ))}
            </ul>
          </div>
          <Paging
            pageNum={curPage}
            getCurPage={getCurPage}
            onePage={5}
            all={totalRows}
          />
        </form>
      </div>
    </div>
  );
};
export default UserReservComponent;
