import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import BoardList from "../community/board-template/BoardList";
import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import ToggleBtn from "./my-page-template/ToggleBtn";
import MyPageMenu from "./MyPageMenu";
import Paging from "components/ect/Paging";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const UserAlertSettingView = () => {
  const { state } = useContext(MobileContext);

  const history = useHistory();
  const [curPage, setCurPage] = useState(1);

  const [totalNewestRows, setTotalNewestRows] = useState(0);
  const [totalPreviousRows, setTotalPreviousRows] = useState(0);

  const [newestAlarmList, setNewestAlarmList] = useState([]);
  const [previousAlarmList, setPreviousAlarmList] = useState([]);

  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const [culturalEventAlarm, setCulturalEventAlarm] = useState({
    id: "CULTURAL_EVENT",
    state: true,
  });
  const [communityEventAlarm, setCommunityEventAlarm] = useState({
    id: "COMMUNITY_EVENT",
    state: true,
  });
  const [culturalPlaceAlarm, setCulturalPlaceAlarm] = useState({
    id: "CULTURAL_SPACE",
    state: true,
  });

  const onToggle = (btnId, state) => {
    let data = new Object();

    data.type = btnId;

    if (btnId === "COMMUNITY_EVENT") {
      state ? (data.communityEvent = 1) : (data.communityEvent = 0);
    } else if (btnId === "CULTURAL_EVENT") {
      state ? (data.culturalEvent = 1) : (data.culturalEvent = 0);
    } else {
      state ? (data.culturalSpace = 1) : (data.culturalSpace = 0);
    }

    editAlarm(data);
  };

  const editAlarm = async (data) => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/alarm/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        getAlarmStates();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAlarmStates = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/alarm/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.get(url);

      if (res.status === 200) {
        const { communityEvent, culturalEvent, culturalSpace } = res.data;

        setCommunityEventAlarm({
          id: "COMMUNITY_EVENT",
          state: communityEvent === 1 ? true : false,
        });
        setCulturalEventAlarm({
          id: "CULTURAL_EVENT",
          state: culturalEvent === 1 ? true : false,
        });
        setCulturalPlaceAlarm({
          id: "CULTURAL_SPACE",
          state: culturalSpace === 1 ? true : false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getNewsetAlarmList = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/alarm/newest/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.get(url, {
        params: {
          page: 1,
          rows: 10,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalNewestRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].idx,
            link: data[i].link.replace("http://118.67.154.134:4040", ""),
            title: data[i].title,
            date: data[i].createDateStr,
          });
        }

        setNewestAlarmList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getPreviousAlarmList = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/alarm/previous/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.get(url, {
        params: {
          page: curPage,
          rows: 10,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalPreviousRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].idx,
            link: data[i].link.replace("http://118.67.154.134:4040", ""),
            title: data[i].title,
            date: data[i].createDateStr,
          });
        }

        setPreviousAlarmList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPage]);

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    getAlarmStates();
    getNewsetAlarmList();
    getPreviousAlarmList();

    return () => {
      document.body.removeChild(script);
    };
  }, [getAlarmStates, getNewsetAlarmList, getPreviousAlarmList, history]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />

            <div className="col-lg-10">
              <div id="sc-tab-menu">
                <nav>
                  <div
                    className="nav nav-tabs none-mt-30-mb-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link active"
                      id="nav-01-tab"
                      data-toggle="tab"
                      href="#nav-tab-01"
                      role="tab"
                      aria-controls="nav-tab-01"
                      aria-selected="true"
                    >
                      활동 알림
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-02-tab"
                      data-toggle="tab"
                      href="#nav-tab-02"
                      role="tab"
                      aria-controls="nav-tab-02"
                      aria-selected="false"
                    >
                      알림 설정
                    </a>
                  </div>
                </nav>

                <div
                  className="tab-content"
                  id="nav-tabContent"
                  style={{ display: "block", backgroundColor: "#ffff" }}
                >
                  <div
                    className="tab-pane fade show active"
                    id="nav-tab-01"
                    role="tabpanel"
                    aria-labelledby="nav-01-tab"
                  >
                    <form className="tab-form">
                      <div className="section-title-sm padding-top-2rem">
                        <h2 className="listing-detail__content-title">
                          신규 알림 ({totalNewestRows}건){" "}
                        </h2>
                        <hr />
                      </div>
                      <BoardList boardList={newestAlarmList} now={-1} />
                      {/* {newestAlarmList.length > 10 ? (
                        <Paging
                          pageNum={curPage}
                          getCurPage={getCurPage}
                          onePage={10}
                        />
                      ) : (
                        ""
                      )} */}

                      <div className="section-title-sm padding-top-2rem">
                        <h2 className="listing-detail__content-title">
                          이전 알림 ({totalPreviousRows}건){" "}
                        </h2>
                        <hr />
                      </div>
                      <BoardList boardList={previousAlarmList} now={-1} />

                      <Paging
                        pageNum={curPage}
                        getCurPage={getCurPage}
                        onePage={10}
                        all={totalPreviousRows}
                      />
                    </form>
                  </div>

                  <div
                    className="tab-pane fade "
                    id="nav-tab-02"
                    role="tabpanel"
                    aria-labelledby="nav-02-tab"
                  >
                    <form className="tab-form">
                      <div className="section-title-sm padding-top-2rem"></div>
                      <div className="board-list">
                        <ul>
                          <li className="list-big">
                            <span className="board-list_title2">
                              <a>이벤트 알림</a>
                            </span>
                            <ToggleBtn
                              btnId={communityEventAlarm.id}
                              status={communityEventAlarm.state}
                              onToggle={onToggle}
                            />
                          </li>

                          <li className="list-big">
                            <span className="board-list_title2">
                              <a>문화행사 관심 분야 소식 알림</a>
                            </span>
                            <ToggleBtn
                              btnId={culturalEventAlarm.id}
                              status={culturalEventAlarm.state}
                              onToggle={onToggle}
                            />
                          </li>

                          <li className="list-big">
                            <span className="board-list_title2">
                              <a>자주 찾는 문화공간 소식 알림</a>
                            </span>
                            <ToggleBtn
                              btnId={culturalPlaceAlarm.id}
                              status={culturalPlaceAlarm.state}
                              onToggle={onToggle}
                            />
                          </li>
                        </ul>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default UserAlertSettingView;
