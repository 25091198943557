import React, { useState, useEffect, useCallback, useContext } from "react";
import MobileContext from "context/mobile";
import axios from "axios";
import $ from "jquery";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import SearchFilter from "./event-template/SearchFilter";
import PopularEventList from "./event-template/PopularEventList";
import EventList from "./event-template/EventList";
import Footer from "../ect/Footer";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const dayArray = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일",
];

// 첫번째 요일 계산
const calcFirstDay = (year, month) => {
  return new Date(year, month, 1).getDay();
};

// 마지막날 일자 계산
const calcLastDate = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const count = 8;

const dateFormat = (y, m, d) => {
  let dateString = y + "";
  if (m < 9) {
    dateString += "0" + (m + 1);
  } else {
    dateString += m + 1;
  }

  if (d < 10) {
    dateString += "0" + d;
  } else {
    dateString += d;
  }

  return dateString;
};

const CustomizeEventSearch = ({ pageTitle }) => {
  const { state } = useContext(MobileContext);

  const [curPage, setCurPage] = useState(1);
  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const [totalCount, setTotalCount] = useState(0);

  const [searchResults, setSearchResults] = useState([]);

  const today = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    date: new Date().getDate(),
    day: new Date().getDay(),
  };
  // 오늘로 초기화
  const [pickDate, setPickDate] = useState(today);
  const [pickStartDate, setPickStartDate] = useState(today);
  const [pickEndDate, setPickEndDate] = useState(today);
  const [calendarDate, setCalendarDate] = useState({
    year: today.year,
    month: today.month,
  });
  const [btn, setBtn] = useState("today");

  const firstDay = calcFirstDay(calendarDate.year, calendarDate.month);
  const lastDate = calcLastDate(calendarDate.year, calendarDate.month);

  const onChangePickDate = (e) => {
    const date = parseInt(e.target.innerText);
    const day = new Date(
      calendarDate.year,
      calendarDate.month,
      parseInt(e.target.innerText)
    ).getDay();

    setPickDate({
      year: calendarDate.year,
      month: calendarDate.month,
      date: date,
      day: day,
    });

    switch (btn) {
      case "today":
        setPickStartDate({
          year: calendarDate.year,
          month: calendarDate.month,
          date: date,
          day: day,
        });
        setPickEndDate({
          year: calendarDate.year,
          month: calendarDate.month,
          date: date,
          day: day,
        });
        break;
      case "daily":
        setPickStartDate({
          year: calendarDate.year,
          month: calendarDate.month,
          date: date,
          day: day,
        });
        setPickEndDate({
          year: calendarDate.year,
          month: calendarDate.month,
          date: date,
          day: day,
        });
        break;
      case "weekly":
        setStartOfWeekly(day - 0, calendarDate.year, calendarDate.month, date);
        setEndOfWeekly(6 - day, calendarDate.year, calendarDate.month, date);
        break;
      case "monthly":
        setStartOfMonthly(calendarDate.year, calendarDate.month);
        setEndOfMonthly(calendarDate.year, calendarDate.month);
        break;
      default:
        break;
    }

    $("td").removeClass("day-active");
    $(e.target).addClass("day-active");

    // 1. axios 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법
    // 2. reponse data 반영
  };

  const setCalendar = () => {
    let calendar = "";
    let date = 1;
    let countDay = 0;

    for (let i = 0; i < 2; i++) {
      if (date > lastDate) break;

      calendar += "<tr>";

      for (let i = 1; i < 29; i++) {
        if (countDay !== firstDay) {
          calendar += "<td></td>";
          countDay++;
        } else {
          if (
            date === today.date &&
            calendarDate.month === today.month &&
            calendarDate.year === today.year
          ) {
            calendar += `<td class="day today day-active" id="${date}">${date}</td>`;
          } else {
            calendar += `<td class="day" id="${date}">${date}</td>`;
          }
          date++;
        }

        if (date > lastDate) break;
      }

      calendar += "</tr>";
    }

    return calendar;
  };

  const setCalendar2 = () => {
    let calendar = "";
    let date = 1;
    let countDay = 0;

    for (let i = 0; i < 5; i++) {
      if (date > lastDate) break;

      calendar += "<tr>";

      for (let i = 0; i < 7; i++) {
        if (countDay !== firstDay) {
          calendar += "<td></td>";
          countDay++;
        } else {
          if (
            date === today.date &&
            calendarDate.month === today.month &&
            calendarDate.year === today.year
          ) {
            calendar += `<td class="day today day-active" id="${date}">${date}</td>`;
          } else {
            calendar += `<td class="day" id="${date}">${date}</td>`;
          }
          date++;
        }

        if (date > lastDate) break;
      }

      calendar += "</tr>";
    }

    return calendar;
  };

  const onChangePreMonth = () => {
    // 1월인 경우, 년 :  -1, 월 : 12
    if (calendarDate.month === 0) {
      setCalendarDate({
        year: calendarDate.year - 1,
        month: 11,
      });
    } else {
      setCalendarDate({
        year: calendarDate.year,
        month: calendarDate.month - 1,
      });
    }

    console.log("PRE CLICK");
  };

  const onChangeNextMonth = () => {
    // 12월인 경우, 년 :  +1, 월 : 1
    if (calendarDate.month === 11) {
      setCalendarDate({
        year: calendarDate.year + 1,
        month: 0,
      });
    } else {
      setCalendarDate({
        year: calendarDate.year,
        month: calendarDate.month + 1,
      });
    }

    console.log("NEXT CLICK");
  };

  const onChangeToday = () => {
    setBtn("today");
    setPickDate(today);
    setPickStartDate(today);
    setPickEndDate(today);

    if (
      calendarDate.month === today.month &&
      calendarDate.year === today.year
    ) {
      $("td").removeClass("day-active");
      $(`#${today.date}`).addClass("day-active");
    } else {
      setCalendarDate({
        year: today.year,
        month: today.month,
      });
    }

    // 1. axios 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법
    // 2. reponse data 반영
  };

  const onChangeDaily = () => {
    setBtn("daily");

    setPickStartDate(pickDate);
    setPickEndDate(pickDate);

    // 1. axios 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법
    // 2. reponse data 반영
  };

  const onChangeWeeckly = () => {
    setBtn("weekly");

    setStartOfWeekly(
      pickDate.day - 0,
      pickDate.year,
      pickDate.month,
      pickDate.date
    );
    setEndOfWeekly(
      6 - pickDate.day,
      pickDate.year,
      pickDate.month,
      pickDate.date
    );

    // 1. axios 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법
    // 2. reponse data 반영
  };

  const setStartOfWeekly = (startDay, year, month, date) => {
    let startDate = date;
    let isLastYear,
      isLastMonth = false;

    if (date - startDay < 1) {
      isLastMonth = true;
      let prevMonthLastDate;
      if (month === 0) {
        prevMonthLastDate = calcLastDate(year - 1, 11);
        isLastYear = true;
      } else {
        prevMonthLastDate = calcLastDate(year, month - 1);
      }

      for (let i = startDay; 0 < i; i--) {
        startDate--;
        if (startDate < 1) {
          startDate = prevMonthLastDate;
        }
      }
    } else {
      startDate -= startDay;
    }

    if (isLastYear) {
      setPickStartDate({
        year: year - 1,
        month: 11,
        date: startDate,
        day: 0,
      });
    } else if (isLastMonth) {
      setPickStartDate({
        year: year,
        month: month - 1,
        date: startDate,
        day: 0,
      });
    } else {
      setPickStartDate({
        year: year,
        month: month,
        date: startDate,
        day: 0,
      });
    }
  };

  const setEndOfWeekly = (endDay, year, month, date) => {
    let endDate = date;
    let monthLastDate = calcLastDate(year, month);
    let isNextYear,
      isNextMonth = false;

    if (date + endDay > monthLastDate) {
      isNextMonth = true;
      if (month === 11) {
        isNextYear = true;
      }
      for (let i = 0; i <= endDay; i++) {
        if (endDate > monthLastDate) {
          endDate = 1;
        } else {
          endDate++;
        }
      }
    } else {
      endDate += endDay;
    }

    if (isNextYear) {
      setPickEndDate({
        year: year + 1,
        month: 0,
        date: endDate,
        day: 6,
      });
    } else if (isNextMonth) {
      setPickEndDate({
        year: year,
        month: month + 1,
        date: endDate,
        day: 6,
      });
    } else {
      setPickEndDate({
        year: year,
        month: month,
        date: endDate,
        day: 6,
      });
    }
  };

  const onChangeMonthly = () => {
    setBtn("monthly");

    setStartOfMonthly(pickDate.year, pickDate.month);
    setEndOfMonthly(pickDate.year, pickDate.month);

    // 1. axios 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법
    // 2. reponse data 반영
  };

  const setStartOfMonthly = (year, month) => {
    setPickStartDate({
      year: year,
      month: month,
      date: 1,
      day: calcFirstDay(year, month),
    });
  };

  const setEndOfMonthly = (year, month) => {
    let lastDate = calcLastDate(year, month);

    setPickEndDate({
      year: year,
      month: month,
      date: lastDate,
      day: new Date(year, month, lastDate).getDay(),
    });
  };

  const [fields, setFields] = useState("");
  const [themes, setThemes] = useState("");
  const [fee, setFee] = useState("");

  const searchFilter = (fields, themes, fee) => {
    setFields(fields);
    setThemes(themes);
    setFee(fee);
  };

  const [sortBtn, setSortBtn] = useState({
    column: "create_date",
    type: "desc",
  });

  const getEventList = useCallback(
    async (userId) => {
      const url = `${process.env.REACT_APP_USER_SERVICE}/api/search/match`;

      const res = await axios.get(url, {
        params: {
          page: curPage,
          count: count,
          from_date: dateFormat(
            pickStartDate.year,
            pickStartDate.month,
            pickStartDate.date
          ),
          to_date: dateFormat(
            pickEndDate.year,
            pickEndDate.month,
            pickEndDate.date
          ),
          sort_column: sortBtn.column,
          sort_type: sortBtn.type,
          fields: fields,
          themes: themes,
          fee: fee,
          userId: userId,
        },
      });

      if (res.status === 200) {
        // console.log(res.data.list);

        const { totalRows, data } = res.data;

        setTotalCount(totalRows);
        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].id,
            imgSrc: getImage(data[i].images),
            category: "",
            title: data[i].name,
            placeName: data[i].location,
            like: data[i].isLike === 1 ? true : false,
            address: "",
            period: {
              start: data[i].openDate,
              end: data[i].closeDate,
            },
          });
        }

        setSearchResults(ary);
      }
    },
    [curPage, fee, fields, pickEndDate, pickStartDate, sortBtn, themes]
  );

  const sortByNewest = () => {
    setSortBtn({ column: "create_date", type: "desc" });

    // 1. axios 최신 정렬순으로 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법 : 최신순
    // 2. reponse data 반영
  };

  const sortByRank = () => {
    setSortBtn({ column: "rank", type: "asc" });

    // 1. axios 주간 랭킹순으로 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법 : 주간 랭킹순
    // 2. reponse data 반영
  };

  const sortByName = () => {
    setSortBtn({ column: "name", type: "asc" });

    // 1. axios 이름순으로 데이터 요청 ( async 함수로 변경 )
    //    params 기간, 큐레이션 옵션, 유료/무료 여부, 정렬 방법 : 이름순
    // 2. reponse data 반영
  };

  const sortByEnd = () => {
    setSortBtn({ column: "close_date", type: "asc" });
  };

  const getImage = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    getEventList(loginId);
  }, [getEventList]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle={pageTitle} />
      {/*인기행사 숨김 
      <PopularEventList type="ALL" />
      */}
      <section className="explore">
        <div className="container">
          <div className="calendar-frame">
            <div className="my-calendar clearfix">
              <div className="calendar-box">
                <div className="ctr-box clearfix">
                  <button
                    type="button"
                    title="prev"
                    className="btn-cal prev"
                    onClick={onChangePreMonth}
                  ></button>
                  <span className="cal-year">{calendarDate.year}년&nbsp;</span>
                  <span className="cal-month">{calendarDate.month + 1}월</span>
                  <button
                    type="button"
                    title="next"
                    className="btn-cal next"
                    onClick={onChangeNextMonth}
                  ></button>
                </div>
                <table className="cal-table mh-hidden">
                  <thead>
                    <tr>
                      <th className="week-sun">일</th>
                      <th>월</th>
                      <th>화</th>
                      <th>수</th>
                      <th>목</th>
                      <th>금</th>
                      <th className="week-sat">토</th>
                      <th className="week-sun">일</th>
                      <th>월</th>
                      <th>화</th>
                      <th>수</th>
                      <th>목</th>
                      <th>금</th>
                      <th className="week-sat">토</th>
                      <th className="week-sun">일</th>
                      <th>월</th>
                      <th>화</th>
                      <th>수</th>
                      <th>목</th>
                      <th>금</th>
                      <th className="week-sat">토</th>
                      <th className="week-sun">일</th>
                      <th>월</th>
                      <th>화</th>
                      <th>수</th>
                      <th>목</th>
                      <th>금</th>
                      <th className="week-sat">토</th>
                    </tr>
                  </thead>
                  <tbody
                    className="cal-body"
                    dangerouslySetInnerHTML={{
                      __html: setCalendar(),
                    }}
                    onClick={onChangePickDate}
                  ></tbody>
                </table>

                <table className="cal-table mih-hidden">
                  <thead>
                    <tr>
                      <th className="week-sun">일</th>
                      <th>월</th>
                      <th>화</th>
                      <th>수</th>
                      <th>목</th>
                      <th>금</th>
                      <th className="week-sat">토</th>
                    </tr>
                  </thead>
                  <tbody
                    className="cal-body"
                    dangerouslySetInnerHTML={{
                      __html: setCalendar2(),
                    }}
                    onClick={onChangePickDate}
                  ></tbody>
                </table>
              </div>
              <div className="clicked-date">
                <span className="cal-year">{pickStartDate.year}</span>년{" "}
                <span className="cal-month">{pickStartDate.month + 1}</span>월{" "}
                <span className="cal-date">{pickStartDate.date}</span>일 (
                <span className="cal-day">{dayArray[pickStartDate.day]}</span>)
                {pickStartDate.year === pickEndDate.year &&
                pickStartDate.month === pickEndDate.month &&
                pickStartDate.date === pickEndDate.date ? null : (
                  <>
                    ~<span className="cal-year">{pickEndDate.year}</span>년{" "}
                    <span className="cal-month">{pickEndDate.month + 1}</span>월{" "}
                    <span className="cal-date">{pickEndDate.date}</span>일 (
                    <span className="cal-day">{dayArray[pickEndDate.day]}</span>
                    )
                  </>
                )}
                <ul className="explore__form-tab-list">
                  <li>
                    <a
                      className={btn === "today" ? "active" : ""}
                      onClick={onChangeToday}
                    >
                      오늘
                    </a>
                  </li>
                  <li>
                    <a
                      className={btn === "daily" ? "active" : ""}
                      onClick={onChangeDaily}
                    >
                      일간
                    </a>
                  </li>
                  <li>
                    <a
                      className={btn === "weekly" ? "active" : ""}
                      onClick={onChangeWeeckly}
                    >
                      주간
                    </a>
                  </li>
                  <li>
                    <a
                      className={btn === "monthly" ? "active" : ""}
                      onClick={onChangeMonthly}
                    >
                      월간
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <SearchFilter searchFilter={searchFilter} />

          <div className="row">
            <div className="col-lg-12">
              <div className="explore__box">
                <h2 className="explore__filter-title">
                  <span>총</span> {totalCount}건
                  <ul className="explore__form-tab-list">
                    <button
                      className={
                        sortBtn.column === "create_date"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByNewest}
                    >
                      최신{" "}
                    </button>
                    <button
                      className={
                        sortBtn.column === "rank"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByRank}
                    >
                      주간랭킹{" "}
                    </button>
                    <button
                      className={
                        sortBtn.column === "name"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByName}
                    >
                      이름{" "}
                    </button>
                    <button
                      className={
                        sortBtn.column === "close_date"
                          ? "btn-default-sc-active"
                          : "btn-default-sc"
                      }
                      type="button"
                      onClick={sortByEnd}
                    >
                      종료임박{" "}
                    </button>
                  </ul>
                </h2>

                <EventList list={searchResults} />
                <Paging
                  pageNum={curPage}
                  getCurPage={getCurPage}
                  onePage={8}
                  all={totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default CustomizeEventSearch;
