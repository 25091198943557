import React from "react";
import DaumPostcode from "react-daum-postcode";

const PostCode = ({ getAddress, closePopup }) => {
  return (
    <div
      className="modal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="bg"
        style={{
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.6)",
        }}
      ></div>
      <div
        className="modalBox"
        style={{
          position: "absolute",
          background: "#fff",
          width: "500px",
          height: "480px",
          padding: "15px",
          paddingTop: "18px",
          border: "1px solid white",
          borderRadius: "13px",
        }}
      >
        <div
          className="infoBox-close"
          onClick={closePopup}
          style={{
            top: "-13px",
            right: "-15px",
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        <DaumPostcode
          style={{
            width: "100%",
            height: "100%",
          }}
          autoClose={true}
          onComplete={(data) => getAddress(data.address)}
        />
      </div>
    </div>
  );
};

export default PostCode;
