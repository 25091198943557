import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import EventListItem from "./EventListItem";

const PopularEventList = ({ type }) => {
  const [eventList, setEventList] = useState([]);

  const getImage = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const getPopularEventList = async (userId) => {
      const url = `${process.env.REACT_APP_USER_SERVICE}/api/like/top`;

      try {
        const res = await axios.get(url, {
          params: {
            type: type.toUpperCase(),
            userId: userId,
          },
        });

        if (res.status === 200) {
          const { data } = res.data;

          let ary = [];
          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].id,
              imgSrc: getImage(data[i].images),
              category: data[i].eventType,
              title: data[i].name,
              placeName: data[i].location,
              like: data[i].isLike === 1 ? true : false,
              address: "",
              period: {
                start: data[i].openDate,
                end: data[i].closeDate,
              },
            });
          }
          setEventList(ary);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    getPopularEventList(loginId);
  }, [type]);

  const setting = {
    dots: false,
    infinite: eventList.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="section-culture-event">
      <div className="container">
        <Slider {...setting} className="section-culture-event__box">
          {eventList.map((eventInfo) => (
            <div className="items" key={eventInfo.id}>
              <div className="item">
                <EventListItem eventInfo={eventInfo} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default PopularEventList;
