import React from "react";
import { Link } from "react-router-dom";

const dayArray = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일",
];

const addDot = (string) => {
  let result = "";
  return result.concat(
    string.slice(0, 4),
    ".",
    string.slice(4, 6),
    ".",
    string.slice(6)
  );
};
const ScheduleListItem = ({ schedule }) => {
  const dateFormat = () => {
    // let year,month, date, day ;

    let dateAry = [
      schedule.date.slice(0, 4),
      schedule.date.slice(4, 6),
      schedule.date.slice(6),
    ];

    return (
      <>
        <h2>
          {dateAry[1]}/{dateAry[2]}
        </h2>
        <small>{`${dateAry[0]} ${
          dayArray[new Date(addDot(schedule.date)).getDay()]
        }`}</small>
      </>
    );
  };

  return (
    <div
      className="item sc-event-list col-lg-6"
      style={{ marginBottom: "10px" }}
    >
      <div className="event-list-date">{dateFormat()}</div>
      <table>
        <tbody>
          {schedule.eventList.map((item) => (
            <tr key={item.time}>
              <td className="event-list-time">
                <p>{item.time}</p>
              </td>
              <td className="event-list-title">
                <Link to={`/event/event-detail/${item.eventId}`}>
                  <p>{item.eventTitle}</p>
                </Link>
                <small className=""></small>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleListItem;
