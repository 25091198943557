import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import MobileContext from "context/mobile";

import MainHeader from "../ect/MainHeader";
import MenuBar from "../ect/MenuBar";
import Footer from "../ect/Footer";

import MainBanner from "./MainBanner";
import MainSearch from "./MainSearch";
import HotClick from "./HotClick";
import NewEvent from "./NewEvent";
import FrequentlyVistedPlace from "./FrequentlyVisitedPlace";
import EnrollSection from "./EnrollSection";
import CountDown from "./CountDown";
import NoticeAndBanner from "./NoticeAndBanner";
import ScCultureVideo from "./ScCultureVideo";
import CollaboratorSite from "./CollaboratorSite";
import BottomNavigation from "components/ect/BottomNavigation";
import getVisitorInfo, { createUserVisitedLog } from "utils/utils";

const parseVideoId = (url) => {
  if (url.indexOf("watch?v=") === 24) {
    return url.slice(32, 43);
  } else {
    return url.slice(17, 28);
  }
};

const Main = () => {
  const [mainBanner, setMainBanner] = useState(null);
  const [noticeEventBanner, setNoticeEventBanner] = useState([]);
  const [scVideoList, setScVideoList] = useState({
    seoripulgallery: [],
    seoripulac: [],
    seochoFriConcert: [],
    seochoChamver: [],
  });

  const { actions, state } = useContext(MobileContext);

  useEffect(() => {
    if (!state.isMobile) {
      const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      };

      actions.setIsMobile(isMobile());
    }

    const getMainInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api/main/theme`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          const { main, banners, videos1, videos2, videos3, videos4 } =
            res.data;

          setMainBanner(main);
          setNoticeEventBanner(banners);

          let seoripulgallery = [];

          for (let i = 0; i < videos1.length; i++) {
            seoripulgallery.push({
              id: videos1[i].id,
              vId: parseVideoId(videos1[i].video),
            });
          }

          let seoripulac = [];

          for (let i = 0; i < videos2.length; i++) {
            seoripulac.push({
              id: videos2[i].id,
              vId: parseVideoId(videos2[i].video),
            });
          }

          let seochoFriConcert = [];

          for (let i = 0; i < videos3.length; i++) {
            seochoFriConcert.push({
              id: videos3[i].id,
              vId: parseVideoId(videos3[i].video),
            });
          }

          let seochoChamver = [];

          for (let i = 0; i < videos4.length; i++) {
            seochoChamver.push({
              id: videos4[i].id,
              vId: parseVideoId(videos4[i].video),
            });
          }

          setScVideoList({
            seoripulgallery: seoripulgallery,
            seoripulac: seoripulac,
            seochoFriConcert: seochoFriConcert,
            seochoChamver: seochoChamver,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getMainInfo();

    // 방문기록이 없다면
    if (window.sessionStorage.getItem("isFirstVisit") === "2") {
      createUserVisitedLog(window.sessionStorage.getItem("userid"));
    } else if (window.sessionStorage.getItem("isFirstVisit") !== "1") {
      getVisitorInfo().then((res) => {
        // 방문 기록 추가 요청
        createUserVisitedLog(res);
      });
    }
  }, []);

  return (
    <div id="container">
      <MainHeader />
      <MenuBar />
      <MainBanner banner={mainBanner} />
      {/* <MainSearch />  --행사 맟춤 검색, 내주변 검색 숨김 */}
      <NewEvent title="신규 문화행사" />
      <HotClick title="핫클릭 문화행사" />
      <FrequentlyVistedPlace title="자주 찾는 문화공간" />
      {/*<EnrollSection />  --행사 및 문화공간 등록 숨김 */}
      <CountDown />
      {/*<NoticeAndBanner noticeEventBanner={noticeEventBanner} />  --공지사항(서초 문화소식) 및 배너 숨김 */}
      <ScCultureVideo scVideoList={scVideoList} />
      {/*<CollaboratorSite /> --공지사항(서초 문화소식) 및 배너 숨김 */}
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default Main;
