import React from "react";

const MyPageTitle = ({ pageTitle }) => {
  return (
    <div className="section-title-sm">
      <h2 className="listing-detail__content-title">{pageTitle}</h2>
      <hr />
    </div>
  );
};

export default MyPageTitle;
