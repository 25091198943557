import React, { useState } from "react";

import PageShareModal from "components/ect/shareBtn/PageShareModal";

const PlaceDetailFormTemplate = ({ initForm }) => {
  const getLabel = () => {
    switch (initForm.category) {
      case "HALL":
        return (
          <span className="place-post__date place-post__hall">공연장</span>
        );
      case "PRACTICE":
        return (
          <span className="place-post__date place-post__practice">연습실</span>
        );
      case "INSTRUMENT":
        return (
          <span className="place-post__date place-post__instrument">
            악기상점
          </span>
        );
      case "GALLERY":
        return (
          <span className="place-post__date place-post__gallery">
            미술관/갤러리
          </span>
        );
      default:
        return "";
    }
  };

  const [shareBtn, setShareBtn] = useState(false);
  const [likeBtn, setLikeBtn] = useState(initForm.like);
  const onToggleShareBtn = () => {
    setShareBtn(!shareBtn);
  };

  const closeShareModal = () => {
    onToggleShareBtn();
  };

  const onClickUnLikedBtn = () => {
    setLikeBtn(!likeBtn);
    // api 좋아요 누른 문화행사 삭제 요청
  };

  const onClickLikedBtn = () => {
    setLikeBtn(!likeBtn);
    // api 좋아요 누른 문화행사 추가 요청
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return `${process.env.REACT_APP_ADMIN_SERVICE}/${resources.images[0].url}`;
    } else {
      return process.env.PUBLIC_URL + "/upload/place-poster1.jpg";
    }
  };

  return (
    <>
      <div className="col-lg-4">
        <div className="sidebar">
          <div className="place-post">
            <div className="place-post__gal">
              <img src={getImage(initForm.imgSrc)} alt="문화공간 포스터" />
              {getLabel()}
            </div>
          </div>
          <div className="listing-detail__buttons listing-detail__buttons-icons">
            <a
              className={likeBtn ? "btn-default-active" : "btn-default"}
              onClick={
                window.location.href.includes("my-page")
                  ? null
                  : likeBtn
                  ? onClickUnLikedBtn
                  : onClickLikedBtn
              }
            >
              <i
                className={likeBtn ? "la la-heart" : "la la-heart-o"}
                aria-hidden="true"
              ></i>
            </a>
            <a
              className="btn-default"
              onClick={
                window.location.href.includes("my-page")
                  ? null
                  : onToggleShareBtn
              }
            >
              <i className="la la-share-alt" aria-hidden="true"></i>
            </a>
            {shareBtn ? (
              <PageShareModal
                closeShareModal={closeShareModal}
                url={window.location.href}
                title={initForm.title}
                address={initForm.address}
                category={
                  {
                    HALL: "공연장",
                    PRACTICE: "연습실",
                    INSTRUMENT: "악기상점",
                    GALLERY: "미술관/갤러리",
                  }[initForm.category]
                }
                imgUrl={getImage(initForm.imgSrc)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="listing-detail__content-box listing-detail__content-box-nopadding">
          <form className="add-listing__form">
            <div className="container">
              <dl>
                <h2 className="">{initForm.title}</h2>
                <dt>장소</dt>
                <dd>{initForm.title === "" ? <br /> : initForm.title}</dd>
                <dt>주소</dt>
                <dd>{initForm.address === "" ? <br /> : initForm.address}</dd>
                <dt>영업시간</dt>
                <dd>
                  {initForm.time.open} ~ {initForm.time.close}
                </dd>
                <dt>홈페이지</dt>
                <dd>
                  {initForm.homePage === "" ? (
                    <br />
                  ) : (
                    <a
                      href={initForm.homePage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {initForm.homePage}
                    </a>
                  )}
                </dd>
                <dt>전화번호</dt>
                <dd>{initForm.tel === "" ? <br /> : initForm.tel}</dd>
                <dt>휴무일</dt>
                <dd>{initForm.dayOff === "" ? <br /> : initForm.dayOff}</dd>
              </dl>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PlaceDetailFormTemplate;
