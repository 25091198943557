import React, { createContext, useState } from "react";

const MenuContext = createContext({
  state: {
    communityMenu: 0,
    myPageMenu: 0,
    loginMenu: 0,
  },
  actions: {
    setCommunityMenu: () => {},
    setMyPageMenu: () => {},
    setLoginMenu: () => {},
  },
});

const MenuProvider = ({ children }) => {
  const [communityMenu, setCommunityMenu] = useState(3);
  const [myPageMenu, setMyPageMenu] = useState(0);
  const [loginMenu, setLoginMenu] = useState(0);

  const value = {
    state: { communityMenu, myPageMenu, loginMenu },
    actions: { setCommunityMenu, setMyPageMenu, setLoginMenu },
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

const { Consumer: MenuConsumer } = MenuContext;

export { MenuProvider, MenuConsumer };

export default MenuContext;
