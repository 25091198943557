import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CheckablePlaceListItem = ({
  placeInfo,
  addCheckedItem,
  removeCheckedItem,
  isDisable,
}) => {
  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  const getCategoryLabel = () => {
    switch (placeInfo.category) {
      case "HALL":
        return (
          <span className="place-post__date place-post__hall">공연장</span>
        );

      case "PRACTICE":
        return (
          <span className="place-post__date place-post__practice">연습실</span>
        );

      case "INSTRUMENT":
        return (
          <span className="place-post__date place-post__instrument">
            악기상점
          </span>
        );

      case "GALLERY":
        return (
          <span className="place-post__date place-post__gallery">
            미술관/갤러리
          </span>
        );

      default:
        return "";
    }
  };

  const [checked, setChecked] = useState(false);

  const checkBox = useRef();

  const onChangeCheckBox = () => {
    if (checked === false) {
      setChecked(!checked);
      checkBox.current.checked = true;
      addCheckedItem(placeInfo.id);
    } else {
      setChecked(!checked);
      checkBox.current.checked = false;
      removeCheckedItem(placeInfo.id);
    }
  };

  useEffect(() => {
    if (isDisable) {
      setChecked(true);
      checkBox.current.checked = true;
    } else {
      setChecked(false);
      checkBox.current.checked = false;
    }
  }, [isDisable]);

  return (
    <div className="item">
      <div className="place-post">
        <div className="place-post__gal">
          <Link to={`/place/place-detail/${placeInfo.id}`}>
            <img src={getImgSrc(placeInfo.imgSrc)} alt="문화공간 포스터" />
          </Link>
          {getCategoryLabel()}
        </div>
        <div className="post_content1">
          <h2 className="post_title1">
            <Link to={`/place/place-detail/${placeInfo.id}`}>
              {placeInfo.title}
            </Link>
          </h2>
          <p className="place-post__description">{placeInfo.address}</p>
          <ul className="explore__form-checkbox-list">
            <li>
              <input
                className="explore__input-checkbox"
                type="checkbox"
                name="open-check"
                // id="open-check"
                ref={checkBox}
                onChange={(e) => onChangeCheckBox(e)}
              />
              <span className="explore__checkbox-style"></span>
              <span className="explore__checkbox-text">선택</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CheckablePlaceListItem;
