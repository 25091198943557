import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import MobileContext from "../../context/mobile";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import MyPageTitle from "./my-page-template/MyPageTitle";
import MyPageMenu from "./MyPageMenu";

import LikedEventList from "./my-page-template/LikedEventList";
import Paging from "components/ect/Paging";
import BottomNavigation from "components/ect/BottomNavigation";

const UserLikedEventView = () => {
  const { state } = useContext(MobileContext);
  const history = useHistory();

  const [curPage, setCurPage] = useState(1);

  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const [totalRows, setTotalRows] = useState(0);
  const [likedEventList, setLikedEventList] = useState([]);

  const getImage = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const getLikedEvent = async () => {
      const url = `${
        process.env.REACT_APP_USER_SERVICE
      }/api/like/user/${window.sessionStorage.getItem("userid")}`;

      try {
        const res = await axios.get(url, {
          params: {
            page: curPage,
          },
        });

        if (res.status === 200) {
          const { totalRows, data } = res.data;

          setTotalRows(totalRows);

          let ary = [];
          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].id,
              imgSrc: getImage(data[i].images),
              category: "performance",
              title: data[i].name,
              period: {
                start: data[i].openDate,
                end: data[i].closeDate,
              },
              placeName: data[i].location,
              like: true,
            });
          }

          setLikedEventList(ary);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getLikedEvent();

    return () => {
      document.body.removeChild(script);
    };
  }, [curPage, history]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />
            <div className="col-lg-10">
              <MyPageTitle
                pageTitle={`내가 좋아하는 문화행사 (${totalRows}건)`}
              />

              <LikedEventList eventList={likedEventList} />
              <div className="row">
                <div className="col-lg-12">
                  <div className="explore__box">
                    <Paging
                      pageNum={curPage}
                      getCurPage={getCurPage}
                      onePage={6}
                      all={totalRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default UserLikedEventView;
