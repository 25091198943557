import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Header from "../ect/Header";
import Footer from "../ect/Footer";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import MyPageMenu from "./MyPageMenu";
import MyEventReviewListItem from "./my-page-template/MyEventReviewListItem";
import MyPlaceReviewListItem from "./my-page-template/MyPlaceReviewListItem";
import Paging from "components/ect/Paging";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";

const UserReviewView = () => {
  const { state } = useContext(MobileContext);

  const history = useHistory();

  const [totalEventRows, setTotalEventRows] = useState(0);
  const [totalPlaceRows, setTotalPlaceRows] = useState(0);

  const [eventReviews, setEventReviews] = useState([]);

  const [placeReviews, setPlaceReviews] = useState([]);

  const [eventCurPage, setEventCurPage] = useState(1);
  const [placeCurPage, setPlaceCurPage] = useState(1);

  const getCurEventPage = (pageNum) => {
    setEventCurPage(pageNum);
  };

  const getCurPlacePage = (pageNum) => {
    setPlaceCurPage(pageNum);
  };

  const getImage = (images) => {
    if (images.length > 0) {
      return JSON.parse(images)[0].url;
    } else {
      return null;
    }
  };

  const deleteEventReview = (rId) => {
    deleteReview(rId, "event");
  };

  const deletePlaceReview = (rId) => {
    deleteReview(rId, "place");
  };

  const deleteReview = async (rId, type) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/admin/review/${rId}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        if (type === "event") getEventReviewList();
        else getPlaceReviewList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getEventReviewList = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/admin/review/event/user/${window.sessionStorage.getItem("userid")}`;
    try {
      const res = await axios.get(url, {
        params: {
          page: eventCurPage,
          count: 5,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalEventRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            eventInfo: {
              id: data[i].eventId,
              imgSrc: getImage(data[i].images),
              category: data[i].eventType,
              title: data[i].name,
              period: {
                start: data[i].openDate,
                end: data[i].closeDate,
              },
              placeName: data[i].location,
              like: data[i].isLike === 1 ? true : false,
            },
            reviewInfo: {
              id: data[i].id,
              user: data[i].createUid,
              date: data[i].reviewDate,
              content: data[i].content,
              stars: data[i].score,
            },
          });
        }

        setEventReviews(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [eventCurPage]);

  const getPlaceReviewList = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/admin/review/space/user/${window.sessionStorage.getItem("userid")}`;
    try {
      const res = await axios.get(url, {
        params: {
          page: placeCurPage,
          count: 5,
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalPlaceRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            placeInfo: {
              id: data[i].spaceId,
              imgSrc: getImage(data[i].images),
              category: data[i].spaceType,
              title: data[i].name,
              address: data[i].address1 + " " + data[i].address2,
            },
            reviewInfo: {
              id: data[i].id,
              user: data[i].createUid,
              date: data[i].reviewDate,
              content: data[i].content,
              stars: data[i].score,
            },
          });
        }

        setPlaceReviews(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [placeCurPage]);

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }

    getEventReviewList();
    getPlaceReviewList();
  }, [getEventReviewList, getPlaceReviewList, history]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />

            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-sm">
                    <h2 className="listing-detail__content-title">
                      문화행사 리뷰 ({totalEventRows}건)
                    </h2>
                    <hr />
                  </div>

                  <div className="explore__box">
                    {eventReviews.map((eventReview) => (
                      <MyEventReviewListItem
                        eventReview={eventReview}
                        key={eventReview.reviewInfo.id}
                        deleteEventReview={deleteEventReview}
                      />
                    ))}
                    <Paging
                      pageNum={eventCurPage}
                      getCurPage={getCurEventPage}
                      onePage={5}
                      all={totalEventRows}
                    />
                  </div>

                  <div className="section-title-sm">
                    <h2 className="listing-detail__content-title">
                      등록 신청한 문화공간 ({totalPlaceRows}건)
                    </h2>
                    <hr />
                  </div>

                  <div className="explore__box">
                    {placeReviews.map((placeReview) => (
                      <MyPlaceReviewListItem
                        placeReview={placeReview}
                        key={placeReview.reviewInfo.id}
                        deletePlaceReview={deletePlaceReview}
                      />
                    ))}

                    <Paging
                      pageNum={placeCurPage}
                      getCurPage={getCurPlacePage}
                      onePage={5}
                      all={totalPlaceRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default UserReviewView;
