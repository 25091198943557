import React, { useState, useEffect, useCallback } from "react";
import RelatedVideoForm from "./RelatedVideoForm";
import RelatedVideoList from "./RelatedVideoList";

const RelatedVideoContainer = ({ onChangeUrls, initVideoInfos }) => {
  const [id, setId] = useState(0);
  const [videoAry, setVideoAry] = useState(initVideoInfos);

  const getUrl = useCallback(
    (url) => {
      setVideoAry(
        videoAry.concat({
          vId: url,
          id: id,
        })
      );

      setId(id + 1);
    },
    [videoAry, id]
  );

  const deleteVideo = useCallback(
    (vId) => {
      let ary = videoAry;
      ary = ary.filter((url) => url.id !== vId);

      setVideoAry(ary);
    },
    [videoAry]
  );

  useEffect(() => {
    onChangeUrls(videoAry);
  }, [videoAry, onChangeUrls]);

  return (
    <div className="listing-detail__video element-waypoint" id="photos-box">
      <h2 className="listing-detail__content-title">관련 영상 업로드</h2>
      <RelatedVideoForm getUrl={getUrl} />
      <RelatedVideoList
        videoAry={videoAry}
        isEdit={true}
        deleteVideo={deleteVideo}
      />
    </div>
  );
};

export default RelatedVideoContainer;
