import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const NaverLoginBtn = () => {
  const history = useHistory();
  const initializeNaverLogin = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_LOGIN_API_KEY,
      callbackUrl: "https://culture.seocho.go.kr/common/log-in",
      isPopup: false,
      loginButton: { color: "white", type: 1, height: "47" },
    });
    naverLogin.init();
  };

  const getNaverToken = () => {
    if (window.location.href.includes("access_token")) {
      let data = new Object();
      data.accessToken = window.location.href.split("=")[1].split("&")[0];

      login(data);
    }
  };

  const login = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/sns/naver/login`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 200) {
        const { token, userid } = res.headers;

        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("userid", userid);

        // 로그인 인포
        window.sessionStorage.setItem("isFirstVisit", "2");

        history.push("/");
      }
    } catch (e) {
      console.log(" ====== 로그인 실패 ====== ");
      console.log(e);
    }
  };

  useEffect(() => {
    initializeNaverLogin();
    getNaverToken();
  }, []);

  return (
    <div
      id="naverIdLogin"
      style={{ position: "absolute", top: "-10000px" }}
    ></div>
  );
};

export default NaverLoginBtn;
