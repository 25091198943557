import React, { useState } from "react";

const SearchBar = ({ search }) => {
  const onSubmitForm = (e) => {
    e.preventDefault();
    search(searchKeyword);
  };

  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <form className="search-form btn-float-right" onSubmit={onSubmitForm}>
      <div className="search-form__input-holders">
        <input
          className="search-form__input"
          type="text"
          name="search-event"
          placeholder="검색..."
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
        />
      </div>
      <button className="btn-round-lg__pink" type="submit">
        검색{" "}
      </button>
    </form>
  );
};

export default SearchBar;
