import React, { useState, useRef } from "react";

const RelatedVideoForm = ({ getUrl }) => {
  const [url, setUrl] = useState("");
  const [isValidity, setIsValidity] = useState(true);

  const onClick = () => {
    if (isValidity && url !== "") getUrl(url);
    setUrl("");
  };

  const urlInput = useRef();
  const regUrlTest = (url) => {
    const regUrl =
      /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?$/;

    if (regUrl.test(url)) {
      if (!isValidity) setIsValidity(true);

      urlInput.current.style = "background:#FFFFFF; border: 1px solid #dddddd;";
    } else {
      if (isValidity) setIsValidity(false);

      urlInput.current.focus();
      urlInput.current.style = "background:#FBFBEF; border: 1px solid #ef5797;";
    }
  };
  const onChangeUrl = (e) => {
    regUrlTest(e.target.value);

    setUrl(e.target.value);
  };

  return (
    <div className="add-listing__form-box" id="social-box">
      <div className="add-listing__form-content row">
        <label className="add-listing__label" htmlFor="url">
          영상 <span>(url)</span>:{" "}
        </label>
        <div className="flex">
          <input
            className="add-listing__input"
            type="text"
            name="url"
            id="url"
            placeholder="관련 영상 유튜브 Url"
            onChange={(e) => {
              onChangeUrl(e);
            }}
            value={url}
            ref={urlInput}
          />
          {isValidity === false ? (
            <p
              style={{
                marginTop: "-15px",
                color: "#ef5797",
              }}
            >
              입력하신 url이 올바르지 않습니다
            </p>
          ) : (
            ""
          )}
        </div>
        <button
          className="button-sc__submit btn_float-right"
          type="button"
          onClick={onClick}
        >
          추가{" "}
        </button>
      </div>
    </div>
  );
};

export default RelatedVideoForm;
