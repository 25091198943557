import React from "react";
import { useHistory } from "react-router";

const replaceString = (string) => {
  return string.replace(/-/gi, ".");
};

const BannerListItem = ({ listItem }) => {
  const history = useHistory();

  const getLink = () => {
    if (listItem.type === "CULTURAL_EVENT")
      return `/event/event-detail/${listItem.content_id}`;
    else return `place/place-detail/${listItem.content_id}`;
  };

  const getImageSrc = (imgSrc) => {
    if (imgSrc !== null) {
      return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
    } else {
      return "/assets/images/stories/256_rsz_thomas-russell-751613-unsplash.jpg";
    }
  };

  return (
    <div
      className="item"
      onClick={() => history.push(getLink())}
      style={{
        cursor: "pointer",
      }}
    >
      <div className="event-gal">
        <img
          className="event-gal-image"
          src={getImageSrc(listItem.image)}
          alt="event-poster-image"
        />
        <div className="event-gal-content">
          <h2 className="event-gal-title">{listItem.content_name}</h2>
          <span className="event-gal-list-item">
            {listItem.content_location}
          </span>
          {Object.keys("listItem").includes("period") ? (
            <p className="sm_description">
              {replaceString(listItem.period.start)}~
              {replaceString(listItem.period.end)}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default BannerListItem;
