import React from "react";
import VideoListItem from "./VideoListItem";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoList = ({ videoList }) => {
  let setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...setting} className="sc-video__box">
      {videoList.map((videoInfo) => (
        <VideoListItem videoInfo={videoInfo} key={videoInfo.id} />
      ))}
    </Slider>
  );
};

export default VideoList;
