import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MenuContext from "../../context/menu";

const categories = [
  {
    name: "press-release",
    text: "서초 문화소식",
    id: 3,
  },
{/*
  {
    name: "notice-board",
    text: "공지사항",
    id: 0,
  },
   {
    name: "event-board",
    text: "이벤트",
    id: 1,
  },
  {
    name: "news-board",
    text: "문화계 소식",
    id: 2,
  },
  {
    name: "press-release",
    text: "보도자료",
    id: 3,
  },
*/} 
];

const CommunityMenu = React.memo(() => {
  const { actions, state } = useContext(MenuContext);

  const [menu, setMenu] = useState();
  const onHandleClick = (pick) => {
    actions.setCommunityMenu(pick);
  };

  useEffect(() => {
    setMenu(state.communityMenu);
  }, [state.communityMenu]);

  return (
    <div className="col-lg-2">
      <div className="comm-sidebar__widget">
        <div className="sub-left-menu">
          <ul className="sub-left-menu-list">
            {categories.map((category) => (
              <li>
                <Link
                  to={`/community/${category.name}`}
                  className={menu === category.id ? "active" : ""}
                  onClick={() => onHandleClick(category.id)}
                >
                  {category.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
});

export default React.memo(CommunityMenu);
