import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import axios from "axios";
import MobileContext from "context/mobile";

import Header from "components/ect/Header";
import MenuBar from "components/ect/MenuBar";
import Footer from "components/ect/Footer";
import BottomNavigation from "components/ect/BottomNavigation";

const styles = {
  cursor: "pointer",
};

const RelatedSiteDetail = ({ match }) => {
  const [siteInfo, setSiteInfo] = useState(null);
  const { state } = useContext(MobileContext);

  const getImageSrc = (image) => {
    if (image === null) {
      return null;
    }
    return `${process.env.REACT_APP_COMMUNITY_SERVICE}/related_sites/${image}`;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const { siteId } = match.params;

    const getPartnersDetailInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/partners/${siteId}`;

      try {
        const res = await axios.get(url);

        if (res.status === 200) {
          let linkAry = [];

          const { links } = res.data;
          for (let i = 0; i < links.length; i++) {
            linkAry.push({
              id: links[i].sortNumber,
              name: links[i].linkName,
              url: links[i].linkUrl,
              type: links[i].linkType,
            });
          }

          setSiteInfo({
            id: res.data.idx,
            title: res.data.title,
            logoImgSrc: res.data.realImagesPath,
            content: res.data.moreInformation,
            links: linkAry,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getPartnersDetailInfo();

    return () => {
      document.body.removeChild(script);
    };
  }, [match.params]);

  if (siteInfo === null) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <div className="container">
        <div className="section-title-lg section-title-first">
          <h1 className="listing-detail-page__content-title">
            {siteInfo.title}
          </h1>
          <div className="row">
            <div className="col-lg-3">
              <Link to={`/community/related-sites-detail/${siteInfo.id}`}>
                <img
                  className="related-sites__image"
                  src={getImageSrc(siteInfo.logoImgSrc)}
                  alt="related-sites-list-image"
                />
              </Link>
            </div>
            <div className="col-lg-9 related-sites-link">
              <div className="row">
                {siteInfo.links.map((linkInfo) => (
                  <div className="col-lg-4" key={linkInfo.id}>
                    <dl>
                      {
                        {
                          H: <i className="fa fa-home"></i>,
                          B: (
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          ),
                          Y: <i className="fa fa-youtube"></i>,
                          F: <i className="fa fa-facebook"></i>,
                          I: <i className="fa fa-instagram"></i>,
                          E: <i className="fa fa-link" aria-hidden="true"></i>,
                        }[linkInfo.type]
                      }
                      <a
                        onClick={() => window.open(`${linkInfo.url}`, "_blank")}
                        style={styles}
                      >
                        {linkInfo.name}
                      </a>
                    </dl>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className=" sc-content">
          <div className="sc-event-d-box">
            {/* <img src={siteInfo.contentImgUrl} /> */}
          </div>
          <p
            className="listing-detail__content-description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(siteInfo.content),
            }}
          ></p>
        </div>
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default RelatedSiteDetail;
