import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import ReservListItem from "components/ect/detail-page-template/ReservListItem";

const MyEventReservListItem = ({ eventReserv, deleteEventReserv }) => {
  const { eventInfo, reservInfo } = eventReserv;
  const [isFavorite, setIsFavorite] = useState();

  const getLabel = () => {
    switch (eventInfo.category) {
      case "SHOW":
        return <span className="event-post__rating">공연</span>;
      case "EXHIBITION":
        return <span className="event-post__rating solid-rat">전시</span>;
      case "OTHER":
        return <span className="event-post__rating solid-rat-other">기타</span>;
      default:
        return "";
    }
  };
  const replaceString = (string) => {
    return string.replace(/-/gi, ".");
  };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  const onClickUnLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}/${eventInfo.id}`;

    try {
      const res = await axios.delete(url);

      if (res.status === 200) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickLikedBtn = async () => {
    setIsFavorite(!isFavorite);

    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/like/user/${window.sessionStorage.getItem("userid")}`;

    let data = new Object();
    data.eventIds = [eventInfo.id];

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        console.log(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    setIsFavorite(eventInfo.like);

    return () => {
      document.body.removeChild(script);
    };
  }, [eventInfo.like]);

  return (
    <div className="explore__wrap iso-call">
      <div className="item col-lg-4">
        <div className="event-post">
          <div className="event-post__gal-box event-gal">
            <Link to={`/event/event-detail/${eventInfo}`}>
              <img
                className="event-gal-image"
                src={getImgSrc(eventInfo.imgSrc)}
                alt="문화행사 포스터"
              />
            </Link>
            {getLabel()}
            <button
              className={
                isFavorite ? "event-post__like active" : "event-post__like"
              }
              type="button"
              onClick={isFavorite ? onClickUnLikedBtn : onClickLikedBtn}
            >
              <i
                className={isFavorite ? "fa fa-heart" : "fa fa-heart-o"}
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div className="item col-lg-8">
        <div className="event-post">
          <div className="event-post__content">
            <p className="post__info-time">
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              {replaceString(eventInfo.period.start)}~
              {replaceString(eventInfo.period.end)}
            </p>
            <h2 className="event-post__title">
              <Link to={`/event/event-detail/${eventInfo.id}`}>
                {eventInfo.title}
              </Link>
            </h2>
            <p className="event-post__address">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              {eventInfo.placeName}
            </p>
          </div>
        </div>
        <br />
        <hr />
        <div className="listing-detail__reviews-box   reviews-list__item-content-mine">
          <ReservListItem
            reservInfo={reservInfo}
            deleteReserv={deleteEventReserv}
          />
        </div>
      </div>
    </div>
  );
};

export default MyEventReservListItem;
