import React from "react";
import UserReivewListItem from "./UserReviewListItem";

const UserReviewList = ({ reviewList, deleteReview }) => {
  return (
    <div className="listing-detail__reviews-box">
      <ul className="reviews-list">
        {reviewList.map((reviewInfo) =>
          reviewInfo.user === window.sessionStorage.getItem("userid") ? (
            <UserReivewListItem
              key={reviewInfo.id}
              reviewInfo={reviewInfo}
              deleteReview={deleteReview}
              isMyReview={true}
            />
          ) : (
            <UserReivewListItem
              key={reviewInfo.id}
              reviewInfo={reviewInfo}
              deleteReview={deleteReview}
              isMyReview={false}
            />
          )
        )}
      </ul>
    </div>
  );
};

export default UserReviewList;
