import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import MobileContext from "context/mobile";

import AddPostButton from "../common/Button/AddPostButton";
import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Editor from "../editor-video-template/Editor";
import RelatedVideoContainer from "../editor-video-template/RelatedVideoContainer";

import AddFilter from "./event-template/AddFilter";
import EventFormTemplate from "./event-template/EventFormTemplate";
import BottomNavigation from "components/ect/BottomNavigation";

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);

  let str = "" + date.getFullYear();

  if (date.getMonth() < 9) {
    str += "0" + (date.getMonth() + 1);
  } else {
    str += date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    str += "0" + date.getDate();
  } else {
    str += date.getDate();
  }
  return str;
};

const AddEventView = () => {
  const { state } = useContext(MobileContext);

  const [formInfo, setFormInfo] = useState({
    imgFile: null,
    title: "",
    category: "default",
    placeName: "default",
    address: "",
    detailAddress: "",
    period: {
      start: "",
      end: "",
    },
    time: {
      open: "default",
      close: "default",
    },
    age: "",
    homePage: "",
    reservPage: "",
    tel: "",
    price: "init",
  });
  const [detail, setDetail] = useState("");

  const [showFields, setShowFields] = useState([
    { id: "MUSICAL_OPERA", name: "뮤지컬/오페라", isActive: false },
    { id: "MUSIC_CONCERT", name: "음악/콘서트", isActive: false },
    { id: "PLAY", name: "연극", isActive: false },
    { id: "CHILD_DRAMA", name: "아동극/인형극", isActive: false },
    { id: "DANCE", name: "무용", isActive: false },
    { id: "OTHER", name: "기타", isActive: false },
    { id: "COME_KOREA", name: "내한", isActive: false },
    { id: "CLASSIC", name: "클래식", isActive: false },
    { id: "TRADITIONAL_ART", name: "전통예술", isActive: false },
    { id: "INDIE", name: "인디", isActive: false },
    { id: "JAZZ", name: "재즈", isActive: false },
    { id: "KPOP", name: "K-POP", isActive: false },
    { id: "ROCK", name: "Rock", isActive: false },
    { id: "BALLAD", name: "발라드", isActive: false },
    { id: "DANCE_POP", name: "댄스", isActive: false },
    { id: "RNB", name: "R&B/소울", isActive: false },
    { id: "HIPHOP", name: "힙합", isActive: false },
  ]);

  const [exhibitionFields, setExhibitionFields] = useState([
    { id: "KOREAN_WRITERS", name: "국내 작가", isActive: false },
    { id: "FOREIGN_WRITERS", name: "해외 작가", isActive: false },
    { id: "HISTORY", name: "역사", isActive: false },
    { id: "SCULPTURE", name: "조각", isActive: false },
    { id: "PHOTO", name: "사진", isActive: false },
    { id: "FOLK_ART", name: "전통미술", isActive: false },
    { id: "KOREAN_PAINTING", name: "한국화", isActive: false },
    { id: "ENGRAVING", name: "판화", isActive: false },
    { id: "WATERCOLOR", name: "수채화", isActive: false },
    { id: "CRAFTS", name: "공예", isActive: false },
    { id: "CALLIGRPHY", name: "서예", isActive: false },
    { id: "EXHIBITION_OTHER", name: "기타", isActive: false },
  ]);

  const [otherFields, setOtherFields] = useState([
    { id: "FESTIVAL", name: "축제", isActive: false },
    { id: "EXPERIENCE_EDUCATION", name: "체험/교육", isActive: false },
    { id: "MOVIE", name: "영화", isActive: false },
    { id: "OTHER_OTHER", name: "기타", isActive: false },
  ]);

  const [themeSeasons, setThemeSeasons] = useState([
    {
      id: "SPRING",
      name: "봄",
      isActive: false,
    },
    {
      id: "SUMMER",
      name: "여름",
      isActive: false,
    },
    {
      id: "FALL",
      name: "가을",
      isActive: false,
    },
    {
      id: "WINTER",
      name: "겨울",
      isActive: false,
    },
  ]);
  const [themeTimes, setThemeTimes] = useState([
    {
      id: "CHRISTMAS",
      name: "성탄절",
      isActive: false,
    },
    {
      id: "HOLIDAY",
      name: "명절",
      isActive: false,
    },
    {
      id: "ANNIVERSARY",
      name: "기념일",
      isActive: false,
    },
    {
      id: "CLOUDY_RAIN",
      name: "흐림/비",
      isActive: false,
    },
    {
      id: "SUNNY",
      name: "화창함",
      isActive: false,
    },
  ]);
  const [themeFeelings, setThemeFeelings] = useState([
    {
      id: "FUNNY",
      name: "재미있는",
      isActive: false,
    },
    {
      id: "UNIQUE",
      name: "이색적인",
      isActive: false,
    },
    {
      id: "LEARN",
      name: "학습",
      isActive: false,
    },
    {
      id: "IMPRESSIVE",
      name: "감동적인",
      isActive: false,
    },
  ]);
  const [themeParties, setThemeParties] = useState([
    {
      id: "ALONE",
      name: "나홀로",
      isActive: false,
    },
    {
      id: "FRIEND",
      name: "친구랑",
      isActive: false,
    },
    {
      id: "COUPLE",
      name: "커플",
      isActive: false,
    },
    {
      id: "KID",
      name: "아이랑",
      isActive: false,
    },
    {
      id: "PARENTS",
      name: "부모랑",
      isActive: false,
    },
    {
      id: "FAMILY",
      name: "가족",
      isActive: false,
    },
  ]);
  const [scEvents, setScEvents] = useState([]);

  const [videoAry, setVideoAry] = useState([]);

  const onSubmitEvent = () => {
    console.log("등록 요청");

    if (formInfo.imgFile === null) {
      alert("문화행사 이미지를 등록해주세요.");
      return;
    }

    let scEventActiveCount = 0;

    for (let i = 0; i < scEvents.length; i++) {
      if (scEvents[i].isActive) {
        scEventActiveCount++;
      }
    }

    if (scEventActiveCount > 1) {
      alert("서초구 축제는 하나만 입력할 수 있습니다");
      return;
    }

    const formData = new FormData();

    formData.append("file", formInfo.imgFile[0]);

    formData.append("name", formInfo.title);

    let inputText = true;
    for (let i = 0; i < placeOptions.length; i++) {
      if (formInfo.placeName === placeOptions[i].id) {
        inputText = false;
        formData.append("location", placeOptions[i].text);
        break;
      }
    }

    if (inputText) {
      formData.append("location", formInfo.placeName);
    }

    formData.append("address1", formInfo.address);
    formData.append("address2", formInfo.detailAddress);
    formData.append(
      "open_date",
      formInfo.period.start === ""
        ? ""
        : convertDateFormat(formInfo.period.start)
    );
    formData.append(
      "close_date",
      formInfo.period.end === "" ? "" : convertDateFormat(formInfo.period.end)
    );
    formData.append("age", formInfo.age);
    formData.append("homepage", formInfo.homePage);
    formData.append("reservation_site", formInfo.reservPage);
    formData.append("phone", formInfo.tel);
    formData.append("price", formInfo.price === "init" ? 0 : formInfo.price);
    formData.append("open_time", formInfo.time.open);
    formData.append("close_time", formInfo.time.close);
    formData.append("more_information", detail);
    formData.append("state", "WAIT");
    var ary;

    // field
    ary = new Array();

    switch (formInfo.category) {
      case "SHOW":
        for (let i = 0; i < showFields.length; i++) {
          if (showFields[i].isActive) {
            ary.push(showFields[i].id);
          }
        }
        break;
      case "EXHIBITION":
        for (let i = 0; i < exhibitionFields.length; i++) {
          if (exhibitionFields[i].isActive) {
            ary.push(exhibitionFields[i].id);
          }
        }
        break;
      case "OTHER":
        for (let i = 0; i < otherFields.length; i++) {
          if (otherFields[i].isActive) {
            ary.push(otherFields[i].id);
          }
        }
        break;
      default:
        break;
    }

    formData.append("event_field", ary);

    // theme
    for (let i = 0; i < themeSeasons.length; i++) {
      if (themeSeasons[i].isActive) {
        ary.push(themeSeasons[i].id);
      }
    }

    for (let i = 0; i < themeTimes.length; i++) {
      if (themeTimes[i].isActive) {
        ary.push(themeTimes[i].id);
      }
    }

    for (let i = 0; i < themeFeelings.length; i++) {
      if (themeFeelings[i].isActive) {
        ary.push(themeFeelings[i].id);
      }
    }

    for (let i = 0; i < themeParties.length; i++) {
      if (themeParties[i].isActive) {
        ary.push(themeParties[i].id);
      }
    }
    formData.append("event_theme", ary);

    for (let i = 0; i < scEvents.length; i++) {
      if (scEvents[i].isActive) {
        formData.append("festival_id", scEvents[i].id);
        break;
      }
    }

    formData.append("event_type", formInfo.category);

    ary = new Array();
    let temp;
    for (let i = 0; i < videoAry.length; i++) {
      temp = new Object();
      temp.url = videoAry[i].vId;
      ary.push(temp);
    }
    formData.append("videos", JSON.stringify(ary));

    formData.append("userid", window.sessionStorage.getItem("userid"));

    for (let key of formData.keys()) {
      console.log(key);
    }

    for (let value of formData.values()) {
      console.log(value);
    }

    requestPostEvent(formData);
  };

  const history = useHistory();
  const requestPostEvent = async (eventData) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/regist`;

    try {
      const res = await axios.post(url, eventData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        if (res.data.id === "") {
          alert("저장 도중 오류가 발생하였습니다. ");
        } else {
          console.log("===== SUCCESS POST EVENT =====");
          alert(
            "등록이 완료되었습니다.\n진행 상황은 '프로필 > 등록 신청' 메뉴에서 확인하실 수 있습니다."
          );
          history.push("/main");
        }
      }
    } catch (e) {
      console.log(" ====== POST ERROR ======");
      console.log(e);
    }
  };

  const getFormInfo = (dataName, data) => {
    if (dataName === "start" || dataName === "end") {
      setFormInfo({
        ...formInfo,
        period: {
          ...formInfo.period,
          [dataName]: data,
        },
      });
    } else if (dataName === "open" || dataName === "close") {
      setFormInfo({
        ...formInfo,
        time: {
          ...formInfo.time,
          [dataName]: data,
        },
      });
    } else {
      setFormInfo({
        ...formInfo,
        [dataName]: data,
      });
    }
  };

  const getDetail = useCallback((result) => {
    setDetail(result);
  }, []);

  const onChangeUrls = (videoAry) => {
    setVideoAry(videoAry);
  };

  const [placeOptions, setPlaceOptions] = useState([
    { id: "default", text: "장소 선택" },
  ]);

  const onChangeFilter = (filterAry, dataId) => {
    let ary = [];
    for (let i = 0; i < filterAry.length; i++) {
      if (filterAry[i].id === dataId) {
        ary.push({
          id: dataId,
          isActive: !filterAry[i].isActive,
          name: filterAry[i].name,
        });
      } else {
        ary.push(filterAry[i]);
      }
    }

    return ary;
  };

  const getFilterInfo = (filterName, dataId) => {
    switch (filterName) {
      case "show":
        setShowFields(onChangeFilter(showFields, dataId));
        break;
      case "exhibition":
        setExhibitionFields(onChangeFilter(exhibitionFields, dataId));
        break;
      case "other":
        setOtherFields(onChangeFilter(otherFields, dataId));
        break;
      case "themeSeasons":
        setThemeSeasons(onChangeFilter(themeSeasons, dataId));
        break;
      case "themeTimes":
        setThemeTimes(onChangeFilter(themeTimes, dataId));
        break;
      case "themeFeelings":
        setThemeFeelings(onChangeFilter(themeFeelings, dataId));
        break;
      case "themeParties":
        setThemeParties(onChangeFilter(themeParties, dataId));
        break;
      case "scEvents":
        setScEvents(onChangeFilter(scEvents, dataId));
        break;
      default:
        console.log(filterName, dataId);
        break;
    }
  };

  const onResetFilterAry = (filterAry) => {
    let ary = [];

    for (let i = 0; i < filterAry.length; i++) {
      ary.push({
        ...filterAry[i],
        isActive: false,
      });
    }

    return ary;
  };

  const resetFilters = () => {
    setShowFields(onResetFilterAry(showFields));
    setExhibitionFields(onResetFilterAry(exhibitionFields));
    setOtherFields(onResetFilterAry(otherFields));
    setThemeSeasons(onResetFilterAry(themeSeasons));
    setThemeTimes(onResetFilterAry(themeTimes));
    setThemeFeelings(onResetFilterAry(themeFeelings));
    setThemeParties(onResetFilterAry(themeParties));
    setScEvents(onResetFilterAry(scEvents));
  };

  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (
      !onConfirm ||
      typeof onConfirm !== "function" ||
      !onCancel ||
      typeof onCancel !== "function"
    ) {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };
    return confirmAction;
  };

  const onConfirmMoveLogin = () => {
    history.push("/common/log-in");
  };

  const onCancelGoback = () => {
    history.goBack();
  };

  const comfirmLogin = useConfirm(
    "로그인 후에 이용하실 수 있습니다.\n로그인 화면으로 이동하시겠습니까?",
    onConfirmMoveLogin,
    onCancelGoback
  );

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      comfirmLogin();
    }

    const getPlaceList = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list`;

      try {
        const response = await axios.get(url, {
          params: {
            sort_type: "desc",
            sort_column: "create_date",
            page: 1,
            count: 1000,
            search_type: "STATE",
            search_word: "POST",
            // userid: window.sessionStorage.getItem("userid"),
          },
        });

        if (response.status === 200) {
          let options = [];
          options.push({ id: "default", text: "장소 선택" });

          for (let i = 0; i < response.data.list.length; i++) {
            options.push({
              id: response.data.list[i].id + i,
              text: response.data.list[i].name,
            });
          }
          setPlaceOptions(options);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getScEventList = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/seochogu-festival/list`;

      try {
        const res = await axios.get(url, {
          params: {
            page: 1,
            count: 1000,
            search_type: "STATE",
            search_word: "POST",
          },
        });

        if (res.status === 200) {
          const { list } = res.data;

          const today = new Date();
          let fromDate = "" + today.getFullYear();

          if (today.getMonth() < 9) {
            fromDate += "0" + (today.getMonth() + 1);
          } else {
            fromDate += today.getMonth() + 1;
          }

          if (today.getDate() < 10) {
            fromDate += "0" + today.getDate();
          } else {
            fromDate += today.getDate();
          }

          let ary = [];

          // 끝나지 않은 서초구 축제만
          for (let i = 0; i < list.length; i++) {
            if (fromDate <= list[i].close_date) {
              ary.push({
                id: list[i].id,
                name: list[i].name,
                isActive: false,
              });
            }
          }

          setScEvents(ary);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getScEventList();
    getPlaceList();
  }, []);

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <h1 className="page__title page__title-black">
                <span>문화행사 등록하기</span>
              </h1>
              <p className="listing-detail__subtitle">
                <Link to="/main">홈</Link> /{" "}
                <Link to="/event/event-all">문화행사</Link> /{" "}
                <span className="active">문화행사 등록하기</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="listing-detail add-listing">
        <div className="listing-detail__content">
          <div className="container">
            <div className="container">
              <EventFormTemplate
                getFormInfo={getFormInfo}
                formInfo={formInfo}
                placeOptions={placeOptions}
              />
              <AddFilter
                showFields={showFields}
                exhibitionFields={exhibitionFields}
                otherFields={otherFields}
                themeSeasons={themeSeasons}
                themeTimes={themeTimes}
                themeFeelings={themeFeelings}
                themeParties={themeParties}
                scEvents={scEvents}
                getFilterInfo={getFilterInfo}
                resetFilters={resetFilters}
                category={formInfo.category}
              />

              <div className="row">
                <div className="col-lg-12">
                  <div className="listing-detail__content-box listing-detail__content-box-nopadding">
                    <Editor
                      editorTitle="상세정보"
                      getDetail={getDetail}
                      initDetail={detail}
                    />
                    <RelatedVideoContainer
                      onChangeUrls={onChangeUrls}
                      initVideoInfos={videoAry}
                    />

                    <AddPostButton onSubmitEvent={onSubmitEvent} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default AddEventView;
