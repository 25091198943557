import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import MobileContext from "context/mobile";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import EventList from "./event-template/EventList";
import ScheduleList from "./event-template/ScheduleList";
import BottomNavigation from "components/ect/BottomNavigation";

const addDot = (string) => {
  let result = "";
  return result.concat(
    string.slice(0, 4),
    ".",
    string.slice(4, 6),
    ".",
    string.slice(6)
  );
};

const dateFormat = (date) => {
  let fromDate = "" + date.getFullYear();

  if (date.getMonth() < 9) {
    fromDate += "0" + (date.getMonth() + 1);
  } else {
    fromDate += date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    fromDate += "0" + date.getDate();
  } else {
    fromDate += date.getDate();
  }

  return fromDate;
};

const isSameDate = (day1, day2) => {
  if (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

const timeFormat = (timeString) => {
  if (timeString.includes("오전")) {
    return timeString.replaceAll(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣\s]/g, "");
  } else if (timeString.includes("오후")) {
    let timeAry = timeString
      .replaceAll(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣\s]/g, "")
      .split(":");

    let time = parseInt(timeAry[0]) + 12 === 24 ? 0 : parseInt(timeAry[0]) + 12;
    time += ":" + timeAry[1];

    return time;
  }

  return timeString;
};

const ScEventDetailView = ({ match }) => {
  const { state } = useContext(MobileContext);
  const [detailInfo, setDetailInfo] = useState(null);
  const [curPage, setCurPage] = useState(1);

  const getNextList = () => {
    setCurPage(curPage + 1);
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  const getImageSrc = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return `${process.env.REACT_APP_ADMIN_SERVICE}${resources.images[0].url}`;
    } else {
      return process.env.PUBLIC_URL + "/upload/seocho-img@2x.jpg";
    }
  };

  const getSchedulFormat = (list) => {
    if (list.length === 0) {
      return [];
    }

    const scheduleList = [];

    const { start, end } = detailInfo.period;

    const startDate = new Date(addDot(start));
    const endDate = new Date(addDot(end));

    let date = startDate;
    while (true) {
      scheduleList.push({
        date: dateFormat(date),
        eventList: [],
      });

      if (isSameDate(date, endDate)) {
        break;
      } else {
        date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 1
        );
      }
    }

    console.log(scheduleList);

    // 시작일이 같다면 더 빠른 시간으로 정렬
    let eventList = list.sort((event1, event2) => {
      if (event1.period.start < event2.period.start) {
        return -1;
      } else if (event1.period.start > event2.period.start) {
        return 1;
      } else {
        if (event1.time < event2.time) {
          return -1;
        } else if (event1.time > event2.time) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    console.log(eventList);

    for (let i = 0; i < eventList.length; i++) {
      for (let j = 0; j < scheduleList.length; j++) {
        if (
          eventList[i].period.start <= scheduleList[j].date &&
          scheduleList[j].date <= eventList[i].period.end
        ) {
          scheduleList[j].eventList.push({
            time: eventList[i].time,
            eventTitle: eventList[i].title,
            eventId: eventList[i].id,
          });
        }
      }
    }

    console.log(scheduleList);

    return scheduleList;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const { eventId } = match.params;

    // 상세조회
    const getScEventDetail = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/seochogu-festival/detail/${eventId}`;

      try {
        const res = await axios.get(url, {
          params: {
            loginid: window.sessionStorage.getItem("userid"),
          },
        });

        if (res.status === 200) {
          const { related_event_list2 } = res.data;
          let ary = [];

          for (let i = 0; i < related_event_list2.length; i++) {
            ary.push({
              id: related_event_list2[i].id,
              imgSrc: getImage(related_event_list2[i].resources),
              category: related_event_list2[i].event_type,
              title: related_event_list2[i].name,
              placeName: related_event_list2[i].location,
              like: related_event_list2[i].is_like === 1 ? true : false,
              address: "",
              period: {
                start: related_event_list2[i].open_date,
                end: related_event_list2[i].close_date,
              },
              time: timeFormat(related_event_list2[i].open_time),
            });
          }

          setDetailInfo({
            id: res.data.id,
            bannerImg: res.data.resources,
            title: res.data.name,
            period: {
              start: res.data.open_date,
              end: res.data.close_date,
            },
            description: res.data.description,
            scheduleList: ary,
            content: res.data.more_information,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getScEventDetail();

    // getScEventList();

    return () => {
      document.body.removeChild(script);
    };
  }, [match.params]);

  if (detailInfo === null) {
    return null;
  }

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <div className="container">
        <div className="section-title-lg section-title-first">
          <h1 className="listing-detail-page__content-title">
            {detailInfo.title}
          </h1>
          <p className="post__info-time">
            <i className="fa fa-clock-o" aria-hidden="true"></i>
            {addDot(detailInfo.period.start)}~{addDot(detailInfo.period.end)}
          </p>
          <p className="listing-detail__content-description">
            {detailInfo.description}
          </p>
        </div>
        <div className="sc-content">
          <div className="sc-event-d-box">
            <img
              src={getImageSrc(detailInfo.bannerImg)}
              alt="sc-place-banner"
            />
          </div>
          <br />
          <div
            className="listing-detail__content-description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(detailInfo.content),
            }}
          ></div>
        </div>

        {detailInfo.scheduleList.length > 0 ? (
          <ScheduleList
            scheduleList={getSchedulFormat(detailInfo.scheduleList)}
          />
        ) : null}

        <div className="listing-detail__content-box">
          <div className="section-title-sm">
            <h2 className="listing-detail__content-title">관련 문화행사</h2>
            <hr />
          </div>
          <EventList list={detailInfo.scheduleList} />

          <div className="center-button">
            <a className="btn-default btn-default-red" onClick={getNextList}>
              <i className="fa fa-refresh"></i>
              더보기
            </a>
          </div>
        </div>
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default ScEventDetailView;
