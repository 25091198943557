import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import MobileContext from "context/mobile";

import Header from "../ect/Header";
import Footer from "../ect/Footer";
import MenuBar from "../ect/MenuBar";

import PlaceFormTemplate from "./place-template/PlaceFormTemplate";
import Editor from "../editor-video-template/Editor";
import RelatedVideoContainer from "../editor-video-template/RelatedVideoContainer";
import AddPostButton from "components/common/Button/AddPostButton";
import BottomNavigation from "components/ect/BottomNavigation";

const AddPlaceView = () => {
  const { state } = useContext(MobileContext);

  const [formInfo, setFormInfo] = useState({
    imgFile: null,
    title: "",
    category: "default",
    address: "",
    detailAddress: "",
    time: {
      open: "default",
      close: "default",
    },
    homePage: "",
    tel: "",
    dayOff: "",
  });
  const getFormInfo = (dataName, data) => {
    if (dataName === "open" || dataName === "close") {
      setFormInfo({
        ...formInfo,
        time: {
          ...formInfo.time,
          [dataName]: data,
        },
      });
    } else {
      setFormInfo({
        ...formInfo,
        [dataName]: data,
      });
    }
  };

  const [detail, setDetail] = useState("");
  const getDetail = useCallback((text) => {
    setDetail(text);
  }, []);

  const [videoAry, setVideoAry] = useState([]);
  const onChangeUrls = (videoAry) => {
    setVideoAry(videoAry);
  };

  const onSubmitEvent = () => {
    console.log("등록 요청");

    if (formInfo.imgFile === null) {
      alert("문화행사 이미지를 등록해주세요.");
      return;
    }

    const formData = new FormData();

    formData.append("file", formInfo.imgFile[0]);

    formData.append("name", formInfo.title);
    formData.append("location", formInfo.title);

    formData.append("space_type", formInfo.category);

    formData.append("address1", formInfo.address);
    formData.append("address2", formInfo.detailAddress);

    formData.append("open_time", formInfo.time.open);
    formData.append("close_time", formInfo.time.close);

    formData.append("homepage", formInfo.homePage);

    formData.append("phone", formInfo.tel);

    formData.append("holiday", formInfo.dayOff);

    formData.append("more_information", detail);

    formData.append("state", "WAIT");

    let ary = new Array();
    let temp;
    for (let i = 0; i < videoAry.length; i++) {
      temp = new Object();
      temp.url = videoAry[i].vId;
      ary.push(temp);
    }
    formData.append("videos", JSON.stringify(ary));

    formData.append("userid", window.sessionStorage.getItem("userid"));

    requestPostPlace(formData);
  };

  const history = useHistory();
  const requestPostPlace = async (formData) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/regist`;

    try {
      const res = await axios.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        if (res.data.id === "") {
          alert("저장 도중 오류가 발생하였습니다. ");
        } else {
          console.log("===== SUCCESS POST PLACE =====");
          alert(
            "등록이 완료되었습니다.\n진행 상황은 '프로필 > 등록 신청' 메뉴에서 확인하실 수 있습니다."
          );
          history.push("/main");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (
      !onConfirm ||
      typeof onConfirm !== "function" ||
      !onCancel ||
      typeof onCancel !== "function"
    ) {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };
    return confirmAction;
  };

  const onConfirmMoveLogin = () => {
    history.push("/common/log-in");
  };

  const onCancelGoback = () => {
    history.goBack();
  };

  const comfirmLogin = useConfirm(
    "로그인 후에 이용하실 수 있습니다.\n로그인 화면으로 이동하시겠습니까?",
    onConfirmMoveLogin,
    onCancelGoback
  );

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      comfirmLogin();
    }
  }, [comfirmLogin]);

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <h1 className="page__title page__title-black">
                <span>문화공간 등록하기</span>
              </h1>
              <p className="listing-detail__subtitle">
                <Link to="/main">홈</Link> /{" "}
                <Link to="/place/place-all">문화행사</Link> /{" "}
                <span className="active">문화공간 등록하기</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="listing-detail add-listing">
        <div className="listing-detail__content">
          <div className="container">
            <div className="container">
              <PlaceFormTemplate
                getFormInfo={getFormInfo}
                formInfo={formInfo}
              />

              <div className="row">
                <div className="col-lg-12">
                  <div className="listing-detail__content-box listing-detail__content-box-nopadding">
                    <Editor
                      editorTitle="상세정보"
                      getDetail={getDetail}
                      initDetail={detail}
                    />
                    <RelatedVideoContainer
                      onChangeUrls={onChangeUrls}
                      initVideoInfos={videoAry}
                    />

                    <AddPostButton onSubmitEvent={onSubmitEvent} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default AddPlaceView;
