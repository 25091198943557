import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const FacebookLoginBtn = () => {
  const history = useHistory();

  const onSeuccessToLogin = (response) => {
    const { name, accessToken, email } = response;

    if (email === "undefined" || typeof email === undefined) {
      alert(
        "페이스북에 등록된 이메일이 유효하지 않습니다.\n이메일을 확인해주세요."
      );
      return;
    }

    let data = new Object();

    data.name = name;
    data.accessToken = accessToken;
    data.email = email;

    login(data);
  };

  const login = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/sns/fb/login`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 200) {
        const { token, userid } = res.headers;

        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("userid", userid);

        // 로그인 인포
        window.sessionStorage.setItem("isFirstVisit", "2");

        history.push("/");
      }
    } catch (e) {
      alert("로그인에 실패하였습니다.");
      console.log(e);
    }
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      reauthenticate={true}
      fields="name, email"
      disableMobileRedirect={true}
      callback={onSeuccessToLogin}
      render={(renderProps) => (
        <a
          className="facebook"
          onClick={renderProps.onClick}
          style={{
            cursor: "pointer",
          }}
        >
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </a>
      )}
    />
  );
};

export default FacebookLoginBtn;
