import React, { useState, useEffect } from "react";

import VideoList from "./cultureVideo-template/VideoList";

const ScCultureVideo = ({ scVideoList }) => {
  const [menu, setMenu] = useState(0);
  const onChangeMenu = (menuNum) => {
    setMenu(menuNum);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="section-seocho-video">
      <div className="container">
        <div className="section-header">
          <h1 className="section-header__title">서초 문화영상</h1>
        </div>

        <ul className="tabs-block2__list">
          <li className="tabs-block2__list-item">
            <a
              className={menu === 0 ? "active-list" : ""}
              onClick={() => {
                onChangeMenu(0);
              }}
            >
              서리풀청년아트갤러리
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 1 ? "active-list" : ""}
              onClick={() => {
                onChangeMenu(1);
              }}
            >
              서리풀악기거리
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 2 ? "active-list" : ""}
              onClick={() => {
                onChangeMenu(2);
              }}
            >
              서초금요음악회
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 3 ? "active-list" : ""}
              onClick={() => {
                onChangeMenu(3);
              }}
            >
              서초실내악축제
            </a>
          </li>
        </ul>
        <VideoList
          videoList={
            {
              0: scVideoList.seoripulgallery,
              1: scVideoList.seoripulac,
              2: scVideoList.seochoFriConcert,
              3: scVideoList.seochoChamver,
            }[menu]
          }
        />
      </div>
    </section>
  );
};

export default ScCultureVideo;
