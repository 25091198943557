import React, { useRef } from "react";
import YouTube from "react-youtube";

const parseVideoId = (url) => {
  if (url.indexOf("watch?v=") === 24) {
    return url.slice(32, 43);
  } else {
    return url.slice(17, 28);
  }
};

const RelatedVideoListItem = ({ videoInfo, isEdit, deleteVideo }) => {
  const onHandleClick = () => {
    deleteVideo(videoInfo.id);
  };

  const opts = {
    height: "100%",
    width: "100%",
  };

  const titleSpan = useRef();

  const onReady = (e) => {
    titleSpan.current.innerText = e.target.playerInfo.videoData.title;
  };

  return (
    <div className="item">
      <YouTube
        videoId={parseVideoId(videoInfo.vId)}
        opts={opts}
        onReady={onReady}
      />
      <span ref={titleSpan}></span>
      {isEdit ? (
        <input
          type="button"
          name="btn_js_confirm"
          id="btn_js_confirm"
          className="btn-square-sm__gray btn_float-right"
          onClick={onHandleClick}
          value="삭제"
        />
      ) : null}
    </div>
  );
};

export default RelatedVideoListItem;
