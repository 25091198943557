import axios from "axios";

const getVisitorInfo = async () => {
  const ipData = await fetch("https://geolocation-db.com/json/");
  const locationIp = await ipData.json();

  return locationIp.IPv4;
};

const createUserVisitedLog = async (visitorInfo) => {
  const url = `${process.env.REACT_APP_USER_SERVICE}/api/visit-log`;

  try {
    const response = await axios.post(url, null, {
      params: {
        visitor: visitorInfo,
      },
    });

    if (response.status === 200) {
      window.sessionStorage.setItem("isFirstVisit", "1");
    }
  } catch (e) {
    console.log(e);
    alert("사용자 방문 로그 생성 중, 오류가 발생하였습니다.");
  }
};

export default getVisitorInfo;
export { createUserVisitedLog };
