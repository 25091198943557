import React, { createContext, useState } from "react";

const MobileContext = createContext({
  state: {
    isMobile: false,
  },
  actions: {
    setIsMobile: () => {},
  },
});

const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  const value = {
    state: { isMobile },
    actions: { setIsMobile },
  };

  return (
    <MobileContext.Provider value={value}>{children}</MobileContext.Provider>
  );
};

const { Consumer: MobileConsumer } = MobileContext;

export { MobileProvider, MobileConsumer };

export default MobileContext;
