import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Footer from "../ect/Footer";
import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import PageTitle from "../ect/PageTitle";
import MyPageMenu from "./MyPageMenu";
import MobileContext from "context/mobile";
import BottomNavigation from "components/ect/BottomNavigation";
import useConfirm from "utils/useConfirm";

const UserInfoWithdrawal = () => {
  const { state } = useContext(MobileContext);
  const history = useHistory();

  const requestWithdrawal = async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const response = await axios.delete(url);

      if (response.status === 200) {
        alert("탈퇴되었습니다.");

        window.sessionStorage.removeItem("userid");
        window.sessionStorage.removeItem("token");

        history.push("/");
      }
    } catch (e) {
      console.log(e);
      alert("회원 탈퇴 처리 중, 오류가 발생하였습니다.");
    }
  };

  const onClickWithdrawalBtn = useConfirm(
    "정말 탈퇴하시겠습니까?",
    requestWithdrawal
  );

  useEffect(() => {
    const userid = window.sessionStorage.getItem("userid");
    const token = window.sessionStorage.getItem("token");

    if (
      userid === null ||
      userid === undefined ||
      token === null ||
      token === undefined
    ) {
      alert("로그인 후에 이용하실 수 있습니다. ");
      history.push("/common/log-in");
    }
  }, [history]);

  return (
    <div id="container">
      <Header />
      <MenuBar />
      <PageTitle pageTitle="프로필" />

      <section className="explore">
        <div className="container">
          <div className="row">
            <MyPageMenu />

            <div className="col-lg-10">
              <div className="section-title-sm">
                <h2 className="listing-detail__content-title">
                  회원 탈퇴하기{" "}
                </h2>
                <hr />
              </div>

              <div
                className="add-listing__form-box element-waypoint"
                id="general-info"
              >
                <div className="add-listing__form-content">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="add-listing__label" htmlFor="list-id">
                        회원탈퇴 안내{" "}
                      </label>
                    </div>
                    <div className="flex">
                      <label className="add-listing__label" htmlFor="list-id">
                        회원탈퇴 시 회원정보 및 서비스 이용기록은 모두
                        삭제됩니다. 또한 회원님 아이디로 이용하셨던 서비스의
                        이용이 모두 불가능하게 됩니다.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="center-button">
                <input
                  type="button"
                  name="btn_js_alert"
                  id="btn_js_alert"
                  className="btn-round-lg__border-gray"
                  value="취소"
                  onClick={() => history.goBack()}
                />
                <input
                  type="button"
                  name="btn_js_alert"
                  id="btn_js_confirm"
                  className="btn-round-lg__pink"
                  value="회원탈퇴"
                  onClick={() => onClickWithdrawalBtn()}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default UserInfoWithdrawal;
