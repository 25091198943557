import React from "react";
import Paging from "components/ect/Paging";

const SearchResultList = ({
  listTitle,
  resultList,
  ListComponent,
  curPage,
  getCurPage,
  onePage,
  totalCount,
  isFestival,
}) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="explore__box">
          <div className="section-title-lg2 ">
            <h1 className="listing-detail-page__content-title">{listTitle}</h1>
            <h2 className="explore__filter-title">
              <span>총</span> {totalCount}건
            </h2>
          </div>

          <ListComponent list={resultList} isFestival={isFestival} />
          <Paging
            pageNum={curPage}
            getCurPage={getCurPage}
            onePage={onePage}
            all={totalCount}
          />
        </div>
      </div>
    </div>
  );
};
export default SearchResultList;
