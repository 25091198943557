import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import KakaoShareBtn from "./KakaoShareBtn";
import { FacebookShareButton, FacebookIcon } from "react-share";

const PageShareModal = ({
  closeShareModal,
  url,
  title,
  address,
  category,
  imgUrl,
}) => {
  const [copyUrl, setCopyUrl] = useState(false);
  const urlInput = useRef();
  const onHandleCopyUrl = () => {
    const element = urlInput.current;
    element.focus();
    element.select();
    document.execCommand("copy");
    setCopyUrl(true);

    setTimeout(() => {
      closeShareModal();
    }, 3000);
  };

  return (
    <div
      className="modal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="bg"
        style={{
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.6)",
        }}
      ></div>
      <div
        className="modalBox"
        style={{
          position: "absolute",
          background: "#fff",
          width: "480px",
          height: "260px",
          padding: "15px",
          paddingTop: "15px",
          border: "1px solid white",
          borderRadius: "13px",
        }}
      >
        <div
          style={{
            display: "flex",
            float: "left",
            width: "100%",
          }}
        >
          <p
            style={{
              color: "#404040",
            }}
          >
            공유하기
          </p>
        </div>

        <hr
          style={{
            marginTop: "32px",
            marginBottom: "25px",
            border: "1px solid #f169a2",
          }}
        />

        <div
          className="infoBox-close"
          onClick={closeShareModal}
          style={{
            top: "-13px",
            right: "-15px",
          }}
        >
          <i className="fa fa-times"></i>
        </div>
        <div>
          {copyUrl ? (
            <div
              style={{
                textAlign: "center",
                position: "absolute",
                top: "39%",
                left: "25%",
              }}
            >
              <span>
                주소가 복사되었습니다.
                <br />
                원하는 곳에 붙여넣기(Ctrl+v)해주세요.
              </span>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div
                style={{
                  paddingBottom: "25px",
                }}
              >
                <Grid container spacig={2}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <KakaoShareBtn
                      title={title}
                      url={url}
                      address={address}
                      category={category}
                      imgUrl={imgUrl}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <FacebookShareButton
                      url="https://www.seochocf.or.kr"
                      style={{
                        height: "90px",
                        width: "90px",
                        background: "white",
                        border: "3px solid rgb(230, 230, 230)",
                        borderRadius: "13px",
                        paddingTop: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <FacebookIcon size={68.8} round={true}></FacebookIcon>
                    </FacebookShareButton>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <button
                      style={{
                        height: "90px",
                        width: "90px",
                        background: "white",
                        border: "3px solid rgb(230, 230, 230)",
                        borderRadius: "13px",
                        cursor: "pointer",
                      }}
                      onClick={onHandleCopyUrl}
                    >
                      <i className="fa fa-link fa-3x" aria-hidden="true"></i>
                    </button>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  ref={urlInput}
                  readOnly={true}
                  value={url}
                  onClick={onHandleCopyUrl}
                  style={{
                    height: "40px",
                    width: "370px",
                    textAlign: "center",
                    color: "#000066",
                    border: "1px solid rgb(235, 235, 235)",
                    borderRadius: "5px",
                    background: "rgb(235, 235, 235)",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageShareModal;
