import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import BlogReviewListItem from "./BlogReviewListItem";
import Paging from "../Paging";

const BlogReviewList = ({ keyword }) => {
  const [reviewList, setReviewList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [curPage, setCurPage] = useState(1);

  const getCurPage = (pageNum) => {
    setCurPage(pageNum);
  };

  const getBlogReviews = useCallback(async () => {
    const url = `${process.env.REACT_APP_CULTURE_SERVICE}/api/admin/naver/blog`;

    const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    try {
      const res = await axios.get(url, {
        params: {
          keyword: keyword.replace(reg, ""),
        },
      });

      if (res.status === 200) {
        const { totalRows, data } = res.data;

        setTotalRows(totalRows);

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: i,
            // imgSrc: process.env.PUBLIC_URL + "/upload/avatar2@2x.jpg",
            userName: data[i].bloggername,
            date: data[i].postdate,
            url: data[i].link,
            title: data[i].title,
            description: data[i].description,
          });
        }

        setReviewList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [keyword]);

  useEffect(() => {
    getBlogReviews();
  }, [getBlogReviews]);

  return (
    <div>
      <div className="listing-detail__reviews" id="tips-reviews-box">
        <h2 className="listing-detail__content-title">
          블로그({totalRows}
          건)
        </h2>
        <div className="listing-detail__reviews-box">
          <ul className="reviews-list">
            {reviewList.map((reviewInfo) => (
              <BlogReviewListItem reviewInfo={reviewInfo} key={reviewInfo.id} />
            ))}
          </ul>
          {/* <Paging pageNum={curPage} getCurPage={getCurPage} onePage={10} /> */}
        </div>
      </div>
    </div>
  );
};

export default BlogReviewList;
