import React from "react";
import { useHistory } from "react-router";

const ReRegistorPostButton = ({ onSubmitEvent, deleteDismissal }) => {
  const history = useHistory();

  const useConfirm = (message = null, onConfirm) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      }
    };
    return confirmAction;
  };

  const onConfirmDelete = () => {
    deleteDismissal();
    alert("삭제되었습니다");
    history.goBack();
  };

  const onConfirmCancel = () => {
    alert("취소되었습니다");
    history.goBack();
  };

  const onHandleDelete = useConfirm(
    "등록 신청을 삭제하시겠습니까?",
    onConfirmDelete
  );
  const onHandleCancel = useConfirm(
    "등록 신청 수정을 취소하시겠습니까?",
    onConfirmCancel
  );
  const onSubmit = () => {
    onSubmitEvent();
  };

  return (
    <div className="center-button">
      <button
        className="btn-round-lg__border-gray"
        type="button"
        onClick={onHandleDelete}
      >
        삭제{" "}
      </button>
      <button
        className="btn-round-lg__border-gray"
        type="button"
        onClick={onHandleCancel}
      >
        취소{" "}
      </button>
      <button
        className="btn-round-lg__border-pink"
        type="button"
        onClick={onSubmit}
      >
        <i className="fa fa-paper-plane" aria-hidden="true"></i>
        재등록신청{" "}
      </button>
    </div>
  );
};

export default ReRegistorPostButton;
