import React from "react";
import Grid from "@mui/material/Grid";
import EventListItem from "components/event/event-template/EventListItem";

const LikedEventList = ({ eventList }) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      {eventList.map((eventInfo) => (
        <Grid item lg={4} md={4} sm={6} xs={12} key={eventInfo.id}>
          <EventListItem eventInfo={eventInfo} key={eventInfo.id} />
        </Grid>
      ))}
    </Grid>
  );
};

export default LikedEventList;
