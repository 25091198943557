import React, { useEffect, useContext } from "react";
import MobileContext from "context/mobile";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Footer from "../ect/Footer";
import BottomNavigation from "components/ect/BottomNavigation";

const Seoripulgallery = () => {
  const { state } = useContext(MobileContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="container">
      <Header />
      <MenuBar />

      <div className="container">
        <div style={{}}>
          <div style={{}}>
            <div style={{}}>
              <xxxxxxxxxxxxlink
                rel="stylesheet"
                type="text/css"
                href="/css/style.css"
                style={{}}
              >
                <div
                  style={{
                    margin: "0px auto 4rem",
                    padding: "0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    lineHeight: "25.6px",
                    boxSizing: "border-box",
                    maxWidth: "80rem",
                    position: "relative",
                    orphans: "2",
                    textAlign: "start",
                    textIndent: "0px",
                    widows: "2",
                    textDecorationThickness: "initial",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <div
                    style={{
                      margin: "0px auto",
                      padding: "0px",
                      border: "0px",
                      verticalAlign: "baseline",
                      lineHeight: "25.6px",
                      boxSizing: "border-box",
                      maxWidth: "80rem",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px auto",
                        padding: "0px",
                        border: "0px",
                        verticalAlign: "baseline",
                        lineHeight: "25.6px",
                        boxSizing: "border-box",
                        maxWidth: "68.75rem",
                      }}
                    >
                      <div
                        style={{
                          margin: "0px",
                          padding: "0px",
                          border: "0px",
                          verticalAlign: "baseline",
                          lineHeight: "25.6px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            margin: "0px auto",
                            padding: "0px",
                            border: "0px",
                            verticalAlign: "baseline",
                            lineHeight: "25.6px",
                            boxSizing: "border-box",
                            maxWidth: "80rem",
                            position: "relative",
                          }}
                        >
                          <div
                            align="center"
                            style={{
                              color: "rgb(84, 84, 84)",
                              fontFamily: "Nanum Gothic, sans-serif",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: "400",
                              letterSpacing: "normal",
                              textTransform: "none",
                              whiteSpace: "normal",
                              wordSpacing: "0px",
                              "-webkit-text-stroke-width": "0px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              style={{
                                margin: "0px",
                                padding: "0px",
                                border: "0px",
                                fontSize: "2.65rem",
                                verticalAlign: "baseline",
                                lineHeight: "67.84px",
                                boxSizing: "border-box",
                                fontWeight: "600",
                                letterSpacing: "-0.125rem",
                                color: "rgb(60, 63, 69)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                              }}
                            >
                              <img src="/images/sc_imga005.png" />
                              &nbsp;
                            </span>
                          </div>
                          <div
                            align="center"
                            style={{
                              color: "rgb(84, 84, 84)",
                              fontFamily: "Nanum Gothic, sans-serif",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: "400",
                              letterSpacing: "normal",
                              textTransform: "none",
                              whiteSpace: "normal",
                              wordSpacing: "0px",
                              "-webkit-text-stroke-width": "0px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(50, 50, 50)",
                                fontFamily: "NanumGothic, 나눔고딕, sans-serif",
                                fontSize: "22.8px",
                                fontWeight: "600",
                                letterSpacing: "-0.0625rem",
                              }}
                            >
                              &nbsp;
                            </span>
                            <br />
                          </div>

                          <div style={{}}>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                  fontSize: "1.425rem",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: "400",
                                  letterSpacing: "-0.0625rem",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  wordSpacing: "0px",
                                  "-webkit-text-stroke-width": "0px",
                                }}
                              >
                                &nbsp;
                              </span>
                              <span
                                style={{
                                  textAlign: "start",
                                  fontSize: "22.8px",
                                  letterSpacing: "-1px",
                                }}
                              >
                                <font color="#323232">
                                  서리풀 청년 아트 갤러리는 예술전당 앞
                                  지하보도를 활용하여
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  textAlign: "start",
                                  fontSize: "22.8px",
                                  letterSpacing: "-1px",
                                }}
                              >
                                <font color="#323232">
                                  청년 시각예술가들의 작품을 전시하고 주민들을
                                  위한 프로그램을 개회하여
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  textAlign: "start",
                                  fontSize: "22.8px",
                                  letterSpacing: "-1px",
                                }}
                              >
                                <font color="#323232">
                                  복합문화공간으로 성장하고 있습니다.
                                </font>
                              </span>
                            </div>
                            <div
                              align="center"
                              style={{
                                color: "rgb(84, 84, 84)",
                                fontFamily: "Nanum Gothic, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: "400",
                                letterSpacing: "normal",
                                textTransform: "none",
                                whiteSpace: "normal",
                                wordSpacing: "0px",
                                "-webkit-text-stroke-width": "0px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div
                              align="center"
                              style={{
                                color: "rgb(84, 84, 84)",
                                fontFamily: "Nanum Gothic, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: "400",
                                letterSpacing: "normal",
                                textTransform: "none",
                                whiteSpace: "normal",
                                wordSpacing: "0px",
                                "-webkit-text-stroke-width": "0px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(50, 50, 50)",
                                  fontSize: "1.425rem",
                                  letterSpacing: "-0.0625rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div
                              align="center"
                              style={{
                                color: "rgb(84, 84, 84)",
                                fontFamily: "Nanum Gothic, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: "400",
                                letterSpacing: "normal",
                                textTransform: "none",
                                whiteSpace: "normal",
                                wordSpacing: "0px",
                                "-webkit-text-stroke-width": "0px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  fontSize: "2.65rem",
                                  verticalAlign: "baseline",
                                  lineHeight: "67.84px",
                                  boxSizing: "border-box",
                                  fontWeight: "600",
                                  letterSpacing: "-0.125rem",
                                  color: "rgb(60, 63, 69)",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                <img src="/images/sc_imga004.png" />
                                &nbsp;
                              </span>
                            </div>

                            <div align="center" style={{ textAlign: "center" }}>
                              <font color="#323232">
                                <span
                                  style={{
                                    fontSize: "14pt",
                                    letterSpacing: "-1px",
                                  }}
                                >
                                  Seoripul Gallery is transformed from the
                                  underground sidewalk in front of the Seoul Art
                                  Center
                                </span>
                              </font>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <font color="#323232">
                                <span
                                  style={{
                                    fontSize: "14pt",
                                    letterSpacing: "-1px",
                                  }}
                                >
                                  to exhibits the works of youth visual
                                  artists.&nbsp;
                                </span>
                              </font>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <font color="#323232">
                                <span
                                  style={{
                                    fontSize: "14pt",
                                    letterSpacing: "-1px",
                                  }}
                                >
                                  The gallery is growing int a multicultural
                                  platform&nbsp;
                                </span>
                              </font>
                              <span
                                style={{
                                  fontSize: "14pt",
                                  letterSpacing: "-1px",
                                  color: "rgb(50, 50, 50)",
                                }}
                              >
                                by holding various cultural program for
                                residents.
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontSize: "14pt",
                                  letterSpacing: "-1px",
                                  color: "rgb(50, 50, 50)",
                                }}
                              >
                                <br />
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontSize: "14pt",
                                  letterSpacing: "-1px",
                                  color: "rgb(50, 50, 50)",
                                }}
                              >
                                <br />
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <font color="#323232">
                                <span
                                  style={{
                                    fontSize: "22.8px",
                                    letterSpacing: "-1px",
                                  }}
                                >
                                  <br />
                                </span>
                              </font>
                            </div>
                            <div
                              align="center"
                              style={{
                                color: "rgb(84, 84, 84)",
                                fontFamily: "Nanum Gothic, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: "400",
                                letterSpacing: "normal",
                                textTransform: "none",
                                whiteSpace: "normal",
                                wordSpacing: "0px",
                                "-webkit-text-stroke-width": "0px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src="/images/sc_imga003.png"
                                loading="lazy"
                              />
                              &nbsp;
                            </div>
                            <div
                              align="center"
                              style={{
                                color: "rgb(84, 84, 84)",
                                fontFamily: "Nanum Gothic, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: "400",
                                letterSpacing: "normal",
                                textTransform: "none",
                                whiteSpace: "normal",
                                wordSpacing: "0px",
                                "-webkit-text-stroke-width": "0px",
                                textAlign: "center",
                              }}
                            >
                              &nbsp;
                            </div>
                            <div
                              align="center"
                              style={{
                                color: "rgb(84, 84, 84)",
                                fontFamily: "Nanum Gothic, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: "400",
                                letterSpacing: "normal",
                                textTransform: "none",
                                whiteSpace: "normal",
                                wordSpacing: "0px",
                                "-webkit-text-stroke-width": "0px",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1.25rem",
                                  fontFamily:
                                    "NanumGothic, 나눔고딕, sans-serif",
                                }}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  <b>전시 관람</b>
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">무료</font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "20px" }}
                              >
                                <font color="#545454">
                                  <br />
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  <b>운영 시간</b>
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  Tue-Sun 11:00 - 19:00 (월요일, 공휴일
                                  휴무)&nbsp;
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  ※상기 일정은 공간 운영에 따라 변경될 수
                                  있습니다.&nbsp;
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "20px" }}
                              >
                                <font color="#545454">
                                  <br />
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  <b>주소</b>
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  서울특별시 서초구 남부순환로 323길 1,
                                  예술의전당 앞 지하보도
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "20px" }}
                              >
                                <font color="#545454">
                                  <br />
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  <b>전화/팩스</b>
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  02.3477.2074 / 070.5057.6235
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "20px" }}
                              >
                                <font color="#545454">
                                  <br />
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "20px" }}
                              >
                                <font color="#545454">
                                  <b>
                                    <span style={{ fontSize: "14pt" }}>
                                      이 메 일
                                    </span>
                                  </b>
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <span
                                style={{ textAlign: "start", fontSize: "14pt" }}
                              >
                                <font color="#545454">
                                  seoripulgallery@gmail.com
                                </font>
                              </span>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <br />
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <br />
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <a
                                className="tab-form__submit"
                                onClick={() =>
                                  window.open(
                                    "https://seoripulgallery.com/",
                                    "_blank"
                                  )
                                }
                              >
                                사이트로 이동
                              </a>
                            </div>
                            <div align="center" style={{ textAlign: "center" }}>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </xxxxxxxxxxxxlink>
            </div>
          </div>
        </div>
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </div>
  );
};

export default Seoripulgallery;
