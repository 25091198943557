import React, { useState } from "react";

const UserReviewForm = ({ addReview }) => {
  const [reviewInfo, setReviewInfo] = useState({
    content: "",
    stars: 0,
  });

  const onChangeContent = (e) => {
    setReviewInfo({
      ...reviewInfo,
      content: e.target.value,
    });
  };

  const onChangeStar = (value) => {
    setReviewInfo({
      ...reviewInfo,
      stars: value,
    });
  };

  const onSubmitReview = () => {
    addReview(reviewInfo.content, reviewInfo.stars);
    setReviewInfo({
      content: "",
      stars: 0,
    });
  };

  return (
    <form className="contact-form inner-review" id="leave-review">
      <div className="inner-review__form">
        <div className="inner-review__form-box">
          <textarea
            className="contact-form__textarea"
            name="comment"
            id=""
            placeholder="감상평을 남겨주세요..."
            onChange={(e) => onChangeContent(e)}
            value={reviewInfo.content}
          ></textarea>
          <div className="row">
            <div className="col-md-6">
              <p className="contact-form__rate-bx">
                <i
                  className={
                    reviewInfo.stars < 1 ? "la la-star" : "la la-star active"
                  }
                  onClick={() => onChangeStar(1)}
                ></i>
                <i
                  className={
                    reviewInfo.stars < 2 ? "la la-star" : "la la-star active"
                  }
                  onClick={() => onChangeStar(2)}
                ></i>
                <i
                  className={
                    reviewInfo.stars < 3 ? "la la-star" : "la la-star active"
                  }
                  onClick={() => onChangeStar(3)}
                ></i>
                <i
                  className={
                    reviewInfo.stars < 4 ? "la la-star" : "la la-star active"
                  }
                  onClick={() => onChangeStar(4)}
                ></i>
                <i
                  className={
                    reviewInfo.stars < 5 ? "la la-star" : "la la-star active"
                  }
                  onClick={() => onChangeStar(5)}
                ></i>
              </p>
              <p className="contact-form__rate-bx-show">
                <span className="rate-actual">{reviewInfo.stars}</span> / 5
              </p>
            </div>
            <div className="col-md-6">
              <input
                className="contact-form__submit btn-float-right"
                type="button"
                name="submit-contact"
                id="submit_contact"
                value="등록하기"
                onClick={onSubmitReview}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UserReviewForm;
