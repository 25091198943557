import React from "react";
import DOMPurify from "dompurify";

const Overview = ({ initDetail }) => {
  return (
    <>
      <div className="listing-detail__galleria">
        {/* <img src={initDetail.imgSrc} /> */}
      </div>

      <h2 className="listing-detail__content-title">소개</h2>
      <div
        className="listing-detail__content-description"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(initDetail) }}
      ></div>
    </>
  );
};

export default Overview;
