import React from "react";
import Grid from "@mui/material/Grid";
import PlaceListItem from "./PlaceListItem";

const PlaceList = ({ list }) => {
  return (
    <Grid container columnSpacing={4} rowSpacing={3}>
      {list === null
        ? "리스트를 로드하지 못하였습니다."
        : list.map((placeInfo) => (
            <Grid item lg={3} md={3} sm={6} xs={12} key={placeInfo.id}>
              <PlaceListItem placeInfo={placeInfo} />
            </Grid>
          ))}
    </Grid>
  );
};

export default PlaceList;
