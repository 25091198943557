import Paging from "components/ect/Paging";
import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";

import CheckablePlaceListItem from "./my-page-template/CheckablePlaceListItem";

const PlaceListModal = ({ onToggleModal, disabledList }) => {
  const [curPage, setCurPage] = useState(1);
  const [placeList, setPlaceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getCurPage = (pageNumber) => {
    setCurPage(pageNumber);
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      return resources.images[0].url;
    } else {
      return null;
    }
  };

  const getPlaceList = useCallback(async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-space/list`;

    try {
      const response = await axios.get(url, {
        params: {
          page: curPage,
          count: 6,
          search_type: "STATE",
          search_word: "POST",
        },
      });

      if (response.status === 200) {
        const { total_count, list } = response.data;

        setTotalCount(total_count);

        let ary = [];
        for (let i = 0; i < list.length; i++) {
          ary.push({
            id: list[i].id,
            imgSrc: getImage(list[i].resources),
            category: list[i].space_type,
            title: list[i].name,
            address: list[i].address1 + " " + list[i].address2,
            status: list[i].state,
          });
        }

        setPlaceList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, [curPage]);

  const [checkedIdList, setCheckedIdList] = useState([]);

  const addCheckedItem = (cId) => {
    setCheckedIdList(checkedIdList.concat(cId));
  };

  const removeCheckedItem = (rId) => {
    let ary = checkedIdList;

    ary = ary.filter((item) => item !== rId);

    setCheckedIdList(ary);
  };

  const isDisable = (id) => {
    if (disabledList) {
      for (let i = 0; i < disabledList.length; i++) {
        if (disabledList[i].id === id) {
          return true;
        }
      }
    }
    return false;
  };

  const onClickGetBtn = () => {
    let data = new Object();
    data.spaceIds = checkedIdList;

    addFavoritePlace(data);
  };

  const addFavoritePlace = async (data) => {
    console.log(data);
    const url = `${
      process.env.REACT_APP_USER_SERVICE
    }/api/favorite-space/user/${window.sessionStorage.getItem("userid")}`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 201) {
        onToggleModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPlaceList();
  }, [getPlaceList]);

  return (
    <div id="container">
      <section className="explore">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-sm">
                <h2 className="listing-detail__content-title">
                  문화공간 추가하기
                </h2>
                <hr />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <h5 style={{ float: "left" }}>총 {totalCount} / 선택 2건</h5>
                  <form className="search-form btn-float-right">
                    <div className="search-form__input-holders">
                      <input
                        className="search-form__input"
                        type="text"
                        name="search-event"
                        placeholder="검색..."
                      />
                    </div>
                    <button className="btn-round-lg__pink" type="submit">
                      검색
                    </button>
                  </form>
                  <br />
                  <br />

                  <Grid container spacing={4}>
                    {placeList.map((placeInfo) => (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        key={placeInfo.id}
                      >
                        <CheckablePlaceListItem
                          placeInfo={placeInfo}
                          addCheckedItem={addCheckedItem}
                          removeCheckedItem={removeCheckedItem}
                          isDisable={isDisable(placeInfo.id)}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Paging
                    pageNum={curPage}
                    getCurPage={getCurPage}
                    onePage={6}
                  />
                  <div className="center-button">
                    <input
                      type="button"
                      name="btn_js_alert"
                      id="btn_js_alert"
                      className="btn-round-lg__border-gray"
                      onClick={() => onToggleModal()}
                      value="취소"
                    />
                    <button
                      className="btn-round-lg__border-pink"
                      type="button"
                      onClick={() => onClickGetBtn()}
                    >
                      가져오기{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PlaceListModal;
