import React from "react";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { useHistory } from "react-router-dom";

const clientId = process.env.REACT_APP_GOOGLE_LOGIN_API_KEY;

const GoogleLoginBtn = () => {
  const history = useHistory();

  const onSuccessToLogin = async (response) => {
    const {
      accessToken,
      profileObj: { email, name },
    } = response;

    let data = new Object();

    data.name = name;
    data.email = email;
    data.accessToken = accessToken;

    login(data);
  };

  const login = async (data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE}/api/sns/google/login`;

    try {
      const res = await axios.post(url, data);

      if (res.status === 200) {
        const { token, userid } = res.headers;

        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("userid", userid);

        // 로그인 인포
        window.sessionStorage.setItem("isFirstVisit", "2");

        history.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onFailToLogin = (error) => {
    console.log(error);
  };

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        responseType={"id_token"}
        onSuccess={onSuccessToLogin}
        onFailure={onFailToLogin}
        render={(renderProps) => (
          <a
            className="google"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            style={{
              cursor: "pointer",
            }}
          >
            <i className="fa fa-google" aria-hidden="true"></i>
          </a>
        )}
      />
    </div>
  );
};

export default GoogleLoginBtn;
