import React from "react";
import { Link } from "react-router-dom";

const PlaceListItem = ({ placeInfo }) => {
  const getLink = () => {
    switch (placeInfo.status) {
      case "REJECT":
        return `/my-page/place-detail-dismissal/${placeInfo.id}`;

      case "POST":
        return `/place/place-detail/${placeInfo.id}`;

      case "WAIT":
        return `/my-page/place-detail-waiting/${placeInfo.id}`;

      default:
        return `/place/place-detail/${placeInfo.id}`;
    }
  };

  const getCategoryLabel = () => {
    switch (placeInfo.category) {
      case "HALL":
        return (
          <span className="place-post__date place-post__hall">공연장</span>
        );

      case "PRACTICE":
        return (
          <span className="place-post__date place-post__practice">연습실</span>
        );

      case "INSTRUMENT":
        return (
          <span className="place-post__date place-post__instrument">
            악기상점
          </span>
        );

      case "GALLERY":
        return (
          <span className="place-post__date place-post__gallery">
            미술관/갤러리
          </span>
        );

      default:
        return "";
    }
  };

  const getStatusLabel = () => {
    switch (placeInfo.status) {
      case "REJECT":
        return <span class="tag__dismissal">기각 </span>;

      case "POST":
        return <span class="tag__passed">통과 </span>;

      case "WAIT":
        return <span class="tag__waiting">대기 중 </span>;

      default:
        return "";
    }
  };

  // const getImageSrc = (resources) => {
  //   if (Object.keys(resources).includes("images")) {
  //     return `${process.env.REACT_APP_ADMIN_SERVICE}${resources.images[0].url}`;
  //   } else {
  //     return process.env.PUBLIC_URL + "/upload/place-poster2.jpg";
  //   }
  // };

  const getImgSrc = (imgSrc) => {
    if (imgSrc) {
      if (imgSrc.includes("/upload/")) {
        return imgSrc;
      } else if (imgSrc.includes("/images/")) {
        return `${process.env.REACT_APP_ADMIN_SERVICE}${imgSrc}`;
      }
    } else {
      return process.env.PUBLIC_URL + "/upload/event-poster.jpg";
    }
  };

  return (
    <div className="place-post">
      <div className="place-post__gal">
        <Link to={getLink()}>
          <img src={getImgSrc(placeInfo.imgSrc)} alt="문화공간 포스터" />
        </Link>
        {getCategoryLabel()}
      </div>
      <div className="post_content1">
        <h2 className="post_title1">
          <Link to={getLink}>{placeInfo.title}</Link>
        </h2>
        <p className="place-post__description">{placeInfo.address}</p>
        {window.location.href.includes("my-page") ? getStatusLabel() : ""}
      </div>
    </div>
  );
};

export default PlaceListItem;
