import React, { useContext } from "react";
import { Link } from "react-router-dom";
import MenuContext from "context/menu";

const MyPageMenu = React.memo(() => {
  const { state, actions } = useContext(MenuContext);

  const onHandleClick = (pick) => {
    actions.setMyPageMenu(pick);
  };

  return (
    <div className="col-lg-2">
      <div className="comm-sidebar__widget">
        <div className="sub-left-menu">
          <ul className="sub-left-menu-list">
            <li>
              <Link
                to="/my-page/user-info"
                className={state.myPageMenu === 0 ? "active" : ""}
                onClick={() => onHandleClick(0)}
              >
                나의 정보 확인
              </Link>
            </li>
            <li>
              <Link
                to="/my-page/user-liked-event"
                className={state.myPageMenu === 1 ? "active" : ""}
                onClick={() => onHandleClick(1)}
              >
                좋아하는 행사
              </Link>
            </li>
            <li>
              <Link
                to="/my-page/user-alert-setting"
                className={state.myPageMenu === 2 ? "active" : ""}
                onClick={() => onHandleClick(2)}
              >
                알림/알림설정
              </Link>
            </li>
            <li>
              <Link
                to="/my-page/user-cont-refused"
                className={state.myPageMenu === 3 ? "active" : ""}
                onClick={() => onHandleClick(3)}
              >
                등록 신청
              </Link>
            </li>
            <li>
              <Link
                to="/my-page/reservation-list"
                className={state.myPageMenu === 4 ? "active" : ""}
                onClick={() => onHandleClick(4)}
              >
                예약 신청
              </Link>
            </li>
            <li>
              <Link
                to="/my-page/user-review"
                className={state.myPageMenu === 5 ? "active" : ""}
                onClick={() => onHandleClick(5)}
              >
                내가 쓴 리뷰
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default React.memo(MyPageMenu);
