import React, { useEffect, useState, useContext } from "react";
import MobileContext from "context/mobile";

import Header from "../ect/Header";
import MenuBar from "../ect/MenuBar";
import Footer from "../ect/Footer";
import PageTitle from "../ect/PageTitle";

import BoradTitle from "./board-template/BoardTitle";
import RelatedSiteListItem from "./board-template/RelatedSiteListItem";
import axios from "axios";
import BottomNavigation from "components/ect/BottomNavigation";

const RelatedSitesBoard = ({ title }) => {
  const [siteInfoList, setSiteInfo] = useState(null);
  const { state } = useContext(MobileContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/js/script.js";
    script.async = true;
    document.body.appendChild(script);

    const getPartnersList = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE}/api2/admin/partners`;

      try {
        const res = await axios.get(url, {
          params: {
            rows: 10,
            pages: 1,
          },
        });

        if (res.status === 200) {
          let ary = [];

          for (let i = 0; i < res.data.list.length; i++) {
            ary.push({
              id: res.data.list[i].idx,
              logoImgSrc: res.data.list[i].thumb,
              folder: res.data.list[i].folder,
            });
          }

          setSiteInfo(ary);
        }
      } catch (e) {
        console.log("===== 문화 파트너스 목록 조회 실패 =====");
        console.log(e);
      }
    };

    getPartnersList();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (siteInfoList === null) {
    return null;
  }

  return (
    <>
      <div id="container">
        <Header />
        <MenuBar />
        <PageTitle pageTitle="문화 파트너스" />
        <section className="explore">
          <div className="container related-site-box">
            <div className="col-lg-12">
              <BoradTitle title={title} sum={siteInfoList.length} />

              <div className="community-related-site-list">
                {siteInfoList.map((siteInfo) => (
                  <RelatedSiteListItem siteInfo={siteInfo} key={siteInfo.id} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      {state.isMobile ? <BottomNavigation /> : ""}
      <Footer />
    </>
  );
};
export default RelatedSitesBoard;
