import React, { useState, useEffect } from "react";

// quill
import ReactQuill from "react-quill";
import CustomToolbar from "./CustomToolbar";

const Editor = ({ editorTitle, getDetail, initDetail }) => {
  const moduels = {
    toolbar: {
      container: "#toolbar-container",
    },
  };

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "ordered",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "color",
    "background",
  ];

  return (
    <div
      className="listing-detail__overview element-waypoint"
      id="overview-box"
    >
      <div id="standalone-container">
        <h2 className="listing-detail__content-title">{editorTitle}</h2>
        <CustomToolbar />
        <ReactQuill
          theme="snow"
          modules={moduels}
          formats={formats}
          value={initDetail}
          onChange={(e) => getDetail(e)}
          placeholder="게시물을 작성해주세요."
          formula={true}
          syntax={true}
        />
      </div>
    </div>
  );
};

export default Editor;
