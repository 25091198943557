import React from "react";

const BoradTitle = ({ title, sum }) => {
  return (
    <div className="section-title-sm">
      <h2 className="listing-detail__content-title">
        {title} ({sum}건)
      </h2>
      <hr />
    </div>
  );
};

export default BoradTitle;
