import React, { memo } from "react";
import { useHistory } from "react-router";

const styles = {
  cursor: "pointer",
};

const MenuBar = memo(() => {
  const history = useHistory();
  const moveToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      <div className="floating">
        <div style={styles} onClick={() => history.push("/common/search-all")}>
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
        <div style={styles} onClick={() => history.push("/event/event-all")}>
          <i className="fa fa-search-plus" aria-hidden="true"></i>
        </div>
        <div style={styles} onClick={() => history.push("/common/search")}>
          <i className="fa fa-map-marker" aria-hidden="true"></i>
        </div>
      </div>
      <div className="floating2">
        <div style={styles} onClick={moveToTop}>
          <i className="fa fa-angle-up" aria-hidden="true"></i>
          <br />
          TOP
        </div>
      </div>
    </>
  );
});

export default MenuBar;
