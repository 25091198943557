import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import EventList from "./list-template/EventList";
import ListTemplateTitle from "./list-template/ListTemplateTitle";
import MoreList from "./list-template/MoreList";

const HotClick = ({ title }) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  const formattedDate = `${year}${formattedMonth}${formattedDay}`;

  const [menu, setMenu] = useState(0);
  const onChangeMenu = (menuNum) => {
    setMenu(menuNum);
    setListInfo(menuNum);
  };

  const [hotClickEventList, setHotClickEventList] = useState([]);
  const [totalCount, setTotalCount] = useState("");

  // api 호출 function으로 바꾸어야함
  const setListInfo = (menuNum) => {
    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    switch (menuNum) {
      case 0:
        getHotClickEventList("", loginId);
        break;
      case 1:
        getHotClickEventList("show", loginId);
        break;
      case 2:
        getHotClickEventList("exhibition", loginId);
        break;
      case 3:
        getHotClickFestivalList();
        break;
      case 4:
        getHotClickEventList("other", loginId);
        break;
      default:
        break;
    }
  };

  const setLink = () => {
    switch (menu) {
      case 0:
        return "/event/event-all";

      case 1:
        return "/event/concert";

      case 2:
        return "/event/exhibit";

      case 3:
        return "/event/sc-event";

      case 4:
        return "/event/others";

      default:
        break;
    }
  };

  const getImage = (resources) => {
    if (Object.keys(resources).includes("images")) {
      if (Object.keys(resources.images).includes("images")) {
        return resources.images.images[0].url;
      } else {
        return resources.images[0].url;
      }
    } else {
      return null;
    }
  };

  const getHotClickEventList = useCallback(async (eventType, loginId) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/cultural-event/list/${eventType}`;

    const res = await axios.get(url, {
      params: {
        sort_column: "view_count",
        sort_type: "desc",
        search_type: "STATE",
        search_word: "POST",
        page: 1,
        count: 10000,
        loginid: loginId,
      },
    });

    if (res.status === 200) {
      setTotalCount(res.data.total_count);
      let ary = [];
      for (let i = 0; i < res.data.list.length; i++) {
        const listDate = res.data.list[i].open_date.replace(/\./g, "");
        if (formattedDate < listDate) {
          ary.push({
            id: res.data.list[i].id,
            imgSrc: getImage(res.data.list[i].resources),
            category: res.data.list[i].event_type,
            title: res.data.list[i].name,
            placeName: res.data.list[i].location,
            like: res.data.list[i].is_like === 1 ? true : false,
            address: res.data.list[i].address1 + res.data.list[i].address2,
            period: {
              start: res.data.list[i].open_date,
              end: res.data.list[i].close_date,
            },
          });
        }
      }

      setHotClickEventList(ary);
    }
  }, []);

  const getHotClickFestivalList = useCallback(async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/api/admin/seochogu-festival/list`;

    try {
      const res = await axios.get(url, {
        params: {
          page: 1,
          count: 10000,
          search_type: "STATE",
          search_word: "POST",
        },
      });

      if (res.status === 200) {
        setTotalCount(res.data.total_count);
        let ary = [];
        for (let i = 0; i < res.data.list.length; i++) {
          ary.push({
            id: res.data.list[i].id,
            imgSrc: getImage(res.data.list[i].resources),
            category: "",
            title: res.data.list[i].name,
            placeName: "",
            like: false,
            address: "",
            period: {
              start: res.data.list[i].open_date,
              end: res.data.list[i].close_date,
            },
          });
        }

        setHotClickEventList(ary);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const loginId =
      window.sessionStorage.getItem("userid") === undefined ||
      window.sessionStorage.getItem("userid") === null
        ? ""
        : window.sessionStorage.getItem("userid");

    getHotClickEventList("", loginId);
  }, [getHotClickEventList]);

  return (
    <section className="section-culture-event bg-pink2">
      <div className="container">
        <ListTemplateTitle title={title} />

        <ul className="tabs-block2__list">
          <li className="tabs-block2__list-item">
            <a
              className={menu === 0 ? "active-list" : ""}
              onClick={() => onChangeMenu(0)}
            >
              전체
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 1 ? "active-list" : ""}
              onClick={() => onChangeMenu(1)}
            >
              공연
            </a>
          </li>
          <li className="tabs-block2__list-item">
            <a
              className={menu === 2 ? "active-list" : ""}
              onClick={() => onChangeMenu(2)}
            >
              전시
            </a>
          </li>
          {/* <li className="tabs-block2__list-item">
            <a
              className={menu === 3 ? "active-list" : ""}
              onClick={() => onChangeMenu(3)}
            >
              축제
            </a>
          </li> */}
          <li className="tabs-block2__list-item">
            <a
              className={menu === 4 ? "active-list" : ""}
              onClick={() => onChangeMenu(4)}
            >
              기타
            </a>
          </li>
        </ul>
        <EventList eventList={hotClickEventList} />
        <MoreList moreNum={totalCount} link={setLink()} />
      </div>
    </section>
  );
};

export default HotClick;
