import React from "react";
import Grid from "@mui/material/Grid";

import FrequentlyVisitedPlaceListItem from "./FrequentlyVisitedPlaceListItem";

const FrequentlyVisitedPlaceList = ({
  placeList,
  onToggleModal,
  totalRows,
  deletePlace,
}) => {
  return (
    <div className="explore__box">
      <h2 className="explore__filter-title">
        <span>총</span> {totalRows}건
      </h2>
      <div className="center-button">
        <button
          className="btn-round-lg__border-pink"
          type="button"
          onClick={() => onToggleModal()}
        >
          문화공간 추가하기{" "}
        </button>
      </div>

      <Grid container spacing={1} className="explore__wrap">
        {placeList.map((placeInfo) => (
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <FrequentlyVisitedPlaceListItem
              placeInfo={placeInfo}
              deletePlace={deletePlace}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FrequentlyVisitedPlaceList;
